import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { ApiEndpoints } from '../../core/constants/ApiEndpoints';
import { GlobalErrorTypes } from '../../core/constants/error.types';
import { MainHttp } from '../../core/services/main-http.service';
import { ContentActions } from '../actions/content-actions';
import { UserActions } from '../actions/user.actions';
import { PayloadAction } from '../interfaces/payload-action.interface';
import { UserSelectors } from '../selectors/user.selectors';

@Injectable()
export class ContentEffects {
    private userId: string;

    constructor(
        private actions$: Actions,
        private http: MainHttp,
        private contentActions: ContentActions,
        private userActions: UserActions,
        private userSelectors: UserSelectors
    ) {
        // TODO this can be refactored to use withLatestFrom inside the epic, instead of subscribing here.
        this.userSelectors.account$.subscribe(account => {
            if (account) {
                this.userId = account.id;
            }
        });
    }

    getSignedS3Url = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(ContentActions.MAIN_SIGNED_S3_URL_GET),
            mergeMap(({ payload }) =>
                this.http.post(ApiEndpoints.getSignedS3Url, payload).pipe(
                    tap(data => {
                        this.userActions.userProfileImageIdNew({
                            userId: this.userId,
                            data: { profileImageId: data.key }
                        });
                    }),
                    map(data => this.contentActions.signedS3UrlGetSuccess(data)),
                    catchError(error =>
                        of(
                            this.contentActions.contentErrorNew({
                                type: GlobalErrorTypes.getSignedS3Url,
                                error: error
                            })
                        )
                    )
                )
            )
        )
    );

    resizeImage = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(ContentActions.MAIN_THUMBNAIL_RESIZE),
            mergeMap(({ payload }) =>
                this.http.post(ApiEndpoints.resizeImage, payload).pipe(
                    map(data => this.contentActions.thumnailResizeSuccess()),
                    catchError(error =>
                        of(
                            this.contentActions.contentErrorNew({
                                type: GlobalErrorTypes.resizeImage,
                                error: error
                            })
                        )
                    )
                )
            )
        )
    );
}

