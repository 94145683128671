import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, finalize, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { ApiEndpoints } from '../../core/constants/ApiEndpoints';
import { MainHttp } from '../../core/services/main-http.service';
import { Account } from '../../models/account.model';
import { PayloadAction } from '../interfaces/payload-action.interface';
import { GlobalState } from '../store';

import { GlobalErrorTypes } from '../../core/constants/error.types';
import * as loadingActions from '../actions/loading.actions';
import { UserActions } from '../actions/user.actions';
import * as usersActions from '../actions/users.actions';
import { selectAccount } from '../selectors/users.selectors';

@Injectable()
export class UserEffects {
    constructor(
        private actions$: Actions,
        private http: MainHttp,
        private store: Store<GlobalState>,
        private userActions: UserActions
    ) {}

    /**
     * Updates a user.
     * @param action$
     */
    updateUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(usersActions.updateUser),
            // tap(() => this.store.dispatch(signupErrorReset())),
            tap(() => this.store.dispatch(loadingActions.updateUserLoading())),
            withLatestFrom(this.store.select(selectAccount)),
            filter(([action, account]) => account != null),
            mergeMap(([{ payload }, { id }]) =>
                this.http.put(ApiEndpoints.updateUser(id), payload).pipe(
                    map((user: Account) =>
                        usersActions.updateUserSuccess({
                            payload: user
                        })
                    ),
                    catchError(error => of(usersActions.updateUserFailure({ payload: error }))),
                    finalize(() => this.store.dispatch(loadingActions.updateUserLoadingFinished()))
                )
            )
        )
    );

    updateEmailPreferences = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(UserActions.EMAIL_PREFERENCES_UPDATE),
            tap(() => this.userActions.emailPreferencesUpdateLoadingChanged(true)),
            mergeMap(action =>
                this.http
                    .put(
                        `${ApiEndpoints.updateEmailPreferences}${action.payload.id}`,
                        action.payload.data
                    )
                    .pipe(
                        map((data: any) => this.userActions.emailPreferencesUpdateSuccess(data)),
                        catchError(error =>
                            of(
                                this.userActions.userErrorNew({
                                    type: GlobalErrorTypes.updateEmailPreferences,
                                    error
                                })
                            )
                        ),
                        finalize(() => this.userActions.emailPreferencesUpdateLoadingChanged(false))
                    )
            )
        )
    );

    disconnectGoogle = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(UserActions.GOOGLE_DISCONNECT),
            mergeMap(action =>
                this.http
                    .put(`${ApiEndpoints.updateUser}${action.payload}`, {
                        accounts: {
                            google: {
                                id: null,
                                token: null,
                                displayName: null,
                                email: null,
                                photo: null
                            }
                        }
                    })
                    .pipe(
                        map((data: any) => this.userActions.googleDisconnectSuccess(data)),
                        catchError(error =>
                            of(
                                this.userActions.userErrorNew({
                                    type: GlobalErrorTypes.googleDisconnect,
                                    error
                                })
                            )
                        )
                    )
            )
        )
    );

    disconnectFacebook = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(UserActions.FACEBOOK_DISCONNECT),
            mergeMap(action =>
                this.http
                    .put(`${ApiEndpoints.updateUser}${action.payload}`, {
                        accounts: {
                            fb: {
                                id: null,
                                token: null,
                                displayName: null,
                                email: null,
                                photo: null
                            }
                        }
                    })
                    .pipe(
                        map((data: any) => this.userActions.facebookDisconnectSuccess(data)),
                        catchError(error =>
                            of(
                                this.userActions.userErrorNew({
                                    type: GlobalErrorTypes.facebookDisconnect,
                                    error
                                })
                            )
                        )
                    )
            )
        )
    );

    disconnectBattleNet = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(UserActions.BATTLE_NET_DISCONNECT),
            mergeMap(action =>
                this.http
                    .put(`${ApiEndpoints.updateUser}${action.payload}`, {
                        accounts: {
                            bnet: {
                                id: null,
                                token: null,
                                battleTag: null
                            }
                        }
                    })
                    .pipe(
                        map((data: any) => this.userActions.battleNetDisconnectSuccess(data)),
                        catchError(error =>
                            of(
                                this.userActions.userErrorNew({
                                    type: GlobalErrorTypes.battleNetDisconnect,
                                    error
                                })
                            )
                        )
                    )
            )
        )
    );

    disconnectDiscord = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(UserActions.DISCORD_DISCONNECT),
            mergeMap(action =>
                this.http
                    .put(`${ApiEndpoints.updateUser}${action.payload}`, {
                        accounts: {
                            discord: {
                                id: null,
                                username: null,
                                discriminator: null,
                                email: null
                            }
                        }
                    })
                    .pipe(
                        map((data: any) => this.userActions.discordDisconnectSuccess(data)),
                        catchError(error =>
                            of(
                                this.userActions.userErrorNew({
                                    type: GlobalErrorTypes.discordDisconnect,
                                    error
                                })
                            )
                        )
                    )
            )
        )
    );

    newUserProfileImageId = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(UserActions.USER_PROFILE_IMAGE_ID_NEW),
            mergeMap(action =>
                this.http
                    .put(
                        ApiEndpoints.newUserProfileImageId(action.payload.userId),
                        action.payload.data
                    )
                    .pipe(
                        map(data => this.userActions.userProfileImageIdNewSuccess(data)),
                        catchError(error =>
                            of(
                                this.userActions.userErrorNew({
                                    type: GlobalErrorTypes.newUserProfileImageId,
                                    error
                                })
                            )
                        )
                    )
            )
        )
    );

    /**
     * Used to get a contact's email preferences.
     * @param action$
     * @returns {Observable<any>}
     */

    getEmailPreferences = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(UserActions.EMAIL_PREFERENCES_GET),
            mergeMap(action => {
                return this.http.get(`${ApiEndpoints.getEmailPreferences}${action.payload}`).pipe(
                    map(data => this.userActions.emailPreferencesGetSuccess(data)),
                    catchError(error =>
                        of(
                            this.userActions.userErrorNew({
                                type: GlobalErrorTypes.getEmailPreferences,
                                error: error
                            })
                        )
                    )
                );
            })
        )
    );

    /**
     * Updates the user's consent grants.
     * @param action$
     * @returns {Observable<any>}
     */

    updateConsentGrants = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(UserActions.USER_CONSENT_REGATHER),
            mergeMap(action => {
                return this.http
                    .put(`${ApiEndpoints.updateUser(action.payload.userId)}`, action.payload.data)
                    .pipe(
                        map((paymentMethod: any) =>
                            this.userActions.userConsentRegatherSuccess(paymentMethod)
                        ),
                        catchError(error =>
                            of(
                                this.userActions.userErrorNew({
                                    type: GlobalErrorTypes.updateConsentGrants,
                                    error
                                })
                            )
                        )
                    );
            })
        )
    );

    /**
     * Updates a user.
     * @param action$
     * @returns {Observable<any>}
     */

    updateUser = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(UserActions.USER_UPDATE),
            tap(() => this.userActions.userUpdateLoadingChanged(true)),
            mergeMap(action => {
                return this.http
                    .put(`${ApiEndpoints.updateUser}${action.payload.userId}`, action.payload.data)
                    .pipe(
                        map((user: any) => this.userActions.userUpdateSuccess(user)),
                        catchError(error =>
                            of(
                                this.userActions.userErrorNew({
                                    type: GlobalErrorTypes.updateUser,
                                    error
                                })
                            )
                        ),
                        finalize(() => this.userActions.userUpdateLoadingChanged(false))
                    );
            })
        )
    );
}

