import { createAction, props } from '@ngrx/store';
import { APIError } from '../../models/error.model';
import { Notification } from '../../models/notification.model';
import { Receiver } from '../../models/receiver.model';

export enum NotificationsActionsTypes {
    LoadNotifications = '[Notifications Dropdown] Load Notifications',
    LoadNotificationsSuccess = '[Notifications API] Load Notifications Success',
    LoadNotificationsFailure = '[Notifications API] Load Notifications Failure',

    MarkNotificationsSeen = '[Notifications Dropdown] Mark Notifications Seen',
    MarkNotificationsSeenSuccess = '[Notifications API] Mark Notifications Seen Success',
    MarkNotificationsSeenFailure = '[Notifications API] Mark Notifications Seen Failure',

    MarkNotificationsRead = '[Notifications Dropdown] Mark Notifications Read',
    MarkNotificationsReadSuccess = '[Notifications API] Mark Notifications Read Success',
    MarkNotificationsReadFailure = '[Notifications API] Mark Notifications Read Failure',

    UpdatePushSubscription = '[Push Notifications Banner] Update Push Subscription',
    UpdatePushSubscriptionSuccess = '[Notifications API] Update Push Subscription Success',
    UpdatePushSubscriptionFailure = '[Notifications API] Update Push Subscription Failure',

    LoadNotificationPreferences = '[Notification Preferences] Load Notification Preferences',
    LoadNotificationPreferencesSuccess = '[Notifications API] Load Notification Preferences Success',
    LoadNotificationPreferencesFailure = '[Notifications API] Load Notification Preferences Failure',

    UpdateNotificationPreferences = '[Notification Preferences] Update Notification Preferences',
    UpdateNotificationPreferencesSuccess = '[Notifications API] Update Notification Preferences Success',
    UpdateNotificationPreferencesFailure = '[Notifications API] Update Notification Preferences Failure'
}

export const loadNotifications = createAction(
    NotificationsActionsTypes.LoadNotifications,
    props<{ payload: { pageNumber?: number } }>()
);
export const loadNotificationsSuccess = createAction(
    NotificationsActionsTypes.LoadNotificationsSuccess,
    props<{ payload: Notification[] }>()
);
export const loadNotificationsFailure = createAction(
    NotificationsActionsTypes.LoadNotificationsFailure,
    props<{ payload: APIError }>()
);

export const markNotificationsSeen = createAction(
    NotificationsActionsTypes.MarkNotificationsSeen,
    props<{ payload?: { itemId?: string } }>()
);
export const markNotificationsSeenSuccess = createAction(
    NotificationsActionsTypes.MarkNotificationsSeenSuccess,
    props<{ payload?: { itemId?: string } }>()
);
export const markNotificationsSeenFailure = createAction(
    NotificationsActionsTypes.MarkNotificationsSeenFailure,
    props<{ payload: APIError }>()
);

export const markNotificationsRead = createAction(
    NotificationsActionsTypes.MarkNotificationsRead,
    props<{ payload?: { itemId?: string } }>()
);
export const markNotificationsReadSuccess = createAction(
    NotificationsActionsTypes.MarkNotificationsReadSuccess,
    props<{ payload?: { itemId?: string } }>()
);
export const markNotificationsReadFailure = createAction(
    NotificationsActionsTypes.MarkNotificationsReadFailure,
    props<{ payload: APIError }>()
);

export const updatePushSubscription = createAction(
    NotificationsActionsTypes.UpdatePushSubscription,
    props<{ payload: any }>()
);
export const updatePushSubscriptionSuccess = createAction(
    NotificationsActionsTypes.UpdatePushSubscriptionSuccess,
    props<{ payload: any }>()
);
export const updatePushSubscriptionFailure = createAction(
    NotificationsActionsTypes.UpdatePushSubscriptionFailure,
    props<{ payload: APIError }>()
);

export const loadNotificationPreferences = createAction(
    NotificationsActionsTypes.LoadNotificationPreferences
);
export const loadNotificationPreferencesSuccess = createAction(
    NotificationsActionsTypes.LoadNotificationPreferencesSuccess,
    props<{ payload: Receiver }>()
);
export const loadNotificationPreferencesFailure = createAction(
    NotificationsActionsTypes.LoadNotificationPreferencesFailure,
    props<{ payload: APIError }>()
);

export const updateNotificationPreferences = createAction(
    NotificationsActionsTypes.UpdateNotificationPreferences,
    props<{
        payload: {
            pushScopes: { [key: string]: boolean };
        };
    }>()
);
export const updateNotificationPreferencesSuccess = createAction(
    NotificationsActionsTypes.UpdateNotificationPreferencesSuccess,
    props<{ payload: Receiver }>()
);
export const updateNotificationPreferencesFailure = createAction(
    NotificationsActionsTypes.UpdateNotificationPreferencesFailure,
    props<{ payload: APIError }>()
);

