import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit
} from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import { takeWhile } from 'rxjs/operators';
import { AppSettings } from '../../../../../core/constants/AppSettings';
import { loadSubscriptions } from '../../../../../store/actions/payment.actions';
import { validateBannerPromoCode } from '../../../../../store/actions/referral.actions';
import { selectEnabledBannerCampaign } from '../../../../../store/selectors/referral.selectors';
import { selectAccount, selectIsLoggedIn } from '../../../../../store/selectors/users.selectors';
import { GlobalState } from '../../../../../store/store';
import { GlButtonComponent } from '../../../gl-button/gl-button.component';
import { L10nTranslateDirective } from 'angular-l10n';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-dashboard-navigation-sale-banner',
    templateUrl: './dashboard-navigation-sale-banner.component.html',
    styleUrls: ['./dashboard-navigation-sale-banner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [RouterLink, NgClass, L10nTranslateDirective, GlButtonComponent]
})
export class DashboardNavigationSaleBannerComponent implements OnInit {
    private componentAlive: boolean = true;
    private isLoggedIn: boolean = false;
    private interval;

    public promoCode?: string | null;
    public countdownTime: any = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
    };

    constructor(
        private router: Router,
        private store: Store<GlobalState>,
        private cdr: ChangeDetectorRef
    ) {}

    private countdown(date: any) {
        const endDate: number = new Date(date).getTime();

        if (isNaN(endDate)) {
            return;
        }

        clearInterval(this.interval);
        this.interval = null;

        const calculate = () => {
            let t: any = Date.parse(date) - Date.now();

            if (t >= 0) {
                let seconds = Math.floor((t / 1000) % 60);
                let minutes = Math.floor((t / 1000 / 60) % 60);
                let hours = Math.floor((t / (1000 * 60 * 60)) % 24);
                let days = Math.floor(t / (1000 * 60 * 60 * 24));

                this.countdownTime.days = Math.round(days);
                this.countdownTime.hours = ('0' + hours).slice(-2);
                this.countdownTime.minutes = ('0' + minutes).slice(-2);
                this.countdownTime.seconds = ('0' + seconds).slice(-2);
            } else {
                clearInterval(this.interval);
                this.interval = null;
                return;
            }
            this.cdr.detectChanges();
        };

        this.interval = setInterval(calculate, 1000);
    }

    public click() {
        this.store.dispatch(validateBannerPromoCode({ payload: { promoCode: this.promoCode } }));

        if (this.isLoggedIn) {
            this.router.navigate(['/signup/checkout', AppSettings.PROMO_DISCOUNTED_PLAN]);
        } else {
            this.router.navigate(['/signup']);
        }
    }

    ngAfterViewInit() {
        this.store
            .select(selectEnabledBannerCampaign)
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(campaign => {
                if (campaign) {
                    this.countdown(campaign.bannerSettings.endDate);
                    this.promoCode = campaign.promoCode.code;
                }
            });
    }

    async ngOnInit() {
        this.store
            .select(selectAccount)
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(account => {
                if (account && account.id) {
                    this.store.dispatch(loadSubscriptions());
                }
            });
        this.store
            .select(selectIsLoggedIn)
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(isLoggedIn => {
                this.isLoggedIn = isLoggedIn;
            });
    }

    ngOnDestroy() {
        this.componentAlive = false;
    }
}
