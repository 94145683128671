import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { AppState } from '../reducers/app.reducer';
import { GlobalState } from '../store';

const selectApp = createFeatureSelector<MemoizedSelector<GlobalState, any>>('app').projector(
    (state: GlobalState) => state.app
);

export const selectDeleteCommentModalOpen = createSelector(
    selectApp,
    (state: AppState) => state.modalsVisibility.deleteCommentModal
);
export const selectPromoVideoModalOpen = createSelector(
    selectApp,
    (state: AppState) => state.modalsVisibility.promoVideoModal
);
export const selectConsentRegatherModalOpen = createSelector(
    selectApp,
    (state: AppState) => state.modalsVisibility.consentRegatherModal
);
export const selectAnnouncementModalOpen = createSelector(
    selectApp,
    (state: AppState) => state.modalsVisibility.announcementModal
);
export const selectGiveawayModalOpen = createSelector(
    selectApp,
    (state: AppState) => state.modalsVisibility.giveawayModal
);
export const selectNotificationPreferencesOpen = createSelector(
    selectApp,
    (state: AppState) => state.modalsVisibility.notificationPreferencesModal
);
export const selectFirstUserClickTracked = createSelector(
    selectApp,
    (state: AppState) => state.firstUserClickTracked
);

