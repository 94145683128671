import { createAction } from '@ngrx/store';

export enum AppActionsTypes {
    OpenAnnouncementModal = '[App Global] Open Announcement Modal',
    CloseAnnouncementModal = '[App Global] Close Announcement Modal',
    OpenGiveawayModal = '[App Global] Open Giveaway Modal',
    CloseGiveawayModal = '[App Global] Close Giveaway Modal',
    OpenConsentModal = '[App Global] Open Consent Modal',
    CloseConsentModal = '[App Global] Close Consent Modal',
    OpenDeleteCommentModal = '[Comment Section] Open Delete Comment Modal',
    CloseDeleteCommentModal = '[Comment Section] Close Delete Comment Modal',
    OpenPromoVideoModal = '[Comment Section] Open Promo Video Modal',
    ClosePromoVideoModal = '[Comment Section] Close Promo Video Modal',
    OpenNotificationPreferencesModal = '[Notifications Component] Open Notification Preferences Modal',
    CloseNotificationPreferencesModal = '[Notifications Component] Close Notification Preferences Modal',
    TrackUserFirstClick = '[App Global] Track User First Click',
    OpenSidenavMenu = '[App Header] Open Sidenav Menu'
}

export const openAnnouncementModal = createAction(AppActionsTypes.OpenAnnouncementModal);
export const closeAnnouncementModal = createAction(AppActionsTypes.CloseAnnouncementModal);
export const openGiveawayModal = createAction(AppActionsTypes.OpenGiveawayModal);
export const closeGiveawayModal = createAction(AppActionsTypes.CloseGiveawayModal);
export const openConsentModal = createAction(AppActionsTypes.OpenConsentModal);
export const closeConsentModal = createAction(AppActionsTypes.CloseConsentModal);
export const openDeleteCommentModal = createAction(AppActionsTypes.OpenDeleteCommentModal);
export const closeDeleteCommentModal = createAction(AppActionsTypes.CloseDeleteCommentModal);
export const openPromoVideoModal = createAction(AppActionsTypes.OpenPromoVideoModal);
export const closePromoVideoModal = createAction(AppActionsTypes.ClosePromoVideoModal);
export const openNotificationPreferencesModal = createAction(
    AppActionsTypes.OpenNotificationPreferencesModal
);
export const closeNotificationPreferencesModal = createAction(
    AppActionsTypes.CloseNotificationPreferencesModal
);
export const trackUserFirstClick = createAction(AppActionsTypes.TrackUserFirstClick);
export const openSidenavMenu = createAction(AppActionsTypes.OpenSidenavMenu);

