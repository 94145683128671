import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable ,  Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { UserSelectors } from '../../store/selectors/user.selectors';

@Injectable()
export class CanActivateLogin  {
    isLoggedInSubscription: Subscription;
    isLoggedIn: boolean;

    constructor(private userSelectors: UserSelectors, private router: Router) {
        this.isLoggedInSubscription = this.userSelectors.isLoggedIn$
            .subscribe(isLoggedIn => this.isLoggedIn = isLoggedIn);
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (!this.isLoggedIn) {
            return true;
        }

        this.router.navigate(['/']);
        return false;
    }
}