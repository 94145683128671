import { Provider } from '@angular/core';
import { DashboardOutletSEOResolver } from '../dashboard/resolvers/dashboard-outlet-seo.resolver';
import { BreadcrumbService } from '../shared/modules/breadcrumbs/breadcrumb.service';
import { ReferralResolver } from './components/referral/referral.resolver';
import { CanActivateAccount } from './guards/account.guard';
import { CanActivateAffiliate } from './guards/affiliate.guard';
import { DashboardGameSetGuard } from './guards/dashboard-game-set.guard';
import { CanActivateDashboard } from './guards/dashboard.guard';
import { EmptyDashboardGameSetGuard } from './guards/empty-dashboard-game-set.guard';
import { CanActivateGameLanding } from './guards/game-landing.guard';
import { CanActivateLogin } from './guards/login.guard';
import { CanActivateMain } from './guards/main.guard';
import { CanActivateParameterizedGame } from './guards/parameterized-game.guard';
import { CanActivatePartner, CanActivatePartnerEarnings } from './guards/partner.guard';
import { CanActivatePayment } from './guards/payment.guard';
import { AdService } from './services/ad.service';
import { BraintreeService } from './services/braintree.service';
import { GameAwareHttp } from './services/game-aware-http.service';
import { GameCommonService } from './services/game-common.service';
import { HelpersService } from './services/helpers.service';
import { IconsService } from './services/icons.service';
import { InfiniteScrollService } from './services/infinite-scroll.service';
import { JWTService } from './services/jwt-service';
import { LocalStorageService } from './services/local-storage.service';
import { MainHttp } from './services/main-http.service';
import { ROUTER_SCROLL_SERVICE, RouterScrollServiceImpl } from './services/router-scroll.service';
import { ScriptService } from './services/scripts.service';
import { SEOService } from './services/seo.service';
import { ServiceWorkerService } from './services/service-worker.service';
import { SocketService } from './services/socket.service';
import { StripeService } from './services/stripe.service';
import { TrackService } from './services/track.service';
import { TweetService } from './services/tweet.service';
import { DashboardGameSetGuardV2 } from './guards/dashboard-game-set-v2.guard';

export function provideCore(): Provider[] {
    return [
        HelpersService,
        SocketService,
        MainHttp,
        GameAwareHttp,
        CanActivateLogin,
        CanActivatePayment,
        CanActivateMain,
        CanActivateDashboard,
        CanActivateAffiliate,
        CanActivateAccount,
        DashboardGameSetGuard,
        EmptyDashboardGameSetGuard,
        DashboardGameSetGuardV2,
        CanActivateGameLanding,
        CanActivateParameterizedGame,
        StripeService,
        TrackService,
        BraintreeService,
        JWTService,
        ReferralResolver,
        InfiniteScrollService,
        GameCommonService,
        ServiceWorkerService,
        ScriptService,
        CanActivatePartner,
        CanActivatePartnerEarnings,
        TweetService,
        SEOService,
        AdService,
        LocalStorageService,
        IconsService,
        BreadcrumbService,
        DashboardOutletSEOResolver,
        {
            provide: ROUTER_SCROLL_SERVICE,
            useClass: RouterScrollServiceImpl
        }
    ];
}

