import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, takeWhile } from 'rxjs/operators';
import { Game, GameDisplayName, GameMetadata } from '../../../../core/constants/game.enum';
import { GlobalState } from '../../../../store/store';

import * as searchActions from '../../../../store/actions/search.actions';
import * as persistSelectors from '../../../../store/selectors/persist.selectors';
import * as searchSelectors from '../../../../store/selectors/search.selectors';
import { DashboardInputFieldComponent } from '../dashboard-input-field/dashboard-input-field.component';
import { NgIf, NgClass, NgFor } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { ClickOutsideDirective } from '../../../directives/click-outside.directive';
import { EscapePressedDirective } from '../../../directives/esc-key.directive';

@Component({
    selector: 'app-dashboard-search-autocomplete',
    templateUrl: './dashboard-search-autocomplete.component.html',
    styleUrls: ['./dashboard-search-autocomplete.component.scss'],
    standalone: true,
    imports: [EscapePressedDirective, ClickOutsideDirective, MatIconModule, DashboardInputFieldComponent, NgIf, NgClass, NgFor]
})
export class DashboardSearchAutocompleteComponent implements OnInit {
    @ViewChild('form', { static: true }) form: DashboardInputFieldComponent;
    @Output() close: EventEmitter<void> = new EventEmitter<void>();

    private componentAlive: boolean = true;
    private game: Game;

    public searchValue: string = '';
    public autocompleteSearchResults: {
        text: string;
        context?: string;
        data?: any;
        type?: string;
        permalink?: string[];
    }[] = [];
    public autocompleteTerms: string[] = [];
    public contextMenuOpen: boolean = false;
    public autocompleteTermsVisible: boolean = false;
    public autocompleteGroups: any[] = [];

    constructor(
        private store: Store<GlobalState>,
        private router: Router,
        private route: ActivatedRoute,
        private cdr: ChangeDetectorRef
    ) {}

    private createSuggestionPermalink(suggestion) {
        switch (suggestion.type) {
            case 'articles':
                return ['/articles', suggestion.data.slug];
            case 'courses':
                return [
                    '/course',
                    suggestion.context,
                    suggestion.data.slug + '~' + suggestion.data._id
                ];
            case 'videoguides':
                return [
                    '/course',
                    suggestion.context,
                    suggestion.data.courseSlug + '~' + suggestion.data.course,
                    suggestion.data.slug + '~' + suggestion.data._id
                ];
            default:
                return [];
        }
    }

    public suggestContent(term: string) {
        if (this.searchValue === term) {
            return;
        }

        const searchQuery = {
            term: term || '',
            entitiesSuggestCount: 5
        };
        this.searchValue = term;

        if (searchQuery.term !== '') {
            this.store.dispatch(
                searchActions.loadAutocompleteResults({ payload: { ...searchQuery } })
            );
        } else {
            this.store.dispatch(searchActions.autocompleteResultsReset());
        }
    }

    public toggleContextMenu(visible: boolean) {
        if (visible === true) {
            this.contextMenuOpen = true;
        } else {
            this.contextMenuOpen = visible;
        }
    }

    public historyTermRemove(term: string) {
        this.store.dispatch(searchActions.autocompleteTermRemove({ payload: { term } }));
    }

    public trackBy(index, item) {
        return index;
    }

    public submitForm(suggestion: { text: string; permalink?: string[] }) {
        this.form.blur();

        if (suggestion.permalink && suggestion.permalink.length) {
            this.router.navigate(suggestion.permalink);
            return this.toggleContextMenu(false);
        }

        if (suggestion.text && suggestion.text.length > 0) {
            this.searchValue = suggestion.text;
            this.router.navigate(['search'], { queryParams: { query: suggestion.text } });
            this.toggleContextMenu(false);
        }
    }

    public resetForm() {
        if (this.searchValue !== '' || this.autocompleteSearchResults?.length > 0) {
            this.searchValue = '';
            this.autocompleteSearchResults = [];
        }
    }

    public focus() {
        this.form.focus();
    }

    ngOnInit(): void {
        this.store
            .select(searchSelectors.selectAutocompleteSearchResults)
            .pipe(
                takeWhile(() => this.componentAlive),
                filter(arr => {
                    return (
                        arr
                            .filter(
                                el => !this.autocompleteSearchResults.some(e => e.text === el.text)
                            )
                            .concat(
                                this.autocompleteSearchResults.filter(
                                    el => !arr.some(e => e.text === el.text)
                                ) as any
                            ).length > 0
                    );
                })
            )
            .subscribe((autocompleteSearchResults = []) => {
                const curQuery = this.searchValue;

                if (
                    curQuery &&
                    curQuery.trim().length > 0 &&
                    !autocompleteSearchResults.some(e => e.text === curQuery)
                ) {
                    this.autocompleteSearchResults = [
                        { text: this.searchValue },
                        ...autocompleteSearchResults
                    ];
                } else {
                    this.autocompleteSearchResults = autocompleteSearchResults;
                }

                this.autocompleteGroups = this.autocompleteSearchResults.reduce((acc, result) => {
                    const groupName = result.context;
                    let group: any = acc.find(g => g.id === groupName);
                    const data = result?.data;
                    const item = {
                        ...result,
                        permalink: this.createSuggestionPermalink(result)
                    };

                    if (group) {
                        group.items.push(item);
                    } else {
                        group = {
                            id: groupName,
                            name: GameDisplayName[groupName as string] || groupName,
                            items: [item],
                            iconImage: GameMetadata[groupName as string]?.logoPath || null
                        };
                        acc.push(group);
                    }

                    return acc;
                }, [] as { id: string; displayName: string; items: any[]; iconImage: string }[]);

                if (!this.contextMenuOpen) {
                    this.toggleContextMenu(true);
                }
            });

        this.store
            .select(persistSelectors.selectAutocompleteTerms)
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe((autocompleteTerms: string[]) => {
                this.autocompleteTerms = autocompleteTerms.reverse();
            });
        this.store
            .select(persistSelectors.selectCurrentGame)
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe((currentGame: Game) => {
                this.game = currentGame;
            });
        this.route.queryParams.pipe(takeWhile(() => this.componentAlive)).subscribe(params => {
            if (params.query) {
                this.searchValue = params.query;
            }
        });
    }

    ngOnDestroy() {
        this.componentAlive = false;
    }
}

