import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { takeWhile } from 'rxjs/operators';
import { AppSettings } from '../../../core/constants/AppSettings';
import { getThumbnailUrl } from '../../../core/constants/utils';
import { HelpersService } from '../../../core/services/helpers.service';
import { ScriptService } from '../../../core/services/scripts.service';
import { Campaign } from '../../../models/campaign.model';
import { loadSubscriptions } from '../../../store/actions/payment.actions';
import { validateBannerPromoCode } from '../../../store/actions/referral.actions';
import { selectEnabledBannerCampaign } from '../../../store/selectors/referral.selectors';
import { UserSelectors } from '../../../store/selectors/user.selectors';
import { GlobalState } from '../../../store/store';
import { L10nTranslateDirective } from 'angular-l10n';
import { NgIf } from '@angular/common';

declare var particlesJS: any;

@Component({
    selector: 'header-banner-festive',
    templateUrl: './header-banner-festive.component.html',
    styleUrls: ['./header-banner-festive.component.scss'],
    standalone: true,
    imports: [NgIf, L10nTranslateDirective]
})
export class HeaderBannerFestiveComponent implements OnInit, OnDestroy {
    @Input() snowParticlesEnabled: boolean;

    private componentAlive: boolean = true;
    private isLoggedIn: boolean = false;
    private interval;

    public promoCode?: string | null;
    public countdownTime: any = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
    };
    public bannerHTML: SafeHtml;
    public getThumbnailUrl = getThumbnailUrl;
    public campaign: Campaign;

    constructor(
        private userSelectors: UserSelectors,
        private router: Router,
        private scriptService: ScriptService,
        private store: Store<GlobalState>,
        private helpers: HelpersService
    ) {}

    private countdown(date: any) {
        const endDate: number = new Date(date).getTime();
        if (isNaN(endDate)) {
            return;
        }
        clearInterval(this.interval);
        this.interval = null;

        const calculate = () => {
            let t: any = Date.parse(date) - Date.now();

            if (t >= 0) {
                let seconds = Math.floor((t / 1000) % 60);
                let minutes = Math.floor((t / 1000 / 60) % 60);
                let hours = Math.floor((t / (1000 * 60 * 60)) % 24);
                let days = Math.floor(t / (1000 * 60 * 60 * 24));

                this.countdownTime.days = Math.round(days);
                this.countdownTime.hours = ('0' + hours).slice(-2);
                this.countdownTime.minutes = ('0' + minutes).slice(-2);
                this.countdownTime.seconds = ('0' + seconds).slice(-2);
            } else {
                clearInterval(this.interval);
                this.interval = null;
                return;
            }
        };

        this.interval = setInterval(calculate, 1000);
    }

    private async loadParticles() {
        // await this.scriptService.loadScript(Scripts.PARTICLES);
        // particlesJS.load('particles', 'assets/lib/snow-particles.json');
    }

    public click() {
        this.store.dispatch(validateBannerPromoCode({ payload: { promoCode: this.promoCode } }));

        if (this.isLoggedIn) {
            this.router.navigate(['/signup/checkout', AppSettings.PROMO_DISCOUNTED_PLAN]);
        } else {
            this.router.navigate(['/signup']);
        }
    }

    ngAfterViewInit() {
        this.store
            .select(selectEnabledBannerCampaign)
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(campaign => {
                if (campaign) {
                    this.campaign = campaign;
                    this.countdown(campaign.bannerSettings.endDate);
                    this.promoCode = campaign.promoCode.code;
                    this.bannerHTML = this.helpers.processModalHTML(campaign?.bannerSettings?.content, campaign);
                }
            });
    }

    async ngOnInit() {
        if (this.snowParticlesEnabled) {
            await this.loadParticles();
        }
        this.userSelectors.account$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(account => {
                if (account && account.id) {
                    this.store.dispatch(loadSubscriptions());
                }
            });
        this.userSelectors.isLoggedIn$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(isLoggedIn => {
                this.isLoggedIn = isLoggedIn;
            });
    }

    ngOnDestroy() {
        this.componentAlive = false;
    }
}

