import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { SEOConfiguration } from './dashboard-outlet-seo-configuration';

const PathSEOMap = {
    dashboard: 'landing',
    landing: 'landing',
    courses: 'courses',
    'video-guides': 'videoGuides',
    news: 'news'
};

@Injectable()
export class DashboardOutletSEOResolver {
    constructor() {}

    public resolve(route: ActivatedRouteSnapshot) {
        const game = route.data?.game || route.params.game;
        const gameSEOConfig = SEOConfiguration[game];
        let path = route.routeConfig?.path;
        // handle :game/courses routes and other parameterized routes
        if (path && path?.indexOf('/') > -1) {
            path = path.split(`/`)[0];
        }

        path = path == game ? 'landing' : path || 'landing';
        let pathSEO: any;

        if (gameSEOConfig) {
            pathSEO = gameSEOConfig[PathSEOMap[path]];
        } else {
            pathSEO = PathSEOMap[path];
        }

        return pathSEO;
    }
}

