<div id="footer" [ngClass]="{ hidden: isHidden }">
    <div class="container gl-flex valign-center">
        <div class="block">
            <picture>
                <source
                    srcset="/assets/images/header/gameleap_small_light_flat.webp"
                    type="image/webp"
                />
                <source
                    srcset="/assets/images/header/gameleap_small_light_flat.png"
                    type="image/png"
                />
                <img
                    loading="lazy"
                    src="/assets/images/header/gameleap_small_light_flat.png"
                    class="logo"
                    alt="GameLeap logo"
                />
            </picture>
        </div>
        <div class="block">
            <div class="games block-list">
                <p>Games</p>

                <ul>
                    <li>
                        <a aria-label="Dota 2" routerLink="/dota">
                            <picture>
                                <source
                                    srcset="/assets/images/dota/dota2logo_small.webp"
                                    type="image/webp"
                                />
                                <source
                                    srcset="/assets/images/dota/dota2logo_small.png"
                                    type="image/png"
                                />
                                <img
                                    src="/assets/images/dota/dota2logo_small.png"
                                    class="game-logo"
                                    alt="Dota 2 Logo"
                                />
                            </picture>
                            Dota 2
                        </a>
                    </li>

                    <li>
                        <a aria-label="League of Legends" routerLink="/league">
                            <picture>
                                <source
                                    srcset="/assets/images/league/leaguelogo_small.webp"
                                    type="image/webp"
                                />
                                <source
                                    srcset="/assets/images/league/leaguelogo_small.png"
                                    type="image/png"
                                />
                                <img
                                    loading="lazy"
                                    src="/assets/images/league/leaguelogo_small.png"
                                    class="game-logo"
                                    alt="League of Legends logo"
                                />
                            </picture>
                            League of Legends
                        </a>
                    </li>

                    <li>
                        <a aria-label="Halo Infinite" routerLink="/halo">
                            <picture>
                                <source
                                    srcset="/assets/images/halo/haloinfinitelogo_small.webp"
                                    type="image/webp"
                                />
                                <source
                                    srcset="/assets/images/halo/haloinfinitelogo_small.png"
                                    type="image/png"
                                />
                                <img
                                    loading="lazy"
                                    src="/assets/images/halo/haloinfinitelogo_small.png"
                                    class="game-logo"
                                    alt="Halo Infinite logo"
                                />
                            </picture>
                            Halo Infinite
                        </a>
                    </li>

                    <li>
                        <a aria-label="Apex Legends" routerLink="/apex">
                            <picture>
                                <source
                                    srcset="/assets/images/apex/apexlogo_small.webp"
                                    type="image/webp"
                                />
                                <source
                                    srcset="/assets/images/apex/apexlogo_small.png"
                                    type="image/png"
                                />
                                <img
                                    loading="lazy"
                                    src="/assets/images/apex/apexlogo_small.png"
                                    class="game-logo"
                                    alt="Apex Legends logo"
                                />
                            </picture>
                            Apex Legends
                        </a>
                    </li>

                    <li>
                        <a aria-label="Valorant" routerLink="/valorant">
                            <picture>
                                <source
                                    srcset="/assets/images/valorant/valorantlogo_small.webp"
                                    type="image/webp"
                                />
                                <source
                                    srcset="/assets/images/valorant/valorantlogo_small.png"
                                    type="image/png"
                                />
                                <img
                                    loading="lazy"
                                    src="/assets/images/valorant/valorantlogo_small.png"
                                    class="game-logo"
                                    alt="Valorant logo"
                                />
                            </picture>
                            Valorant
                        </a>
                    </li>

                    <li>
                        <a aria-label="Overwatch 2" routerLink="/overwatch2">
                            <picture>
                                <source
                                    srcset="/assets/images/overwatch2/overwatch2_logo_small.webp"
                                    type="image/webp"
                                />
                                <source
                                    srcset="/assets/images/overwatch2/overwatch2_logo_small.png"
                                    type="image/png"
                                />
                                <img
                                    loading="lazy"
                                    src="/assets/images/overwatch2/overwatch2_logo_small.png"
                                    class="game-logo"
                                    alt="Overwatch 2 logo"
                                />
                            </picture>
                            Overwatch 2
                        </a>
                    </li>

                    <li>
                        <a aria-label="Fortnite" routerLink="/fortnite">
                            <picture>
                                <source
                                    srcset="/assets/images/fortnite/fortnite_logo_small.webp"
                                    type="image/webp"
                                />
                                <source
                                    srcset="/assets/images/fortnite/fortnite_logo_small.png"
                                    type="image/png"
                                />
                                <img
                                    loading="lazy"
                                    src="/assets/images/fortnite/fortnite_logo_small.png"
                                    class="game-logo"
                                    alt="Fortnite logo"
                                />
                            </picture>
                            Fortnite
                        </a>
                    </li>
                </ul>
            </div>
        </div>

        <div class="block company-links">
            <div class="resources block-list">
                <p>Resources</p>

                <ul class="just-list">
                    <li>
                        <a aria-label="Privacy Policy" routerLink="/support/privacy">
                            Privacy Policy
                        </a>
                    </li>

                    <li>
                        <a aria-label="Terms of Use" routerLink="/support/terms">
                            Terms of Use
                        </a>
                    </li>
                    <li>
                        <a
                            aria-label="Frequently Asked Questions"
                            routerLink="/support/faq"
                            translate
                        >
                            Frequently Asked Questions
                        </a>
                    </li>
                    <li>
                        <a aria-label="Cookies Policy" routerLink="/support/cookies" translate>
                            Cookie Policy
                        </a>
                    </li>
                </ul>

                <p>Help</p>

                <ul class="just-list">
                    <li>
                        <a aria-label="Email GameLeap" href="mailto:support@gameleap.com">
                            support&#64;gameleap.com
                        </a>
                    </li>
                </ul>
            </div>

            <div class="block-list-empty"></div>
        </div>

        <div class="block social-networks-container">
            <div class="block-list">
                <p>SOCIAL</p>
                <ul class="social-networks">
                    <li>
                        <a aria-label="YouTube channel" [href]="youtubeChannelUrl" target="_blank">
                            <mat-icon fontSet="brands" svgIcon="youtube"></mat-icon>
                            <span>YouTube</span>
                        </a>
                    </li>

                    <li>
                        <a aria-label="Facebook Page" [href]="facebookPageUrl" target="_blank">
                            <mat-icon fontSet="brands" svgIcon="facebook"></mat-icon>
                            <span>Facebook</span>
                        </a>
                    </li>

                    <li>
                        <a aria-label="Twitter Page" [href]="twitterPageUrl" target="_blank">
                            <mat-icon fontSet="brands" svgIcon="twitter"></mat-icon>
                            <span>Twitter</span>
                        </a>
                    </li>

                    <li>
                        <a aria-label="Discord Server" [href]="discordServerLink" target="_blank">
                            <mat-icon fontSet="brands" svgIcon="discord"></mat-icon>
                            <span>Discord</span>
                        </a>
                    </li>
                </ul>
                <p>BUSINESS</p>

                <ul class="just-list">
                    <li>
                        <a aria-label="Email GameLeap" href="mailto:ivan@gameleap.com">
                            ivan&#64;gameleap.com
                        </a>
                    </li>
                </ul>
            </div>
            <!-- <ul class="discord">
                <li>
                    <a class="discord-button" target="_blank" [href]="discordServerLink" translate>
                        <span class="discord-white-icon" translate></span>
                        app_footer_discord-cta
                    </a>
                </li>
            </ul> -->
        </div>
        <!-- <div class="block">
            <div class="block-list">
                <p>MOBILE</p>
                <ul class="app-stores">
                    <li>
                        <a aria-label="Download on Apple App Store" [href]="appStoreUrl" target="_blank">
                            <img alt="Download on Apple App Store" src="/assets/images/general/app-store.svg" />
                        </a>
                    </li>
                    <li>
                        <a aria-label="Dwonload on Google Play Store" [href]="playStoreUrl" target="_blank">
                            <img alt="Download on Google Play Store" src="/assets/images/general/play-store.svg" />
                        </a>
                    </li>
                </ul>
            </div>
        </div> -->
    </div>
    <div class="misc gl-flex valign-center">
        <!-- <p class="email-link" translate>
            Are you a Streamer/YouTuber/High Ranked?
            <a href="mailto:support@gameleap.com">Apply for Partnership</a>
        </p> -->
        <span class="disclaimer" translate>
            This website is not affiliated with or endorsed by Valve Corporation, Blizzard
            Entertainment, Activision, Epic Games, Riot Games, 343 Industries or any other gaming
            company and/or their affiliates. All trademarks, images, gameplay videos and sounds
            appearing on the site are the property of their respective owners. GameLeap publishes
            content under the fair-use doctrine.
        </span>

        <p class="copy-text">
            <picture>
                <source
                    srcset="/assets/images/header/gameleap_big_light_flat.webp"
                    type="image/webp"
                />
                <source
                    srcset="/assets/images/header/gameleap_big_light_flat.png"
                    type="image/png"
                />
                <img
                    loading="lazy"
                    src="/assets/images/header/gameleap_big_light_flat.png"
                    class="logo"
                    alt="GameLeap logo"
                />
            </picture>
            <span> &copy; {{ currentYear }} GameLeap Inc. All rights reserved. </span>
        </p>
    </div>
</div>

