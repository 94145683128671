import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { ApiEndpoints } from '../../core/constants/ApiEndpoints';
import { MainHttp } from '../../core/services/main-http.service';
import { newPageView, newPageViewFailure } from '../actions/page-views.actions';
import { PayloadAction } from '../interfaces/payload-action.interface';

@Injectable()
export class PageViewEffects {
    constructor(private actions$: Actions, private http: MainHttp) {}

    newPageView$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType<PayloadAction>(newPageView),
                mergeMap(({ payload }) => {
                    return this.http
                        .post(ApiEndpoints.newPageView, payload)
                        .pipe(catchError(error => of(newPageViewFailure({ payload: error }))));
                })
            ),
        { dispatch: false }
    );
}

