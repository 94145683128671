import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, finalize, map, mergeMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { DashboardEndpoints } from '../../core/constants/ApiEndpoints';
import { PayloadAction } from '../interfaces/payload-action.interface';
import { Comment } from '../../models/comment.model';
import { GameAwareHttp } from '../../core/services/game-aware-http.service';
import { GlobalState } from '../store';

import * as loadingActions from '../actions/loading.actions';
import * as commentsActions from '../actions/comments.actions';

@Injectable()
export class CommentsEffects {
    constructor(
        private actions$: Actions,
        private http: GameAwareHttp,
        private store: Store<GlobalState>
    ) {}

    /**
     * Loads all comments for a video guide.
     * @param action$
     */
    public loadComments$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(commentsActions.loadComments),
            // tap(() => this.store.dispatch(signupErrorReset())),
            // tap(() => this.store.dispatch(loadingActions.signupUserLoading())),
            mergeMap(({ payload }) =>
                this.http.get(DashboardEndpoints.getComments(payload.videoGuideId)).pipe(
                    map((comments: Comment[]) =>
                        commentsActions.loadCommentsSuccess({
                            payload: comments
                        })
                    ),
                    catchError(error => of(commentsActions.loadCommentsFailure({ payload: error })))
                    // finalize(() => this.store.dispatch(loadingActions.signupUserLoadingFinished()))
                )
            )
        )
    );

    /**
     * Posts a new comment, or a reply to a comment.
     * @param action$
     */
    public newComment$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(commentsActions.newComment),
            tap(() => this.store.dispatch(loadingActions.newCommentLoading())),
            mergeMap(({ payload }) =>
                this.http.post(DashboardEndpoints.newComment, payload).pipe(
                    map((comment: Comment) =>
                        commentsActions.newCommentSuccess({
                            payload: comment
                        })
                    ),
                    catchError(error => of(commentsActions.newCommentFailure({ payload: error }))),
                    finalize(() => this.store.dispatch(loadingActions.newCommentLoadingFinished()))
                )
            )
        )
    );

    /**
     * Updates a comment.
     * @param action$
     */
    public updateComment$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(commentsActions.updateComment),
            tap(() => this.store.dispatch(loadingActions.updateCommentLoading())),
            mergeMap(({ payload }) =>
                this.http
                    .put(
                        DashboardEndpoints.updateComment(payload.commentId, payload.userId),
                        payload.data
                    )
                    .pipe(
                        map((comment: Comment) =>
                            commentsActions.updateCommentSuccess({
                                payload: comment
                            })
                        ),
                        catchError(error =>
                            of(commentsActions.updateCommentFailure({ payload: error }))
                        ),
                        finalize(() =>
                            this.store.dispatch(loadingActions.updateCommentLoadingFinished())
                        )
                    )
            )
        )
    );

    /**
     * Deletes a comment.
     * @param action$
     */
    public deleteComment$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(commentsActions.deleteComment),
            // tap(() => this.store.dispatch(signupErrorReset())),
            // tap(() => this.store.dispatch(loadingActions.signupUserLoading())),
            mergeMap(({ payload }) =>
                this.http
                    .delete(DashboardEndpoints.deleteComment(payload.commentId, payload.userId))
                    .pipe(
                        map((comment: Comment) =>
                            commentsActions.deleteCommentSuccess({
                                payload: comment
                            })
                        ),
                        catchError(error =>
                            of(commentsActions.deleteCommentFailure({ payload: error }))
                        )
                        // finalize(() => this.store.dispatch(loadingActions.signupUserLoadingFinished()))
                    )
            )
        )
    );
}
