import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { takeWhile } from 'rxjs/operators';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { HelpersService } from '../../../../core/services/helpers.service';
import { Location, NgClass } from '@angular/common';
import { AppSettings } from '../../../../core/constants/AppSettings';
import { MatIconModule } from '@angular/material/icon';
import { L10nTranslateDirective } from 'angular-l10n';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    standalone: true,
    imports: [NgClass, RouterLink, L10nTranslateDirective, MatIconModule]
})
export class FooterComponent implements OnInit {
    private componentAlive: boolean = true;
    private hiddenOnMobileUrls = ['course'];
    public currentYear: number = (new Date()).getFullYear();
    public facebookPageUrl: string = 'https://www.facebook.com/gameleap/';
    public twitterPageUrl: string = 'https://twitter.com/gameleap';
    public youtubeChannelUrl: string = 'https://www.youtube.com/channel/UCy0-ftAwxMHzZc74OhYT3PA/featured';
    public dotaWebsiteUrl: string = 'https://www.gameleap.com/dota';
    public appStoreUrl: string = 'https://apps.apple.com/app/gameleap/id1516123608';
    public playStoreUrl: string = 'https://play.google.com/store/apps/details?id=com.gameleap.app';
    public isHidden: boolean = false;
    public discordServerLink: string = 'https://discord.gg/' + AppSettings.DISCORD_SERVER_INVITE_ID;

    constructor(private helpersService: HelpersService,
                private router: Router,
                private location: Location,
                private cdr: ChangeDetectorRef) {
    }

    private toggleVisibility(isHidden: boolean) {
        this.isHidden = isHidden;
        this.cdr.detectChanges();
    }

    private determineVisibilityState(url: string) {
        const urlParts = url.split('/');
        let isHiddenUrl = false;

        if (this.helpersService.isMobileDevice()) {
            isHiddenUrl = this.hiddenOnMobileUrls.some(hiddenUrl => {
                return urlParts.includes(hiddenUrl);
            });
        }

        this.toggleVisibility(isHiddenUrl);
    }

    ngOnInit() {
        this.determineVisibilityState(this.location.path());
        this.router.events.pipe(
            takeWhile(() => this.componentAlive))
            .subscribe(data => {
                if (data instanceof NavigationEnd) {
                    this.determineVisibilityState(data.url);
                }
            });
    }

    ngOnDestroy() {
        this.componentAlive = false;
    }
}
