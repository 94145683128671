import { Provider } from '@angular/core';
import { DashboardOutletScrollService } from '../dashboard/dashboard-scroll.service';
import { VideoPlayerService } from './components/video-player/video-player.service';
import { DeepFilterPipe } from './pipes/deep-filter.pipe';
import { FormatDurationPipe } from './pipes/format-duration.pipe';
import { AnchorLinkService } from './services/anchor-link.service';
import { CommonService } from './services/common.service';
import { ConnectionsService } from './services/connections.service';
import { ScrollService } from './services/scroll.service';
import { WINDOW_PROVIDERS } from './services/window.service';

export function provideShared(): Provider[] {
    return [
        WINDOW_PROVIDERS,
        VideoPlayerService,
        CommonService,
        ConnectionsService,
        ScrollService,
        DashboardOutletScrollService,
        AnchorLinkService,
        FormatDurationPipe,
        DeepFilterPipe
    ];
}

