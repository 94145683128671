import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';
import { DashboardEndpoints } from '../../core/constants/ApiEndpoints';
import { GlobalState } from '../store';

import { concatLatestFrom } from '../../core/operators/concat-latest-from';
import { MainHttp } from '../../core/services/main-http.service';
import * as loadingActions from '../actions/loading.actions';
import * as searchActions from '../actions/search.actions';
import * as routeSelectors from '../selectors/route.selectors';

@Injectable()
export class SearchEffects {
    constructor(
        private actions$: Actions,
        private http: MainHttp,
        private store: Store<GlobalState>
    ) {}

    public loadAutocompleteResults$ = createEffect(() =>
        this.actions$.pipe(
            ofType(searchActions.loadAutocompleteResults),
            switchMap(({ payload }) =>
                this.http.get(DashboardEndpoints.autocompleteResults, payload).pipe(
                    map((results: { text: string; context: string }[]) =>
                        searchActions.loadAutocompleteResultsSuccess({ payload: results })
                    ),
                    catchError(error =>
                        of(
                            searchActions.loadAutocompleteResultsFailure({
                                payload: error
                            })
                        )
                    )
                )
            )
        )
    );

    public suggestContent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(searchActions.loadSuggestedContent),
            tap(() => this.store.dispatch(loadingActions.suggestedContentLoading())),
            concatLatestFrom(action => this.store.select(routeSelectors.selectQueryParam('query'))),
            switchMap(([action, query]) =>
                this.http
                    .get(DashboardEndpoints.suggestContent, {
                        term: query,
                        entitiesSuggestCount: 20
                    })
                    .pipe(
                        map(result =>
                            searchActions.loadSuggestedContentSuccess({ payload: result })
                        ),
                        catchError(error =>
                            of(
                                searchActions.loadSuggestedContentFailure({
                                    payload: error
                                })
                            )
                        ),
                        finalize(() => {
                            if (query) {
                                this.store.dispatch(
                                    searchActions.autocompleteTermAdd({
                                        payload: { term: query }
                                    })
                                );
                            }

                            this.store.dispatch(loadingActions.suggestedContentLoadingFinished());
                        })
                    )
            )
        )
    );
}

