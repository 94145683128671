import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, finalize, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { DashboardEndpoints } from '../../core/constants/ApiEndpoints';
import { MainHttp } from '../../core/services/main-http.service';
import { Article } from '../../models/article.model';
import { PayloadAction } from '../interfaces/payload-action.interface';
import { GlobalState } from '../store';

import { ArticleCategory } from '../../models/article-category.model';
import * as loadingActions from '../actions/loading.actions';
import * as newsActions from '../actions/news.actions';
import { CategoryArticles } from '../reducers/news.reducer';
import * as routeSelectors from '../selectors/route.selectors';

@Injectable()
export class NewsEffects {
    constructor(
        private actions$: Actions,
        private http: MainHttp,
        private store: Store<GlobalState>
    ) {}

    /**
     * Loads all articles.
     * @param action$
     */
    public loadArticles$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(newsActions.loadArticles),
            tap(() => this.store.dispatch(loadingActions.loadRecentNewsLoading())),
            mergeMap(({ payload }) =>
                this.http.get(DashboardEndpoints.loadArticles, payload).pipe(
                    map((articles: Article[]) => {
                        return newsActions.loadArticlesSuccess({ payload: articles });
                    }),
                    catchError(error => of(newsActions.loadArticlesFailure({ payload: error }))),
                    finalize(() =>
                        this.store.dispatch(loadingActions.loadRecentNewsLoadingFinished())
                    )
                )
            )
        )
    );

    /**
     * Loads all articles.
     * @param action$
     */
    public loadArticle$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(newsActions.loadArticle),
            concatLatestFrom(action => this.store.select(routeSelectors.selectRouteParam('slug'))),
            mergeMap(([{ payload }, slug]) =>
                this.http
                    .get(DashboardEndpoints.loadArticle(slug?.split('~')[0] as string), payload)
                    .pipe(
                        map((article: Article) => {
                            return newsActions.loadArticleSuccess({ payload: article });
                        }),
                        catchError(error => of(newsActions.loadArticleFailure({ payload: error })))
                    )
            )
        )
    );

    /**
     * Loads an article or a list of articles belonging to a category.
     * @param action$
     */
    public loadArticleOrCategoryArticles$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(newsActions.loadArticleOrCategoryArticles),
            concatLatestFrom(action => this.store.select(routeSelectors.selectRouteParams)),
            mergeMap(([{ payload }, params]) =>
                this.http
                    .get(
                        DashboardEndpoints.loadArticleOrCategoryArticles(
                            params?.slug?.split('~')[0] as string
                        ),
                        { ...payload, ...(params?.type && { type: params.type }) }
                    )
                    .pipe(
                        map(
                            (data: {
                                type: 'article' | 'category-articles';
                                articles: Article[];
                            }) => {
                                return newsActions.loadArticleOrCategoryArticlesSuccess({
                                    payload: data
                                });
                            }
                        ),
                        catchError(error =>
                            of(newsActions.loadArticleOrCategoryArticlesFailure({ payload: error }))
                        )
                    )
            )
        )
    );

    /**
     * Loads all articles.
     * @param action$
     */
    public updateArticleViews$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(newsActions.updateArticleViews),
            mergeMap(({ payload }) =>
                this.http.post(DashboardEndpoints.updateArticleViews(payload.articleId)).pipe(
                    map((article: Article) => {
                        return newsActions.updateArticleViewsSuccess({ payload: article });
                    }),
                    catchError(error =>
                        of(newsActions.updateArticleViewsFailure({ payload: error }))
                    )
                )
            )
        )
    );

    /**
     * Loads all recent articles grouped by category.
     * @param action$
     */
    public loadRecentArticlesByCategory$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(newsActions.loadRecentArticlesByCategory),
            // tap(() => this.store.dispatch(loadingActions.loadRecentNewsLoading())),
            mergeMap(({ payload }) =>
                this.http.get(DashboardEndpoints.loadRecentArticlesByCategory, payload).pipe(
                    map((articles: CategoryArticles[]) => {
                        return newsActions.loadRecentArticlesByCategorySuccess({
                            payload: articles
                        });
                    }),
                    catchError(error =>
                        of(newsActions.loadRecentArticlesByCategoryFailure({ payload: error }))
                    )
                    // finalize(() =>
                    //     this.store.dispatch(loadingActions.loadRecentNewsLoadingFinished())
                    // )
                )
            )
        )
    );

    /**
     * Loads category articles.
     * @param action$
     */
    public loadCategoryArticles$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(newsActions.loadCategoryArticles),
            tap(() => this.store.dispatch(loadingActions.loadCategoryArticlesLoading())),
            withLatestFrom(
                this.store.select(routeSelectors.selectRouteParam('slug')),
                this.store.select(routeSelectors.selectQueryParam('type'))
            ),
            mergeMap(([{ payload }, slug, type]) => {
                return this.http
                    .get(DashboardEndpoints.loadCategoryArticles(slug as string), {
                        ...(type && { type }),
                        ...payload
                    })
                    .pipe(
                        map((articles: Article[]) => {
                            return newsActions.loadCategoryArticlesSuccess({
                                payload: articles
                            });
                        }),
                        catchError(error =>
                            of(newsActions.loadCategoryArticlesFailure({ payload: error }))
                        ),
                        finalize(() =>
                            this.store.dispatch(
                                loadingActions.loadCategoryArticlesLoadingFinished()
                            )
                        )
                    );
            })
        )
    );
    /**
     * Loads article categories.
     * @param action$
     */
    public loadCategories$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(newsActions.loadCategories),
            tap(() => this.store.dispatch(loadingActions.loadCategoriesLoading())),

            mergeMap(({ payload }) => {
                return this.http
                    .get(DashboardEndpoints.loadCategories, {
                        ...payload
                    })
                    .pipe(
                        map((categories: ArticleCategory[]) => {
                            return newsActions.loadCategoriesSuccess({
                                payload: categories
                            });
                        }),
                        catchError(error =>
                            of(newsActions.loadCategoriesFailure({ payload: error }))
                        ),
                        finalize(() =>
                            this.store.dispatch(loadingActions.loadCategoriesLoadingFinished())
                        )
                    );
            })
        )
    );
}

