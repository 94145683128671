import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { takeWhile } from 'rxjs/operators';
import { getThumbnailUrl } from '../../../../core/constants/utils';
import { Account, Role } from '../../../../models/account.model';
import { logoutUser } from '../../../../store/actions/auth.actions';
import { selectAccount, selectIsLoggedIn } from '../../../../store/selectors/users.selectors';
import { GlobalState } from '../../../../store/store';
import { MatIconModule } from '@angular/material/icon';
import { L10nTranslateDirective } from 'angular-l10n';
import { RolesDirective } from '../../../directives/roles.directive';
import { RouterLink } from '@angular/router';
import { GlButtonComponent } from '../../gl-button/gl-button.component';
import { NgIf } from '@angular/common';
import { ClickOutsideDirective } from '../../../directives/click-outside.directive';
import { EscapePressedDirective } from '../../../directives/esc-key.directive';

@Component({
    selector: 'app-dashboard-header-dropdown',
    templateUrl: './dashboard-header-dropdown.component.html',
    styleUrls: ['./dashboard-header-dropdown.component.scss'],
    standalone: true,
    imports: [EscapePressedDirective, ClickOutsideDirective, NgIf, GlButtonComponent, RouterLink, RolesDirective, L10nTranslateDirective, MatIconModule]
})
export class DashboardHeaderDropdownComponent implements OnInit {
    private componentAlive: boolean = true;

    public Role = Role;
    public getThumbnailUrl = getThumbnailUrl;
    public account: Account;
    public dropdownOpen: boolean = false;
    public isLoggedIn: boolean = false;

    constructor(private store: Store<GlobalState>) {}

    public toggleDropdownOpen(isOpen: boolean) {
        this.dropdownOpen = isOpen;
    }

    public logout() {
        this.store.dispatch(logoutUser());
    }

    ngOnInit() {
        this.store
            .select(selectAccount)
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(account => {
                this.account = account;
            });
        this.store
            .select(selectIsLoggedIn)
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(isLoggedIn => {
                this.isLoggedIn = isLoggedIn;
            });
    }

    ngOnDestroy() {
        this.componentAlive = false;
    }
}
