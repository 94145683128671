import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectCurrentGame } from '../../store/selectors/persist.selectors';
import { selectIsLoggedIn } from '../../store/selectors/users.selectors';
import { GlobalState } from '../../store/store';
import { Game } from '../constants/game.enum';

@Injectable()
export class CanActivateDashboard {
    private currentGame: Game | null | undefined;
    private isLoggedIn: boolean;

    constructor(private store: Store<GlobalState>, private router: Router) {
        this.store.select(selectCurrentGame).subscribe(game => (this.currentGame = game));
        this.store.select(selectIsLoggedIn).subscribe(isLoggedIn => {
            this.isLoggedIn = isLoggedIn;
        });
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (!this.isLoggedIn) {
            this.router.navigate([this.currentGame ? this.currentGame : '/'], {
                replaceUrl: true,
                ...(route.queryParams && { queryParams: route.queryParams })
            });
            return false;
        }

        return true;
    }
}

