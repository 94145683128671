import { createReducer, on } from '@ngrx/store';
import * as cacheActions from '../actions/cache.actions';

export enum CacheEtagTypes {
    COURSES = 'courses',
    COURSES_FEATURED = 'courses_featured',
    COURSES_ENROLLED = 'courses_enrolled',
    COURSES_EXTENDED = 'courses_extended',
    VIDEOS_RECENT = 'videos_recent',
    VIDEOS_SEARCH = 'videos_search',
    VIDEOS_FEATURED = 'videos_featured',
    PLAYERS_FEATURED = 'players_featured',
    METADATA = 'metadata',
    TAGS = 'tags'
}
export interface CacheState {
    etagValues: { [key: string]: {} };
}

export const cacheInitialState: CacheState = {
    etagValues: {
        [CacheEtagTypes.COURSES_FEATURED]: '',
        [CacheEtagTypes.COURSES_ENROLLED]: '',
        [CacheEtagTypes.COURSES_EXTENDED]: '',
        [CacheEtagTypes.VIDEOS_RECENT]: '',
        [CacheEtagTypes.VIDEOS_SEARCH]: '',
        [CacheEtagTypes.VIDEOS_FEATURED]: '',
        [CacheEtagTypes.PLAYERS_FEATURED]: '',
        [CacheEtagTypes.METADATA]: '',
        [CacheEtagTypes.TAGS]: ''
    }
};

export const cacheReducer = createReducer(
    cacheInitialState,
    /**
     * On game change, clear all cached etags
     */
    // on(persistActions.setCurrentGame, state => ({
    //     ...state,
    //     ...cacheInitialState
    // })),
    on(cacheActions.clearCacheEtagValues, state => ({
        ...state,
        ...cacheInitialState
    })),
    /**
     * Courses Cache
     */
    on(cacheActions.loadFeaturedCoursesEtagSuccess, (state, { payload }) => ({
        ...state,
        etagValues: {
            ...state.etagValues,
            [CacheEtagTypes.COURSES_FEATURED]: payload
        }
    })),
    on(cacheActions.loadCoursesEtagSuccess, (state, { payload }) => ({
        ...state,
        etagValues: {
            ...state.etagValues,
            [CacheEtagTypes.COURSES]: payload
        }
    })),
    on(cacheActions.loadEnrolledCoursesEtagSuccess, (state, { payload }) => ({
        ...state,
        etagValues: {
            ...state.etagValues,
            [CacheEtagTypes.COURSES_ENROLLED]: payload
        }
    })),
    on(cacheActions.loadCourseEtagSuccess, (state, { payload }) => ({
        ...state,
        etagValues: {
            ...state.etagValues,
            [CacheEtagTypes.COURSES_EXTENDED]: {
                ...state.etagValues[CacheEtagTypes.COURSES_EXTENDED],
                [payload.courseId]: payload.etag
            }
        }
    })),
    /**
     * Tags Cache
     */
    on(cacheActions.loadTagsEtagSuccess, (state, { payload }) => ({
        ...state,
        etagValues: {
            ...state.etagValues,
            [CacheEtagTypes.TAGS]: payload
        }
    })),
    /**
     * Video Guides Cache
     */
    on(cacheActions.loadRecentVideoGuidesEtagSuccess, (state, { payload }) => ({
        ...state,
        etagValues: {
            ...state.etagValues,
            [CacheEtagTypes.VIDEOS_RECENT]: payload
        }
    })),
    on(cacheActions.loadFeaturedVideoGuidesEtagSuccess, (state, { payload }) => ({
        ...state,
        etagValues: {
            ...state.etagValues,
            [CacheEtagTypes.VIDEOS_FEATURED]: payload
        }
    })),
    on(cacheActions.loadSearchVideoGuidesEtagSuccess, (state, { payload }) => ({
        ...state,
        etagValues: {
            ...state.etagValues,
            [CacheEtagTypes.VIDEOS_SEARCH]: payload
        }
    })),
    /**
     * Players Cache
     */
    on(cacheActions.loadRecentVideoGuidesEtagSuccess, (state, { payload }) => ({
        ...state,
        etagValues: {
            ...state.etagValues,
            [CacheEtagTypes.VIDEOS_RECENT]: payload
        }
    })),
    /**
     * Metadata Cache
     */
    on(cacheActions.loadMetadataEtagSuccess, (state, { payload }) => ({
        ...state,
        etagValues: {
            ...state.etagValues,
            [CacheEtagTypes.METADATA]: payload
        }
    }))
);

