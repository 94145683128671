import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs';

import { isPlatformBrowser } from '@angular/common';
import {
    L10N_LOCALE,
    L10nConfig,
    L10nDateTimeFormatOptions,
    L10nLocale,
    L10nNumberFormatOptions,
    L10nProvider,
    L10nTranslationLoader,
    L10nValidation,
    parseDigits
} from 'angular-l10n';
import { environment } from '../environments/environment';

export const l10nConfig: L10nConfig = {
    format: 'language',
    providers: [],
    fallback: false,
    cache: true,
    keySeparator: '.',
    defaultLocale: { language: 'en', currency: 'USD', timeZone: 'America/Los_Angeles' },
    schema: [
        {
            locale: {
                language: 'en',
                currency: 'USD',
                timeZone: 'America/Los_Angeles',
                units: { length: 'mile' }
            },
            dir: 'ltr',
            text: 'English (US)'
        }
    ]
};

// @Injectable()
// export class AppStorage implements L10nStorage {
//     private hasStorage: boolean;

//     constructor(private storage: L10nStorage) {
//         this.hasStorage = typeof Storage !== 'undefined';
//     }

//     public async read(): Promise<L10nLocale | null> {
//         if (this.hasStorage) {
//             return Promise.resolve(this.storage.read());
//         }
//         return Promise.resolve(null);
//     }

//     public async write(locale: L10nLocale): Promise<void> {
//         if (this.hasStorage) {
//             this.storage.write(locale);
//         }
//     }
// }

@Injectable()
export class HttpTranslationLoader implements L10nTranslationLoader {
    private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    constructor(
        @Optional() private http: HttpClient,
        @Inject(PLATFORM_ID) private platformId: Object
    ) {}

    public get(language: string, provider: L10nProvider): Observable<{ [key: string]: any }> {
        const url = `${
            isPlatformBrowser(this.platformId)
                ? environment.clientRoot
                : environment.clientRootServer
        }${provider.asset}-${language}.json`;
        const options = {
            headers: this.headers,
            params: new HttpParams().set('v', provider.options.version)
        };
        // This call causes ERROR NetworkError in Angular SSR
        return this.http.get(url, options);
    }
}

@Injectable()
export class LocaleValidation implements L10nValidation {
    constructor(@Inject(L10N_LOCALE) private locale: L10nLocale) {}

    public parseNumber(
        value: string,
        options?: L10nNumberFormatOptions,
        language = this.locale.language
    ): number | null {
        if (value === '' || value == null) {
            return null;
        }

        let format: any = {
            minimumIntegerDigits: 1,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        };
        if (options && options.digits) {
            format = { ...format, ...parseDigits(options.digits) };
        }

        let decimalSeparator: string;
        switch (language) {
            case 'it-IT':
                decimalSeparator = ',';
                break;
            default:
                decimalSeparator = '.';
        }

        const pattern = `^-?[\\d]{${format.minimumIntegerDigits},}(\\${decimalSeparator}[\\d]{${format.minimumFractionDigits},${format.maximumFractionDigits}})?$`;
        const regex = new RegExp(pattern);
        return regex.test(value) ? parseFloat(value.replace(decimalSeparator, '.')) : null;
    }

    public parseDate(
        value: string,
        options?: L10nDateTimeFormatOptions,
        language = this.locale.language
    ): Date | null {
        return null;
    }
}

