<!-- <div class="maintenance">
    <mat-icon>construction</mat-icon>
    <p>We're conducting server maintenance at the moment.</p>
    <p class="subtitle">The service will be back up and running by 2:00am UTC.</p>
    <p class="subtitle">In case you still see this message, please clear your cache for gameleap.com and reload the page.</p>
    <p class="subtitle">Thank you for your patience!</p>
</div> -->

<div class="app-content-wrapper">
    <router-outlet id="router-outlet"></router-outlet>
</div>

<!-- <ng-container *ngIf="!cookieDisclaimerDismissed && platformBrowser">
    <ng-container *ngTemplateOutlet="cookiesBanner"></ng-container>
</ng-container> -->

<!-- <ng-container *ngIf="consentNotGranted && platformBrowser">
    <ng-container *ngTemplateOutlet="consentRegather"></ng-container>
</ng-container> -->

<!-- <ng-template #cookiesBanner>
    <core-banner
        [anchorLinkPath]="'/support/cookies'"
        [showAnchorLink]="true"
        [heading]="'app_cookies-banner_heading'"
        [description]="'app_cookies-banner_disclaimer'"
        [anchorLinkText]="'app_cookies-banner_secondary-cta'"
        [primaryButtonText]="'app_cookies-banner_main-cta'"
        (onPrimaryButtonClicked)="dismissDisclaimer()"
        (onCloseIconClicked)="dismissDisclaimer()"
    ></core-banner>
</ng-template>

<!-- <core-notifications></core-notifications> -->
<!-- <core-announcement></core-announcement> -->
<!-- <core-giveaway></core-giveaway> -->

