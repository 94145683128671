import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { Player } from '../../models/player.model';

import * as playersActions from '../actions/players.actions';

const selectId = (entity: any) => entity?._id;

export const featuredPlayersAdapter: EntityAdapter<Player> = createEntityAdapter<Player>({
    selectId
});

export interface PlayersState {
    featuredPlayers: EntityState<Player>;
}

export const playersInitialState: PlayersState = {
    featuredPlayers: featuredPlayersAdapter.getInitialState()
};

export const playersReducer = createReducer(
    playersInitialState,
    on(playersActions.loadFeaturedPlayersSuccess, (state, { payload }) => ({
        ...state,
        featuredPlayers: featuredPlayersAdapter.setAll(payload, state.featuredPlayers)
    }))
);
