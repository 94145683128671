import { ActivatedRouteSnapshot } from '@angular/router';

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { take } from 'rxjs';
import { setCurrentGame } from '../../store/actions/persist.actions';
import { selectCurrentGame } from '../../store/selectors/persist.selectors';
import { GlobalState } from '../../store/store';
import { GameSlugArticleCategoryMap, Games } from '../constants/game.enum';

@Injectable()
export class DashboardGameSetGuardV2 {
    constructor(private store: Store<GlobalState>) {}

    canActivate(route: ActivatedRouteSnapshot): boolean {
        let game = route.params.slug;
        game = Games.includes(game) ? game : null || GameSlugArticleCategoryMap[game] || null;

        if (game) {
            this.store
                .select(selectCurrentGame)
                .pipe(take(1))
                .subscribe(currentGame => {
                    if (currentGame !== game) {
                        this.store.dispatch(setCurrentGame({ payload: game }));
                    }
                });
        } else {
            this.store.dispatch(setCurrentGame({ payload: null }));
            console.warn('Could not set game, this may be a problem!');
        }
        return true;
    }
}

