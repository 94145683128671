import { createAction, props } from '@ngrx/store';
import { APIError } from '../../models/error.model';
import { Player } from '../../models/player.model';

export enum PlayersActionsTypes {
    LoadFeaturedPlayers = '[Main Home] Load Featured Players',
    LoadFeaturedPlayersSuccess = '[Content API] Load Featured Players Success',
    LoadFeaturedPlayersFailure = '[Content API] Load Featured Players Failure'
}

export const loadFeaturedPlayers = createAction(PlayersActionsTypes.LoadFeaturedPlayers);
export const loadFeaturedPlayersSuccess = createAction(
    PlayersActionsTypes.LoadFeaturedPlayersSuccess,
    props<{ payload: Player[] }>()
);
export const loadFeaturedPlayersFailure = createAction(
    PlayersActionsTypes.LoadFeaturedPlayersFailure,
    props<{ payload: APIError }>()
);
