import { InjectionToken } from '@angular/core';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { RouterStateUrl } from './custom-route-serializer';
import { appReducer, AppState } from './reducers/app.reducer';
import { cacheReducer, CacheState } from './reducers/cache.reducer';
import { cloudReducer, CloudState } from './reducers/cloud.reducer';
import { commentsReducer, CommentsState } from './reducers/comments.reducer';
import { contentReducer, ContentState } from './reducers/content.reducer';
import { coursesReducer, CoursesState } from './reducers/courses.reducer';
import { errorReducer, ErrorState } from './reducers/error.reducer';
import { errorsReducer, ErrorsState } from './reducers/errors.reducer';
import { loadingReducer, LoadingState } from './reducers/loading.reducer';
import { metaReducer, MetaState } from './reducers/meta.reducer';
import { newsReducer, NewsState } from './reducers/news.reducer';
import { notificationsReducer, NotificationsState } from './reducers/notifications.reducer';
import { paymentReducer, PaymentState } from './reducers/payment.reducer';
import { persistReducer, PersistState } from './reducers/persist.reducer';
import { playersReducer, PlayersState } from './reducers/players.reducer';
import { referralReducer, ReferralState } from './reducers/referral.reducer';
import { searchReducer, SearchState } from './reducers/search.reducer';
import { tagsReducer, TagsState } from './reducers/tags.reducer';
import { userReducer, UserState } from './reducers/user.reducer';
import { videoGuidesReducer, VideoGuidesState } from './reducers/video-guides.reducer';

export interface GlobalState {
    app: AppState;
    user: UserState;
    referral: ReferralState;
    error: ErrorState;
    payment: PaymentState;
    content: ContentState;
    persist: PersistState;
    cloud: CloudState;
    cache: CacheState;
    loading: LoadingState;
    router: RouterReducerState<RouterStateUrl>;
    videoGuides: VideoGuidesState;
    courses: CoursesState;
    tags: TagsState;
    errors: ErrorsState;
    meta: MetaState;
    search: SearchState;
    notifications: NotificationsState;
    comments: CommentsState;
    players: PlayersState;
    news: NewsState;
}

export const reducers = {
    app: appReducer,
    user: userReducer,
    referral: referralReducer,
    error: errorReducer,
    payment: paymentReducer,
    content: contentReducer,
    persist: persistReducer,
    cloud: cloudReducer,
    cache: cacheReducer,
    loading: loadingReducer,
    router: routerReducer,
    videoGuides: videoGuidesReducer,
    courses: coursesReducer,
    tags: tagsReducer,
    errors: errorsReducer,
    meta: metaReducer,
    search: searchReducer,
    notifications: notificationsReducer,
    comments: commentsReducer,
    players: playersReducer,
    news: newsReducer
};

export const reducerToken = new InjectionToken<ActionReducerMap<GlobalState>>('Reducers');

export function getReducers() {
    return reducers;
}

export function stateSetter(reducer: ActionReducer<any>): ActionReducer<any> {
    return function(state: any, action: any) {
        if (action.type === '[App] Set Root State') {
            return action.payload;
        }
        return reducer(state, action);
    };
}

export const reducerProvider = [{ provide: reducerToken, useFactory: getReducers }];

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return localStorageSync({
        keys: [
            'user',
            {
                referral: [
                    'promoCodeData',
                    'campaignData',
                    'campaignId',
                    'firstVisitTracked',
                    'previousAffiliateId'
                ]
            },
            'persist'
        ],
        rehydrate: true,
        checkStorageAvailability: true
    })(reducer);
}

export const metaReducers: MetaReducer<any, any>[] = [stateSetter];

// ssr workaround
if (typeof window !== 'undefined') {
    metaReducers.push(localStorageSyncReducer);
}

