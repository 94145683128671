<div
    class="navigation-container"
    (escapePressed)="closeNav()"
    (clickOutside)="closeNav()"
    [isOpen]="isOpen"
    [ignoredClasses]="['inner']"
    [escapeIsOpen]="isOpen"
    [ngClass]="[isLarge ? 'large' : 'small', isOpen ? 'open' : '']"
    (click)="closeNav()"
>
    <div class="inner" (click)="$event.stopPropagation()">
        <a
            class="logo-container"
            href="/"
            (click)=" closeNav()"
        >
            <picture class="large">
                <source
                    srcset="/assets/images/header/gameleap_big_light_flat_optimized.webp"
                    type="image/webp"
                />
                <source
                    srcset="/assets/images/header/gameleap_big_light_flat_optimized.png"
                    type="image/png"
                />
                <img
                    width="173px"
                    height="20px"
                    loading="lazy"
                    src="/assets/images/header/gameleap_big_light_flat_optimized.png"
                    class="logo"
                    alt="GameLeap logo"
                />
            </picture>
            <picture class="small">
                <source
                    srcset="/assets/images/header/gameleap_small_light_flat.webp"
                    type="image/webp"
                />
                <source
                    srcset="/assets/images/header/gameleap_small_light_flat.png"
                    type="image/png"
                />
                <img
                    width="30px"
                    height="20px"
                    loading="lazy"
                    src="/assets/images/header/gameleap_small_light_flat.png"
                    class="logo"
                />
            </picture>
        </a>

        <div class="routes-container signup" *ngIf="!isLoggedIn">
            <a
                class="route"
                routerLinkActive="active"
                [routerLink]="['/login']"
                (click)="closeNav()"
            >
                <mat-icon svgIcon="right-to-bracket"></mat-icon>
                <span>
                    Login
                </span>
            </a>
            <a
                class="route"
                routerLinkActive="active"
                [routerLink]="['/signup']"
                (click)="closeNav()"
            >
                <mat-icon svgIcon="user-plus"></mat-icon>
                <span>
                    Signup
                </span>
            </a>
        </div>
        <div class="routes-container">
            <a
                class="route"
                href="/"
                (click)="closeNav()"
            >
                <!-- <mat-icon svgIcon="house"></mat-icon> -->
                <span>
                    Home
                </span>
            </a>
            <a
                class="route"
                routerLinkActive="active"
                [routerLink]="currentGame ? ['/courses', currentGame] : ['/courses']"
                (click)="closeNav()"
            >
                <!-- <mat-icon svgIcon="map"></mat-icon> -->
                <span>
                    Courses
                </span>
            </a>
            <a
                class="route"
                routerLinkActive="active"
                [routerLink]="currentGame ? ['/video-guides', currentGame] : ['/video-guides']"
                (click)="closeNav()"
            >
                <!-- <mat-icon svgIcon="clapperboard-play"></mat-icon> -->
                <span>
                    Videos
                </span>
            </a>
            <a
                class="route"
                routerLinkActive="active"
                [routerLink]="['/', 'articles']"
                (click)="closeNav()"
            >
                <!-- <mat-icon svgIcon="newspaper"></mat-icon> -->
                <span>
                    Articles
                </span>
            </a>

            <a
                class="route"
                routerLinkActive="active"
                [routerLink]="['/', 'articles', 'news']"
                (click)="closeNav()"
            >
                <!-- <mat-icon svgIcon="newspaper"></mat-icon> -->
                <span>
                    News
                </span>
            </a>
            <a
                class="route"
                routerLinkActive="active"
                [routerLink]="['/', 'articles', 'guides']"
                (click)="closeNav()"
            >
                <!-- <mat-icon svgIcon="newspaper"></mat-icon> -->
                <span>
                    Guides
                </span>
            </a>
        </div>

        <app-dashboard-navigation-sale-banner
            *ngIf="saleBannerEnabled && saleBannerVisible"
        ></app-dashboard-navigation-sale-banner>
    </div>
</div>

