import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { Observable, combineLatest, fromEvent } from 'rxjs';
import { filter, map, mergeMap, skip, take, withLatestFrom } from 'rxjs/operators';

import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { MatIconRegistry } from '@angular/material/icon';
import { Store } from '@ngrx/store';
import { AppSettings } from './core/constants/AppSettings';
import { SocketRooms } from './core/constants/SocketSettings';
import { ModalTypes } from './core/constants/modal.constants';
import { AdService } from './core/services/ad.service';
import { HelpersService } from './core/services/helpers.service';
import { IconsService } from './core/services/icons.service';
import { JWTService } from './core/services/jwt-service';
import { ScriptService, Scripts } from './core/services/scripts.service';
import { SEOService } from './core/services/seo.service';
import { SocketService } from './core/services/socket.service';
import { Account } from './models/account.model';
import { AnchorLinkService } from './shared/services/anchor-link.service';
import { trackUserFirstClick } from './store/actions/app.actions';
import { confirmLogin } from './store/actions/auth.actions';
import { loadCurrencySettings, loadSubscriptions } from './store/actions/payment.actions';
import { dismissCookiesBanner } from './store/actions/persist.actions';
import {
    loadActiveAdCampaignsAdvertisements,
    loadEnabledBannerCampaigns
} from './store/actions/referral.actions';
import { selectConsentRegatherModalOpen } from './store/selectors/app.selectors';
import * as persistSelectors from './store/selectors/persist.selectors';
import { selectAccount, selectIsLoggedIn } from './store/selectors/users.selectors';
import { GlobalState } from './store/store';

const thirtySeconds = 30 * 1000;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet]
})
export class AppComponent implements OnInit {
    private currentAuthToken: string | null | undefined;

    public ModalTypes = ModalTypes;
    public cookieDisclaimerDismissed: boolean;
    public platformBrowser: boolean = true;
    public consentNotGranted: boolean;
    public announcementsDismissed: boolean;
    public primaryColor = AppSettings.PRIMARY_COLOR_HEX;
    public consentRegatherModalOpen$: Observable<boolean>;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        @Inject(PLATFORM_ID) private platformId: Object,
        private socket: SocketService,
        private router: Router,
        private route: ActivatedRoute,
        private seoService: SEOService,
        private helpersService: HelpersService,
        private jwtService: JWTService,
        private scriptService: ScriptService,
        private store: Store<GlobalState>,
        private iconRegistry: MatIconRegistry,
        private anchorLinkService: AnchorLinkService,
        private iconsService: IconsService,
        private adService: AdService
    ) {
        this.platformBrowser = isPlatformBrowser(platformId);
        iconRegistry.setDefaultFontSetClass(
            'material-symbols-outlined',
            'material-icons',
            'mat-ligature-font'
        );
        iconRegistry.registerFontClassAlias('brands', 'fa-brands fa-fw');

        this.consentRegatherModalOpen$ = store.select(selectConsentRegatherModalOpen);
    }

    public dismissDisclaimer() {
        this.store.dispatch(dismissCookiesBanner());
    }

    ngAfterViewInit() {
        this.scriptService.load(Scripts.GOOGLE);
        this.anchorLinkService.bind();
        if (this.platformBrowser) {
            // this.scriptService.load(Scripts.PLAYER);
            // this.scriptService.load(Scripts.PLAYER_MUX);
        }
    }

    ngOnInit() {
        this.store.dispatch(loadSubscriptions());
        this.store.dispatch(loadEnabledBannerCampaigns());
        this.store.dispatch(loadActiveAdCampaignsAdvertisements());
        this.adService.setupProsperPageSession();

        fromEvent(this.document, 'click')
            .pipe(take(1))
            .subscribe(event => {
                this.store.dispatch(trackUserFirstClick());
            });

        combineLatest([
            this.store.select(selectAccount),
            this.store.select(persistSelectors.selectAuthToken),
            this.store.select(selectIsLoggedIn)
        ]).subscribe(([account, authToken, isLoggedIn]) => {
            // if (!isLoggedIn && !this.socket.socket && this.platformBrowser) {
            //     return this.socket.connect();
            // }

            if (isLoggedIn && this.currentAuthToken !== authToken && this.platformBrowser) {
                this.socket.authToken = authToken; // provide the new authToken
                this.socket.connect();
                this.currentAuthToken = authToken;

                if (account) {
                    this.socket.joinRoom(SocketRooms.user + account.id);
                    this.store.dispatch(loadCurrencySettings());

                    this.consentNotGranted =
                        !account.consentGrants.policies.terms.granted ||
                        !account.consentGrants.policies.privacy.granted;

                    // 27.10.2022 -- removed this
                    if (this.consentNotGranted) {
                        // this.store.dispatch(openConsentModal());
                    }
                }
            }
        });

        this.store
            .select(selectIsLoggedIn)
            .pipe(take(1))
            .subscribe(isLoggedIn => {
                if (isLoggedIn) {
                    this.store.dispatch(confirmLogin({ payload: { reIssueToken: false } }));
                }
            });
        this.route.queryParams
            .pipe(skip(1), withLatestFrom(this.store.select(selectIsLoggedIn)), take(1))
            .subscribe(([params, isLoggedIn]) => {
                if (params.authToken && !isLoggedIn) {
                    const user: Account = this.jwtService.decodeToken(params.authToken);
                    this.store.dispatch(confirmLogin({ payload: { reIssueToken: false } }));
                }
            });
        if (this.route.snapshot.data?.seo?.title) {
            this.seoService.setTitle(
                this.route.snapshot.data?.seo?.title,
                this.route.snapshot.data?.seo?.appendSuffix
            );
        }
        this.router.events
            .pipe(
                filter(evt => evt instanceof NavigationEnd),
                map(() => this.route),
                map(route => {
                    // Always get the deepest route
                    while (route.firstChild) {
                        route = route.firstChild;
                    }

                    return route;
                }),
                filter(route => route.outlet === 'primary'),
                mergeMap(route => route.data)
            )
            .subscribe(data => {
                this.seoService.setTags({
                    url: this.helpersService.getCurrentUrl(),
                    type: 'website',
                    title: data?.seo?.title,
                    ...(data?.seo?.description && { description: data?.seo?.description })
                });
                this.seoService.setCanonicalUrl(this.helpersService.getCurrentUrl());

                if (data?.seo?.title) {
                    this.seoService.setTitle(data?.seo?.title, data?.seo?.appendSuffix);
                } else {
                    this.seoService.getTitle();
                }
            });

        this.store.select(persistSelectors.selectCookiesBannerDismissed).subscribe(dismissed => {
            this.cookieDisclaimerDismissed = dismissed;
        });
    }
}

