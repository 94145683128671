import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { DashboardEndpoints } from '../../core/constants/ApiEndpoints';
import { GameAwareHttp } from '../../core/services/game-aware-http.service';
import { MainHttp } from '../../core/services/main-http.service';
import { Tag } from '../../models/tag.model';
import * as tagsActions from '../actions/tags.actions';
import { GlobalState } from '../store';

@Injectable()
export class TagsEffects {
    constructor(
        private actions$: Actions,
        private http: MainHttp,
        private gameAwareHttp: GameAwareHttp,
        private store: Store<GlobalState>
    ) {}

    loadTags$ = createEffect(() =>
        this.actions$.pipe(
            ofType(tagsActions.loadTags),
            // concatLatestFrom(action => this.store.select(cacheSelectors.selectTagsEtag)),
            // tap(() => this.store.dispatch(cacheActions.loadTagsEtag())),
            // concatMap(([action, curEtag]) =>
            //     curEtag
            //         ? this.actions$.pipe(
            //               ofType(cacheActions.loadTagsEtagSuccess),
            //               map(cacheAction => [action, cacheAction.payload === curEtag])
            //           )
            //         : of([action, false])
            // ),
            // filter(([action, etagLatest]) => etagLatest === false),
            // tap(() => this.store.dispatch(loadingActions.featuredCoursesLoading())),
            mergeMap(({ payload }: any) => {
                return this.gameAwareHttp
                    .get(DashboardEndpoints.getTags, payload, { rawBody: true })
                    .pipe(
                        map((tags: Tag[]) => tagsActions.loadTagsSuccess({ payload: tags })),
                        catchError(error =>
                            of(
                                tagsActions.loadTagsFailure({
                                    payload: error
                                })
                            )
                        )
                        // finalize(() =>
                        //     this.store.dispatch(loadingActions.featuredCoursesLoadingFinished())
                        // )
                    );
            })
        )
    );
}

