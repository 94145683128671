export interface ModalTypes {
    deleteCommentModal: string;
    courseNavigationModal: string;
    coursesSidebarModal: string;
    promoVideoModal: string;
    consentRegatherModal: string;
    announcementModal: string;
}

// @ts-ignore
export const ModalTypes: ModalTypes = {
    deleteCommentModal: 'deleteCommentModal',
    courseNavigationModal: 'courseNavigationModal',
    coursesSidebarModal: 'coursesSidebarModal',
    promoVideoModal: 'promoVideoModal',
    consentRegatherModal: 'consentRegatherModal',
    announcementModal: 'announcementModal'
};