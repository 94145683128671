import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { UserSelectors } from '../../store/selectors/user.selectors';
import { UserRoles } from '../../models/user-role-map.model';

@Injectable()
export class CanActivatePayment  {
    private isLoggedIn: boolean;
    private userRoles: UserRoles;

    constructor(private userSelectors: UserSelectors, private router: Router) {
        this.userSelectors.isLoggedIn$.subscribe(isLoggedIn => {
            this.isLoggedIn = isLoggedIn;
        });
        this.userSelectors.userRoles$.subscribe(userRoles => {
            this.userRoles = userRoles;
        });
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.userRoles.isPremium && !this.userRoles.isTrial) {
            this.router.navigate(['/overwatch/dashboard'], {replaceUrl: true});
            return false;
        }

        if (!this.isLoggedIn) {
            this.router.navigate(['/signup'], {
                queryParams: {returnTo: state.url},
                replaceUrl: true
            });
            return false;
        }

        return true;
    }
}