import { Injectable } from '@angular/core';
import { ReplaySubject, Observable } from 'rxjs';

@Injectable()
export class InfiniteScrollService {
    private scrollDownSubject: ReplaySubject<any> = new ReplaySubject<any>();
    private scrollUpSubject: ReplaySubject<any> = new ReplaySubject<any>();

    public scrollDown$: Observable<string | null> = this.scrollDownSubject.asObservable();
    public scrollUp$: Observable<string | null> = this.scrollUpSubject.asObservable();

    constructor() {}

    public scrollDown() {
        this.scrollDownSubject.next(true);
    }

    public scrollUp() {
        this.scrollUpSubject.next(true);
    }
}
