<div class="gl-flex-wrapper">
    <div *ngIf="snowParticlesEnabled" id="particles"></div>

    <div id="container" class="gl-flex-header">
        <div id="content" (click)="click()">
            <img [src]="getThumbnailUrl(campaign?.iconPath, '150x150', 'png')" />

            <!-- <video autoplay muted loop src="/assets/images/header/broken.mp4"></video> -->
            <div class="text-content">
                <p class="main-cta">
                    <span [innerHTML]="bannerHTML" class="desktop"></span>

                    <span class="mobile" [translate]="$any({ code: promoCode })"
                        >app_header-banner-festive_cta-mobile</span
                    >
                </p>
            </div>
        </div>
        <div id="countdown">
            <p
                [translate]="
                    $any({
                        days: countdownTime.days,
                        hours: countdownTime.hours,
                        minutes: countdownTime.minutes,
                        seconds: countdownTime.seconds
                    })
                "
            >
                app_header-banner-festive_expiration
            </p>
        </div>
    </div>
</div>

