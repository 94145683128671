<div class="layout-container">
    <div class="content">
        <header-banner *ngIf="promoBannerEnabled && platformBrowser">
            <header-banner-festive></header-banner-festive>
        </header-banner>
        <div class="header-container">
            <div class="header" [ngClass]="{ promo: promoBannerEnabled, scrolled: scrolledDown }">
                <app-dashboard-header></app-dashboard-header>
            </div>
        </div>

        <div class="outlet">
            <div class="router-parent">
                <!-- <div class="breadcrumbs-container"> -->
                    <!-- <app-breadcrumb class="breadcrumbs"></app-breadcrumb> -->
                <!-- </div> -->

                <router-outlet class="router-outlet" select="[router-outlet]"> </router-outlet>
            </div>
            <app-footer></app-footer>
        </div>
    </div>
</div>
<!-- 
<ng-template #iconTemplate>
    <mat-icon fontIcon="chevron_right"></mat-icon>
</ng-template> -->

