import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, filter, finalize, map, mergeMap, take, tap } from 'rxjs/operators';
import { DashboardEndpoints } from '../../core/constants/ApiEndpoints';
import { concatLatestFrom } from '../../core/operators/concat-latest-from';
import { GameAwareHttp } from '../../core/services/game-aware-http.service';
import { MainHttp } from '../../core/services/main-http.service';
import { Course } from '../../models/course.model';
import { VideoGuide } from '../../models/video-guide.model';
import * as cacheActions from '../actions/cache.actions';
import * as coursesActions from '../actions/courses.actions';
import * as loadingActions from '../actions/loading.actions';
import * as metaActions from '../actions/meta.actions';
import { PayloadAction } from '../interfaces/payload-action.interface';
import * as cacheSelectors from '../selectors/cache.selectors';
import * as routeSelectors from '../selectors/route.selectors';
import { UserSelectors } from '../selectors/user.selectors';
import { GlobalState } from '../store';

@Injectable()
export class CoursesEffects {
    constructor(
        private actions$: Actions,
        private http: MainHttp,
        private gameAwareHttp: GameAwareHttp,
        private store: Store<GlobalState>,
        private userSelectors: UserSelectors
    ) {}

    loadFeaturedCourses$ = createEffect(() =>
        this.actions$.pipe(
            ofType(coursesActions.loadFeaturedCourses),
            concatLatestFrom(action => this.store.select(cacheSelectors.selectFeaturedCoursesEtag)),
            tap(() => this.store.dispatch(cacheActions.loadFeaturedCoursesEtag())),
            concatMap(([action, curEtag]) =>
                curEtag
                    ? this.actions$.pipe(
                          ofType(cacheActions.loadFeaturedCoursesEtagSuccess),
                          map(cacheAction => [action, cacheAction.payload === curEtag])
                      )
                    : of([action, false])
            ),
            filter(([action, etagLatest]) => etagLatest === false),
            tap(() => this.store.dispatch(loadingActions.featuredCoursesLoading())),
            mergeMap(() =>
                this.http.get(DashboardEndpoints.getFeaturedCourses).pipe(
                    map((featuredCourses: Course[]) =>
                        coursesActions.loadFeaturedCoursesSuccess({ payload: featuredCourses })
                    ),
                    catchError(error =>
                        of(
                            coursesActions.loadFeaturedCoursesFailure({
                                payload: error
                            })
                        )
                    ),
                    finalize(() =>
                        this.store.dispatch(loadingActions.featuredCoursesLoadingFinished())
                    )
                )
            )
        )
    );

    loadCourses$ = createEffect(() =>
        this.actions$.pipe(
            ofType(coursesActions.loadCourses),
            // concatLatestFrom(action => this.store.select(cacheSelectors.selectCoursesEtag)),
            // tap(() => this.store.dispatch(cacheActions.loadCoursesEtag())),
            // concatMap(([action, curEtag]) =>
            //     curEtag
            //         ? this.actions$.pipe(
            //               ofType(cacheActions.loadCoursesEtagSuccess),
            //               take(1),
            //               map(cacheAction => [action, cacheAction.payload === curEtag])
            //           )
            //         : of([action, false])
            // ),
            // filter(([action, etagLatest]) => etagLatest === false),
            tap(() => this.store.dispatch(loadingActions.coursesLoading())),
            mergeMap(({ payload }: any) =>
                this.gameAwareHttp.get(DashboardEndpoints.getCourses, payload).pipe(
                    map(
                        (payload: {
                            courses: Course[];
                            counters: any;
                            coursesBottomReached: boolean;
                        }) => coursesActions.loadCoursesSuccess({ payload })
                    ),
                    catchError(error =>
                        of(
                            coursesActions.loadCoursesFailure({
                                payload: error
                            })
                        )
                    ),
                    finalize(() => this.store.dispatch(loadingActions.coursesLoadingFinished()))
                )
            )
        )
    );

    loadEnrolledCourses$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType(coursesActions.loadEnrolledCourses),
                concatLatestFrom(action => this.userSelectors.account$),
                filter(([action, account]) => account != null),
                concatLatestFrom(action =>
                    this.store.select(cacheSelectors.selectEnrolledCoursesEtag)
                ),
                tap(() => this.store.dispatch(cacheActions.loadEnrolledCoursesEtag())),
                concatMap(([action, curEtag]) =>
                    curEtag
                        ? this.actions$.pipe(
                              ofType(cacheActions.loadEnrolledCoursesEtagSuccess),
                              take(1),
                              map(cacheAction => [action, cacheAction.payload === curEtag])
                          )
                        : of([action, false])
                ),
                filter(([action, etagLatest]) => etagLatest === false),
                map(([action]) => action),
                concatLatestFrom(action => this.userSelectors.account$)
            )
            // pipe again here because pipe can accept up to 9 operators
            .pipe(
                tap(() => this.store.dispatch(loadingActions.enrolledCoursesLoading())),
                mergeMap(([action, account]) =>
                    this.http
                        .get(DashboardEndpoints.getEnrolledCourses(account.id), { limit: 999 })
                        .pipe(
                            map(
                                (response: {
                                    courses: Course[];
                                    currentCourse: Course;
                                    nextVideoGuide: VideoGuide;
                                }) =>
                                    coursesActions.loadEnrolledCoursesSuccess({
                                        payload: response
                                    })
                            ),
                            catchError(error =>
                                of(
                                    coursesActions.loadEnrolledCoursesFailure({
                                        payload: error
                                    })
                                )
                            ),
                            finalize(() =>
                                this.store.dispatch(loadingActions.enrolledCoursesLoadingFinished())
                            )
                        )
                )
            )
    );

    loadCourse$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(coursesActions.loadCourse, coursesActions.loadCourseById),
                concatLatestFrom((action: any) =>
                    action?.payload == null
                        ? this.store.select(routeSelectors.selectCustomRouteParamFragment, {
                              param: 'courseId'
                          })
                        : of(action.payload)
                ),
                concatLatestFrom(([action, courseId]) =>
                    this.store.select(cacheSelectors.selectCourseEtag, {
                        courseId
                    })
                ),
                map(([[action, courseId], curEtag]) => [action, courseId, curEtag]),
                tap(([action, courseId, curEtag]) =>
                    this.store.dispatch(cacheActions.loadCourseEtag({ payload: { courseId } }))
                ),
                tap(([action, courseId, curEtag]: [PayloadAction, string, string]) =>
                    this.store.dispatch(
                        metaActions.updateCourseEnrollment({
                            payload: {
                                courseId,
                                isCurrent: true,
                                isDeleted: false
                            }
                        })
                    )
                ),
                concatMap(([action, courseId, curEtag]) =>
                    curEtag
                        ? this.actions$.pipe(
                              ofType(cacheActions.loadCourseEtagSuccess),
                              take(1),
                              map(cacheAction => [
                                  action,
                                  courseId,
                                  cacheAction.payload.etag === curEtag
                              ])
                          )
                        : of([action, courseId, false])
                ),
                filter(
                    ([action, courseId, etagLatest]) => etagLatest === false && courseId != null
                ),
                tap(() => this.store.dispatch(loadingActions.courseLoading())),
                mergeMap(([action, courseId]) =>
                    this.http.get(DashboardEndpoints.getCourse(courseId)).pipe(
                        map((course: Course) =>
                            coursesActions.loadCourseSuccess({ payload: course })
                        ),
                        catchError(error =>
                            of(
                                coursesActions.loadCourseFailure({
                                    payload: error
                                })
                            )
                        ),
                        finalize(() => this.store.dispatch(loadingActions.courseLoadingFinished()))
                    )
                )
            ) as any
    );

    public loadMasterCourse$ = createEffect(() =>
        this.actions$.pipe(
            ofType(coursesActions.loadMasterCourse),
            mergeMap(() =>
                this.http.get(DashboardEndpoints.getMasterCourse).pipe(
                    map((data: [{ masterCourse: Course }]) =>
                        coursesActions.loadMasterCourseSuccess({ payload: data[0] })
                    ),
                    catchError(error =>
                        of(
                            coursesActions.loadMasterCourseFailure({
                                payload: error
                            })
                        )
                    ),
                    finalize(() => this.store.dispatch(loadingActions.courseLoadingFinished()))
                )
            )
        )
    );
}

