<div class="promo-code-container" [routerLink]="['/signup']" (click)="click()">
    <div class="upper">
        <p>50% OFF</p>
        <p>ANNUAL PLAN</p>
    </div>

    <div class="lower">
        <p>Promo Code:</p>
        <p>{{ promoCode }}</p>
    </div>

    <div id="countdown" [ngClass]="{ visible: countdownTime.seconds > 0 }">
        <p
            [translate]="$any({
                days: countdownTime.days,
                hours: countdownTime.hours,
                minutes: countdownTime.minutes,
                seconds: countdownTime.seconds
            })"
        >
            app_header-banner-festive_expiration
        </p>
    </div>
    <gl-button size="small">UPGRADE</gl-button>
</div>
