import { createSelector } from '@ngrx/store';
import { ContentActions } from '../actions/content-actions';
import { GlobalState } from '../store';
import { PayloadAction } from '../interfaces/payload-action.interface';

export interface ContentState {
    uploadData: any;
}

export const contentInitialState: ContentState = {
    uploadData: {
        signedUrls: {},
        thumbnailSignedUrl: '',
        itemId: '',
        thumbnailResized: false
    }
};

export function contentReducer(state: ContentState = contentInitialState, action: PayloadAction): ContentState {
    switch (action.type) {
        case ContentActions.MAIN_SIGNED_S3_URL_GET_SUCCESS:
            return {
                ...state,
                uploadData: {
                    ...state.uploadData,
                    signedUrls: {
                        ...state.uploadData.signedUrls,
                        [action.payload.type]: action.payload.signedUrl
                    },
                    itemId: action.payload.key,
                    thumbnailResized: false
                }
            };
        case ContentActions.MAIN_THUMBNAIL_RESIZE_SUCCESS:
            return {
                ...state,
                uploadData: {
                    ...state.uploadData,
                    thumbnailResized: true
                }
            };

        default:
            return state;
    }
}

const getUploadData = (state: GlobalState) => state.content.uploadData;

export const uploadDataSelector = createSelector(getUploadData, uploadData => uploadData);
