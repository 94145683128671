import { createReducer, on } from '@ngrx/store';
import {
    closeAnnouncementModal,
    closeConsentModal,
    closeDeleteCommentModal,
    closeGiveawayModal,
    closeNotificationPreferencesModal,
    closePromoVideoModal,
    openAnnouncementModal,
    openConsentModal,
    openDeleteCommentModal,
    openGiveawayModal,
    openNotificationPreferencesModal,
    openPromoVideoModal,
    trackUserFirstClick
} from '../actions/app.actions';

export interface AppState {
    modalsVisibility: {
        deleteCommentModal: boolean;
        promoVideoModal: boolean;
        consentRegatherModal: boolean;
        announcementModal: boolean;
        giveawayModal: boolean;
        notificationPreferencesModal: boolean;
    };
    firstUserClickTracked: boolean;
}

export const appInitialState: AppState = {
    modalsVisibility: {
        deleteCommentModal: false,
        promoVideoModal: false,
        consentRegatherModal: false,
        announcementModal: false,
        giveawayModal: false,
        notificationPreferencesModal: false
    },
    firstUserClickTracked: false
};

export const appReducer = createReducer(
    appInitialState,
    on(openDeleteCommentModal, state => ({
        ...state,
        modalsVisibility: {
            ...state.modalsVisibility,
            deleteCommentModal: true
        }
    })),
    on(closeDeleteCommentModal, state => ({
        ...state,
        modalsVisibility: {
            ...state.modalsVisibility,
            deleteCommentModal: false
        }
    })),
    on(openPromoVideoModal, state => ({
        ...state,
        modalsVisibility: {
            ...state.modalsVisibility,
            promoVideoModal: true
        }
    })),
    on(closePromoVideoModal, state => ({
        ...state,
        modalsVisibility: {
            ...state.modalsVisibility,
            promoVideoModal: false
        }
    })),
    on(openConsentModal, state => ({
        ...state,
        modalsVisibility: {
            ...state.modalsVisibility,
            consentRegatherModal: true
        }
    })),
    on(closeConsentModal, state => ({
        ...state,
        modalsVisibility: {
            ...state.modalsVisibility,
            consentRegatherModal: false
        }
    })),
    on(openAnnouncementModal, state => ({
        ...state,
        modalsVisibility: {
            ...state.modalsVisibility,
            announcementModal: true
        }
    })),
    on(closeAnnouncementModal, state => ({
        ...state,
        modalsVisibility: {
            ...state.modalsVisibility,
            announcementModal: false
        }
    })),
    on(openGiveawayModal, state => ({
        ...state,
        modalsVisibility: {
            ...state.modalsVisibility,
            giveawayModal: true
        }
    })),
    on(closeGiveawayModal, state => ({
        ...state,
        modalsVisibility: {
            ...state.modalsVisibility,
            giveawayModal: false
        }
    })),
    on(openNotificationPreferencesModal, state => ({
        ...state,
        modalsVisibility: {
            ...state.modalsVisibility,
            notificationPreferencesModal: true
        }
    })),
    on(closeNotificationPreferencesModal, state => ({
        ...state,
        modalsVisibility: {
            ...state.modalsVisibility,
            notificationPreferencesModal: false
        }
    })),
    on(trackUserFirstClick, state => ({
        ...state,
        firstUserClickTracked: true
    }))
);

