import { createReducer, on } from '@ngrx/store';
import { Advertisement } from '../../models/advertisement.model';
import { EventKind } from '../../models/affiliate-event.model';
import { Campaign } from '../../models/campaign.model';
import { DiscountDuration } from '../../models/discount.model';
import { Giveaway } from '../../models/giveaway.model';
import { PaymentType } from '../../models/payment.model';
import { PromoCode } from '../../models/promo-code.model';
import {
    loadActiveAdCampaignsAdvertisementsSuccess,
    loadAffiliateCampaignsSuccess,
    loadAffiliateEarningsDataSuccess,
    loadAffiliateEventsSuccess,
    loadAffiliatePaymentsSuccess,
    loadAffiliatePromoCodesSuccess,
    loadEnabledBannerCampaignsSuccess,
    loadEnabledGiveawaysSuccess,
    loadEnabledModalCampaignsSuccess,
    resetPromoCodeData,
    setCampaignId,
    setFirstVisit,
    setLoadActiveAdCampaignsAdvertisementsComplete,
    setPreviousAffiliateId,
    validateCampaignSuccess,
    validatePromoCodeSuccess
} from '../actions/referral.actions';

export interface ReferralState {
    promoCodeData: Partial<PromoCode>;
    campaignData: Campaign | null;
    campaignId: string | null;
    affiliateEvents: { [TKey in EventKind]: any };
    affiliatePayments: { [TKey in PaymentType]: any };
    firstVisitTracked: boolean;
    /**
     * We keep the affiliateId of the last promoCode's affiliateId, so if the user ends up
     * coming from the same URL twice, we won't track this as a new visit.
     * If they come from a different referral, we will track it as a separate visit.
     */
    previousAffiliateId: string | null;
    earningsData: { [currency: string]: any[] };
    affiliatePromoCodes: PromoCode[];
    promoCodes: PromoCode[];
    campaigns: Campaign[];
    enabledBannerCampaigns: Campaign[];
    enabledModalCampaigns: Campaign[];
    enabledGiveaways: Giveaway[];
    activeAdCampaignsAdvertisements: Advertisement[];
    activeAdCampaignsAdvertisementsLoadingComplete: boolean;
}

export const referralInitialState: ReferralState = {
    promoCodeData: {
        code: '',
        isValid: undefined,
        affiliate: null,
        videoId: null,
        callout: null,
        game: null,
        discount: {
            amount: 0,
            percentage: 0,
            duration: DiscountDuration.ONCE,
            durationMonths: 0
        }
    },
    campaignData: null,
    campaignId: null,
    affiliateEvents: {
        [EventKind.VISIT]: {},
        [EventKind.SIGNUP]: {},
        [EventKind.UPGRADE]: {},
        [EventKind.PRODUCT_SUBSCRIBE]: {}
    },
    affiliatePayments: {
        [PaymentType.RECURRING]: {},
        [PaymentType.NON_RECURRING]: {}
    },
    previousAffiliateId: null,
    firstVisitTracked: false,
    earningsData: {},
    promoCodes: [],
    campaigns: [],
    affiliatePromoCodes: [],
    enabledBannerCampaigns: [],
    enabledModalCampaigns: [],
    enabledGiveaways: [],
    activeAdCampaignsAdvertisements: [],
    activeAdCampaignsAdvertisementsLoadingComplete: false
};

export const referralReducer = createReducer(
    referralInitialState,
    on(validatePromoCodeSuccess, (state, { payload }) => ({
        ...state,
        promoCodeData: payload
    })),
    on(validateCampaignSuccess, (state, { payload }) => ({
        ...state,
        campaignData: payload
    })),
    on(resetPromoCodeData, state => ({
        ...state,
        promoCodeData: referralInitialState.promoCodeData
    })),
    on(loadAffiliateEarningsDataSuccess, (state, { payload }) => ({
        ...state,
        earningsData: {
            ...state.earningsData,
            ...payload
        }
    })),
    on(loadAffiliateEventsSuccess, (state, { payload }) => ({
        ...state,
        affiliateEvents: {
            ...state.affiliateEvents,
            [payload.eventKind]: { ...payload }
        }
    })),
    on(loadAffiliatePaymentsSuccess, (state, { payload }) => ({
        ...state,
        affiliatePayments: {
            ...state.affiliatePayments,
            [payload.paymentType]: { ...payload }
        }
    })),
    on(loadAffiliatePromoCodesSuccess, (state, { payload }) => ({
        ...state,
        promoCodes: [...payload]
    })),
    on(loadAffiliateCampaignsSuccess, (state, { payload }) => ({
        ...state,
        campaigns: [...payload]
    })),
    on(loadEnabledBannerCampaignsSuccess, (state, { payload }) => ({
        ...state,
        enabledBannerCampaigns: [...payload]
    })),
    on(loadEnabledModalCampaignsSuccess, (state, { payload }) => ({
        ...state,
        enabledModalCampaigns: [...payload]
    })),
    on(setCampaignId, (state, { payload }) => ({
        ...state,
        campaignId: payload.campaignId
    })),
    on(setFirstVisit, (state, { payload }) => ({
        ...state,
        firstVisitTracked: payload.firstVisitTracked
    })),
    on(setPreviousAffiliateId, (state, { payload }) => ({
        ...state,
        previousAffiliateId: payload.previousAffiliateId
    })),
    on(loadEnabledGiveawaysSuccess, (state, { payload }) => ({
        ...state,
        enabledGiveaways: [...payload]
    })),
    on(loadActiveAdCampaignsAdvertisementsSuccess, (state, { payload }) => ({
        ...state,
        activeAdCampaignsAdvertisements: [...payload]
    })),
    on(setLoadActiveAdCampaignsAdvertisementsComplete, (state, { payload }) => ({
        ...state,
        activeAdCampaignsAdvertisementsLoadingComplete: payload
    }))
);

