<div
    class="dropdown-container"
    (escapePressed)="toggleDropdownOpen(false)"
    (clickOutside)="toggleDropdownOpen(false)"
    [isOpen]="dropdownOpen"
    [escapeIsOpen]="dropdownOpen"
    [ignoredClasses]="['notifications-list-container']"
>
    <gl-button size="small" type="transparent" [routerLink]="['/login']" *ngIf="!isLoggedIn"
        >LOGIN</gl-button
    >
    <gl-button size="small" *ngIf="!isLoggedIn" [routerLink]="['/signup']">SIGN UP</gl-button>
    <div class="dropdown-button" (click)="toggleDropdownOpen(!dropdownOpen)" *ngIf="isLoggedIn">
        <div class="thumbnail">
            <img
                *ngIf="account?.profileImagePath !== null"
                [src]="getThumbnailUrl(account?.profileImagePath, '50x50')"
            />
            <div
                *ngIf="account && !account?.profileImagePath"
                [style.background]="account?.profileColor"
                class="user-thumbnail-default"
            >
                {{ account.username[0] }}
            </div>
        </div>
    </div>

    <div class="dropdown" *ngIf="dropdownOpen">
        <div class="header-container">
            <div class="user-info">
                <div class="thumbnail">
                    <img
                        *ngIf="account?.profileImagePath !== null"
                        [src]="getThumbnailUrl(account?.profileImagePath, '50x50')"
                    />

                    <div
                        *ngIf="account && !account?.profileImagePath"
                        [style.background]="account?.profileColor"
                        class="user-thumbnail-default small"
                    >
                        {{ account.username[0] }}
                    </div>
                </div>

                <h4 class="dashboard-heading">
                    {{ account?.username }}
                </h4>

                <div class="role-info-container">
                    <div
                        class="role-info valign-center"
                        *roles="[Role.PREMIUM]; selectedOnly: true"
                    >
                        <span class="premium text" translate>global_pro-user</span>
                    </div>
                    <div class="role-info valign-center" *roles="[Role.ADMIN]; selectedOnly: true">
                        <span class="premium text" translate>global_admin-user</span>
                    </div>
                    <div
                        class="role-info valign-center"
                        *roles="[Role.MANAGER]; selectedOnly: true"
                    >
                        <span class="premium text" translate>global_manager-user</span>
                    </div>
                    <div class="role-info valign-center" *roles="[Role.PLAYER]; selectedOnly: true">
                        <span class="premium text" translate>global_pro-player-user</span>
                    </div>
                </div>
                <div class="actions-container">
                    <mat-icon
                        svgIcon="circle-xmark"
                        title="Close"
                        (click)="toggleDropdownOpen(false)"
                    ></mat-icon>
                </div>
            </div>
        </div>
        <div>
            <ul class="menu-list">
                <li class="dashboard-list-item">
                    <a aria-label="Account" class="content" [routerLink]="['/account']">
                        <mat-icon svgIcon="user"></mat-icon>
                        <p>Account</p>
                    </a>
                </li>
                <li
                    class="dashboard-list-item"
                    *roles="[Role.FREE, Role.TRIAL]; selectedOnly: true"
                >
                    <a
                        aria-label="Upgrade to PRO"
                        class="content"
                        [routerLink]="['/signup']"
                        translate
                    >
                        <mat-icon svgIcon="unlock"></mat-icon>
                        <p>Upgrade to PRO</p>
                    </a>
                </li>

                <li
                    class="dashboard-list-item"
                    *roles="
                        [Role.INFLUENCER, Role.ADMIN, Role.PLAYER, Role.MANAGER];
                        selectedOnly: true
                    "
                >
                    <a
                        class="content"
                        [routerLink]="['/partners', account?.affiliateId, 'earnings']"
                        translate
                    >
                        <mat-icon svgIcon="chart-tree-map"></mat-icon>
                        <p>Partner Dashboard</p>
                    </a>
                </li>
                <li
                    class="dashboard-list-item"
                    *roles="
                        [Role.INFLUENCER, Role.ADMIN, Role.PLAYER, Role.MANAGER];
                        selectedOnly: true
                    "
                >
                    <a
                        class="content"
                        [routerLink]="['/partners', account?.affiliateId, 'files']"
                        translate
                    >
                        <mat-icon svgIcon="cloud"></mat-icon>
                        <p>GameLeap Drive</p>
                    </a>
                </li>
                <li
                    class="dashboard-list-item"
                    *roles="
                        [
                            Role.MANAGER,
                            Role.ADMIN,
                            Role.WRITER,
                            Role.WRITER_INTERN,
                            Role.WRITER_EDITOR
                        ];
                        selectedOnly: true
                    "
                >
                    <a aria-label="Management" class="content" href="/cms" translate>
                        <mat-icon svgIcon="clipboard"></mat-icon>
                        <p>Management</p>
                    </a>
                </li>
                <li class="dashboard-list-item" (click)="logout()">
                    <div class="content">
                        <mat-icon svgIcon="right-from-bracket"></mat-icon>
                        <p>Logout</p>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>

