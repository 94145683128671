<div
    class="autocomplete-container"
    (escapePressed)="toggleContextMenu(false)"
    (clickOutside)="toggleContextMenu(false)"
    [isOpen]="contextMenuOpen"
    [skipFirstClick]="false"
    [escapeIsOpen]="contextMenuOpen"
    [ignoredClasses]="['suggestions-list', 'suggestion-action']"
>
    <mat-icon
        svgIcon="arrow-left"
        class="close-small"
        (click)="toggleContextMenu(false); close.emit()"
    ></mat-icon>

    <app-dashboard-input-field
        #form
        placeholder="Search"
        icon="magnifying-glass"
        [closeIcon]="true"
        [value]="searchValue"
        (onSubmit)="submitForm({ text: $event })"
        (onKeyup)="suggestContent($event)"
        (onFocus)="toggleContextMenu(true)"
        (onReset)="resetForm()"
        (click)="toggleContextMenu(true)"
    >
    </app-dashboard-input-field>

    <ng-container *ngIf="contextMenuOpen">
        <div
            class="suggestions-list"
            [ngClass]="{
                visible: autocompleteSearchResults && autocompleteSearchResults.length > 0
            }"
        >
            <div *ngFor="let group of autocompleteGroups">
                <div class="group-name" *ngIf="group.id">
                    @if(group?.iconImage) {
                    <picture>
                        <source [srcset]="group?.iconImage" type="image/webp" />
                        <img [src]="group?.iconImage" [alt]="group.iconImage + ' logo'" />
                    </picture>
                    }

                    <span>
                        {{ group.name }}
                    </span>
                </div>
                <li
                    class="dashboard-list-item"
                    *ngFor="let suggestion of group.items; trackBy: trackBy"
                    (click)="submitForm(suggestion); close.emit(); $event.stopPropagation()"
                >
                    <div class="content">
                        @if(suggestion?.type === 'articles') {
                        <mat-icon svgIcon="newspaper"></mat-icon>
                        } @else if (suggestion?.type ==='videoguides' ){
                        <mat-icon svgIcon="circle-play"></mat-icon>
                        } @else if (suggestion?.type === 'courses') {
                        <mat-icon svgIcon="album-collection"></mat-icon>
                        } @else {
                        <mat-icon svgIcon="magnifying-glass"></mat-icon>
                        }
                        <p>{{ suggestion.text }}</p>
                    </div>
                </li>
            </div>
        </div>

        <div
            class="suggestions-list history"
            [ngClass]="{
                visible: !autocompleteSearchResults.length && autocompleteTerms.length > 0,
                'autocomplete-visible':
                    autocompleteSearchResults && autocompleteSearchResults.length > 0
            }"
        >
            <li
                class="dashboard-list-item"
                *ngFor="let term of autocompleteTerms"
                (click)="submitForm({ text: term }); close.emit(); $event.stopPropagation()"
            >
                <div class="content">
                    <mat-icon svgIcon="clock-rotate-left"></mat-icon>
                    <p>{{ term }}</p>
                </div>
                <mat-icon
                    svgIcon="circle-xmark"
                    class="action"
                    (click)="historyTermRemove(term); $event.stopPropagation()"
                ></mat-icon>
            </li>
        </div>
    </ng-container>
</div>

