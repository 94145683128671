/**
 * Created by Bo on 16-Jul-17.
 */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { contentErrorSelector } from '../reducers/error.reducer';
import { uploadDataSelector } from '../reducers/content.reducer';
import { select, Store } from '@ngrx/store';
import { GlobalState } from '../store';

@Injectable()
export class ContentSelectors {
    public error$: Observable<any>;
    public uploadData$: Observable<any>;

    constructor(private store: Store<GlobalState>) {
        this.error$ = store.pipe(select(contentErrorSelector));
        this.uploadData$ = store.pipe(select(uploadDataSelector));
    }

}
