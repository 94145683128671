<header [ngClass]="{ 'search-visible': searchVisible, 'search-page': searchPage }">
    <app-dashboard-navigation></app-dashboard-navigation>

    <div class="content-container">
        <button
            aria-label="Back"
            class="toggle-search"
            mat-icon-button
            (click)="toggleSearchVisible()"
        >
            <mat-icon svgIcon="circle-xmark"></mat-icon>
        </button>
        <button
            aria-label="Toggle sidenav"
            class="toggle-sidenav"
            mat-icon-button
            (click)="openSidenavMenu(); $event.stopPropagation()"
        >
            <mat-icon svgIcon="bars"></mat-icon>
        </button>
        <picture class="logo" class="large">
            <source
                srcset="/assets/images/header/gameleap_big_light_flat_optimized.webp"
                type="image/webp"
            />
            <source
                srcset="/assets/images/header/gameleap_big_light_flat_optimized.png"
                type="image/png"
            />
            <img
                src="/assets/images/header/gameleap_big_light_flat_optimized.png"
                class="logo"
                alt="GameLeap logo"
                loading="lazy"
                decoding="async"
            />
        </picture>
    </div>

    <div class="right-side-container">
        <app-dashboard-search-autocomplete
            #search
            [ngClass]="{ visible: searchVisible }"
            (close)="toggleSearchVisible(false)"
        ></app-dashboard-search-autocomplete>
        <div class="actions-section">
            <mat-icon
                svgIcon="magnifying-glass"
                class="search-icon"
                [ngClass]="{ 'no-margin': !isLoggedIn }"
                (click)="toggleSearchVisible()"
                *ngIf="!searchVisible"
            ></mat-icon>
            <!-- <app-dashboard-notifications *ngIf="isLoggedIn"></app-dashboard-notifications> -->
            <app-dashboard-header-dropdown ngSkipHydration></app-dashboard-header-dropdown>
        </div>
    </div>
</header>

