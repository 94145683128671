import { NgClass, NgIf, isPlatformBrowser } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { InfiniteScrollService } from '../core/services/infinite-scroll.service';
import { ROUTER_SCROLL_SERVICE } from '../core/services/router-scroll.service';
import { DashboardHeaderComponent } from '../shared/components/dashboard/dashboard-header/dashboard-header.component';
import { HeaderBannerFestiveComponent } from '../shared/components/header-banner-festive/header-banner-festive.component';
import { HeaderBannerComponent } from '../shared/components/header-banner/header-banner.component';
import { FooterComponent } from '../shared/components/navigation/footer/footer.component';
import { BreadcrumbComponent } from '../shared/modules/breadcrumbs/breadcrumb.component';
import { ScrollService } from '../shared/services/scroll.service';
import { selectPromoBannerState } from '../store/selectors/persist.selectors';
import { selectEnabledBannerCampaign } from '../store/selectors/referral.selectors';
import { selectIsLoggedIn, selectUserRoles } from '../store/selectors/users.selectors';
import { GlobalState } from '../store/store';

@Component({
    selector: 'dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        HeaderBannerComponent,
        HeaderBannerFestiveComponent,
        NgClass,
        DashboardHeaderComponent,
        RouterOutlet,
        FooterComponent,
        BreadcrumbComponent,
        MatIconModule
    ]
})
export class DashboardComponent implements OnInit {
    private componentAlive: boolean = true;

    public scrolledDown: boolean = false;
    public promoBannerEnabled: boolean = false;
    public platformBrowser: boolean = true;
    public bannerExpanded: boolean = false;

    constructor(
        private infiniteScrollService: InfiniteScrollService,
        @Inject(ROUTER_SCROLL_SERVICE)
        @Inject(PLATFORM_ID)
        platformId: Object,
        private store: Store<GlobalState>,
        private scrollService2: ScrollService,
        private cdr: ChangeDetectorRef
    ) {
        this.platformBrowser = isPlatformBrowser(platformId);
    }

    public scrollDown() {
        this.infiniteScrollService.scrollDown();
    }

    public scrollUp() {
        this.infiniteScrollService.scrollUp();
    }

    ngOnInit() {
        combineLatest([
            this.store.select(selectEnabledBannerCampaign),
            this.store.select(selectIsLoggedIn),
            this.store.select(selectUserRoles)
        ])
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(([campaign, isLoggedIn, roles]) => {
                this.promoBannerEnabled =
                    campaign != null &&
                    campaign.bannerSettings?.content != null &&
                    (!isLoggedIn || roles.isFree);
            });
        this.store
            .select(selectPromoBannerState)
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(expanded => {
                this.bannerExpanded = expanded;
            });
        combineLatest([
            this.store.select(selectEnabledBannerCampaign),
            this.store.select(selectIsLoggedIn),
            this.store.select(selectUserRoles)
        ])
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(([campaign, isLoggedIn, roles]) => {
                this.promoBannerEnabled =
                    campaign != null &&
                    campaign.bannerSettings?.content != null &&
                    (!isLoggedIn || roles.isFree);
            });
        this.store
            .select(selectPromoBannerState)
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(expanded => {
                this.bannerExpanded = expanded;
            });
    }

    ngAfterViewInit() {
        this.scrollService2.onScroll.pipe(takeWhile(() => this.componentAlive)).subscribe(y => {
            const newScrolledDown = y > 0;

            if (this.scrolledDown !== newScrolledDown) {
                this.scrolledDown = newScrolledDown;
                this.cdr.detectChanges();
            }
        });
    }

    ngOnDestroy() {
        this.componentAlive = false;
    }
}

