<div class="not-found-page">
    <div class="gl-flex-wrapper not-found-page-wrapper">
        <div class="gl-flex not-found-page-content">
            <h3 translate>
                Oops! It appears that the page you are looking for does not exist.
            </h3>
            <p translate>
                You may be looking for a page that's been moved or deleted. If you think this is a
                mistake, please contact us.
            </p>
            <div id="button-container">
                <button aria-label="Back" class="btn-primary" (click)="goHome()" translate>
                    Go Home
                </button>
            </div>
        </div>
    </div>
</div>

