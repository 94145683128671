import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { map, Observable, take } from 'rxjs';
import { selectIsLoggedIn } from '../../store/selectors/users.selectors';
import { GlobalState } from '../../store/store';

@Injectable()
export class CanActivateAccount  {
    constructor(private store: Store<GlobalState>, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.store.select(selectIsLoggedIn).pipe(
            take(1),
            map(isLoggedIn => {
                if (!isLoggedIn) {
                    this.router.navigate(['/login'], {
                        queryParams: { returnTo: state.url },
                        replaceUrl: true
                    });

                    return false;
                }

                return true;
            })
        );
    }
}

