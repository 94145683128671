import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, finalize, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { ApiEndpoints } from '../../core/constants/ApiEndpoints';
import { HelpersService } from '../../core/services/helpers.service';
import { LocalStorageService } from '../../core/services/local-storage.service';
import { MainHttp } from '../../core/services/main-http.service';
import * as authActions from '../actions/auth.actions';
import {
    loginErrorReset,
    passwordResetErrorReset,
    signupErrorReset
} from '../actions/errors.actions';
import * as loadingActions from '../actions/loading.actions';
import { setRefreshToken } from '../actions/persist.actions';
import { PayloadAction } from '../interfaces/payload-action.interface';
import { UserSelectors } from '../selectors/user.selectors';
import { GlobalState } from '../store';

@Injectable()
export class AuthEffects {
    constructor(
        private actions$: Actions,
        private http: MainHttp,
        private helpersService: HelpersService,
        private store: Store<GlobalState>,
        private userSelectors: UserSelectors,
        private router: Router,
        private localStorage: LocalStorageService
    ) {}

    /**
     * Executes user signup.
     * @param action$
     */
    userSignup$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(authActions.signupUser),
            tap(() => this.store.dispatch(signupErrorReset())),
            tap(() => this.store.dispatch(loadingActions.signupUserLoading())),

            mergeMap(({ payload }) =>
                this.http.post(ApiEndpoints.signupUser, payload).pipe(
                    map((data: any) => {
                        this.store.dispatch(setRefreshToken({ payload: data.refreshToken }));

                        return authActions.signupUserSuccess({
                            payload: this.helpersService.formatUserData(data.user)
                        });
                    }),
                    catchError(error => of(authActions.signupUserFailure({ payload: error }))),
                    finalize(() => this.store.dispatch(loadingActions.signupUserLoadingFinished()))
                )
            )
        )
    );

    /**
     * Executes user login.
     * @param action$
     */
    userLogin$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(authActions.loginUser),
            tap(() => this.store.dispatch(loginErrorReset())),
            tap(() => this.store.dispatch(loadingActions.loginUserLoading())),
            mergeMap(({ payload }) =>
                this.http.post(ApiEndpoints.loginUser, payload).pipe(
                    map(data => {
                        this.store.dispatch(setRefreshToken({ payload: data.refreshToken }));

                        return authActions.loginUserSuccess({
                            payload: this.helpersService.formatUserData(data.user)
                        });
                    }),
                    catchError(error => of(authActions.loginUserFailure({ payload: error }))),
                    finalize(() => this.store.dispatch(loadingActions.loginUserLoadingFinished()))
                )
            )
        )
    );

    /**
     * Executes user logout.
     * @param action$
     */
    userLogout$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(authActions.logoutUser, authActions.logoutUserInterceptor),
            mergeMap(({ payload }) =>
                this.http
                    .post(
                        ApiEndpoints.logoutUser(this.localStorage.getItem('refreshToken') || ''),
                        payload
                    )
                    .pipe(
                        map((data: any) => authActions.logoutUserSuccess()),
                        catchError(error => of(authActions.logoutUserFailure({ payload: error }))),
                        finalize(() => this.router.navigate(['/']))
                    )
            )
        )
    );

    /**
     * Confirms user auth and refresh tokens and sets latest user data.
     * @param action$
     */
    confirmLogin$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(authActions.confirmLogin),
            withLatestFrom(this.userSelectors.account$),
            mergeMap(([{ payload }, account]) => {
                return this.http
                    .post(ApiEndpoints.confirmLogin(account.id), {
                        reIssueToken: payload.reIssueToken || false
                    })
                    .pipe(
                        map((data: any) =>
                            authActions.confirmLoginSuccess({
                                payload: this.helpersService.formatUserData(data.user)
                            })
                        )
                    );
            })
        )
    );

    // /**
    //  * Used to create an account or log in user with google auth.
    //  * @param action$
    //  */
    // @Effect()
    // googleLogin = this.actions$.pipe(
    //     ofType<PayloadAction>(AuthActions.GOOGLE_LOGIN),
    //     mergeMap(action => {
    //         this.authActions.authErrorReset({ type: GlobalErrorTypes.googleLogin });
    //         return of(action);
    //     }),
    //     mergeMap(({ payload }) =>
    //         this.http.get(ApiEndpoints.googleLogin).pipe(
    //             map(data => this.authActions.googleLoginSuccess(data)),
    //             catchError(error =>
    //                 of(
    //                     this.authActions.authErrorNew({
    //                         type: GlobalErrorTypes.googleLogin,
    //                         error: error
    //                     })
    //                 )
    //             )
    //         )
    //     )
    // );

    // /**
    //  * Used to login via Battle.net OAuth 2.0.
    //  * @param action$
    //  */
    // @Effect()
    // bnetLogin = this.actions$.pipe(
    //     ofType<PayloadAction>(AuthActions.BNET_LOGIN),
    //     mergeMap(action => {
    //         this.authActions.authErrorReset({ type: GlobalErrorTypes.bnetLogin });
    //         return of(action);
    //     }),
    //     catchError(error =>
    //         of(
    //             this.authActions.authErrorNew({
    //                 type: GlobalErrorTypes.bnetLogin,
    //                 error: error
    //             })
    //         )
    //     )
    // );

    /**
     * Sends an email to initiate a password reset for a user.
     * @param action$
     */
    passwordResetInitiate$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(authActions.passwordResetInitiate),
            tap(() => this.store.dispatch(loadingActions.passwordResetInitiateLoading())),
            mergeMap(({ payload }) =>
                this.http.post(ApiEndpoints.initializePasswordReset, payload).pipe(
                    map((data: any) => authActions.passwordResetInitiateSuccess()),
                    catchError(error =>
                        of(authActions.passwordResetInitiateFailure({ payload: error }))
                    ),
                    finalize(() => {
                        this.store.dispatch(loadingActions.passwordResetInitiateLoadingFinished());
                    })
                )
            )
        )
    );

    /**
     * Resets a user password with a new one.
     * @param action$
     */
    passwordReset$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(authActions.passwordReset),
            tap(() => this.store.dispatch(passwordResetErrorReset())),
            tap(() => this.store.dispatch(loadingActions.passwordResetLoading())),
            mergeMap(({ payload }) =>
                this.http.post(ApiEndpoints.resetPassword, payload).pipe(
                    map((data: any) => authActions.passwordResetSuccess()),
                    catchError(error => {
                        return of(authActions.passwordResetFailure({ payload: error }));
                    }),
                    finalize(() =>
                        this.store.dispatch(loadingActions.passwordResetLoadingFinished())
                    )
                )
            )
        )
    );
}
