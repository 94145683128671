import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';

import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
    provideRouter,
    withEnabledBlockingInitialNavigation,
    withInMemoryScrolling,
    withPreloading,
    withRouterConfig,
    withViewTransitions
} from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { L10nIntlModule, L10nTranslationModule } from 'angular-l10n';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { environment } from '../environments/environment';
import { CustomPreloadingStrategy } from './app.preloading-strategy';
import { routes } from './app.routes';
import { provideCore } from './core/core';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { HttpTranslationLoader, l10nConfig } from './l10n-config';
import { provideShared } from './shared/shared';
import { Actions, Selectors } from './store';
import { EffectsProviders } from './store/effects';
import { InterfaceServices } from './store/interfaces';
import { metaReducers, reducerProvider, reducerToken } from './store/store';

export const appConfig: ApplicationConfig = {
    providers: [
        importProvidersFrom(
            StoreModule.forRoot(reducerToken, {
                metaReducers,
                // do not make objects immutable as this goes against some files manager logic
                // when it comes to passing around class instances with setters
                runtimeChecks: { strictActionImmutability: false }
            }),
            EffectsModule.forRoot([...EffectsProviders] as any),
            ServiceWorkerModule.register('ngsw-worker.js', {
                enabled: environment.production || environment.test,
                registrationStrategy: 'registerImmediately'
            }),
            StoreRouterConnectingModule.forRoot(),
            LoggerModule.forRoot({
                level: NgxLoggerLevel.TRACE,
                colorScheme: ['purple', 'teal', 'gray', 'gray', 'red', 'red', 'red']
            }),
            L10nTranslationModule.forRoot(l10nConfig, {
                // storage: L10nDefaultStorage,
                translationLoader: HttpTranslationLoader
            }),
            L10nIntlModule,
            // L10nValidationModule.forRoot({ validation: LocaleValidation }),
            // L10nRoutingModule.forRoot()
            !environment.production ? StoreDevtoolsModule.instrument() : []
        ),
        provideRouter(
            routes,
            withPreloading(CustomPreloadingStrategy),
            withInMemoryScrolling({
                scrollPositionRestoration: 'enabled',
                anchorScrolling: 'enabled'
            }),
            withRouterConfig({
                paramsInheritanceStrategy: 'always'
            }),
            withEnabledBlockingInitialNavigation(),
            withViewTransitions()
        ),
        provideCore(),
        provideShared(),
        provideClientHydration(),
        Actions,
        Selectors,
        CustomPreloadingStrategy,
        InterfaceServices,
        reducerProvider,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimations()
    ]
};

