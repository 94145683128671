import { Game } from '../../core/constants/game.enum';

interface RouteSEOConfiguration {
    title: string;
    description: string;
    appendSuffix?: boolean;
    useRawTitle?: boolean;
}

interface GameSEOConfiguration {
    landing: RouteSEOConfiguration;
    courses: RouteSEOConfiguration;
    videoGuides: RouteSEOConfiguration;
    news: RouteSEOConfiguration;
}

export const SEOConfiguration: { [key in Game]: GameSEOConfiguration } = {
    [Game.APEX]: {
        landing: {
            title: 'GameLeap for Apex Legends | Professional Guides and Tips',
            description: `Learn Apex Legends from the world's top pro gamers. Access updated hero courses, tricks and thousands of video guides. Join GameLeap to increase your skill today.`,
            appendSuffix: false
        },
        courses: {
            title: 'Courses',
            description: `Watch hundreds of hours of guides and tutorials by professional players, organized into courses. Master different heroes and roles and gain MMR as a result.`,
            appendSuffix: true
        },
        videoGuides: {
            title: 'Video Guides',
            description:
                'Watch a selection of Video Guides and tutorials for your favorite heroes and maps. Learn the tips and tricks the best players know.',
            appendSuffix: true
        },
        news: {
            title: 'Latest News',
            description: 'Get the latest news and updates.',
            appendSuffix: true
        }
    },
    [Game.DOTA]: {
        landing: {
            title: 'GameLeap for Dota 2 | Gain Ranked MMR like a Pro',
            description: `Learn Dota 2 from the world's top pro gamers. Access updated hero courses, builds, tricks and thousands of video guides. Join GameLeap and increase your mmr today.`,
            appendSuffix: false
        },
        courses: {
            title: 'Courses',
            description: `Watch hundreds of hours of guides and tutorials by professional players, organized into courses. Master different heroes and roles and gain MMR as a result.`,
            appendSuffix: true
        },
        videoGuides: {
            title: 'Video Guides',
            description:
                'Watch a selection of Video Guides and tutorials for your favorite heroes and maps. Learn the tips and tricks the best players know.',
            appendSuffix: true
        },
        news: {
            title: 'Latest News',
            description: 'Get the latest news and updates.',
            appendSuffix: true
        }
    },
    [Game.FORTNITE]: {
        landing: {
            title: 'GameLeap for Fortnite | Champion Guides and Tips',
            description: `Learn Fortnite from the world's top pro gamers. Access updated hero courses, tricks and thousands of video guides. Join GameLeap and increase your skill today.`,
            appendSuffix: false
        },
        courses: {
            title: 'Courses',
            description: `Watch hundreds of hours of guides and tutorials by professional players, organized into courses. Master different heroes and roles and gain MMR as a result.`,
            appendSuffix: true
        },
        videoGuides: {
            title: 'Video Guides',
            description:
                'Watch a selection of Video Guides and tutorials for your favorite heroes and maps. Learn the tips and tricks the best players know.',
            appendSuffix: true
        },
        news: {
            title: 'Latest News',
            description: 'Get the latest news and updates.',
            appendSuffix: true
        }
    },
    [Game.HALO]: {
        landing: {
            title: 'GameLeap for Halo Infinite | Competitive Guides and Tips',
            description: `Learn Halo Infinite from the world's top-rated gamers. Access up-to-date courses, tricks and thousands of video guides. Join GameLeap to increase your skill today.`,
            appendSuffix: false
        },
        courses: {
            title: 'Courses',
            description: `Watch hundreds of hours of guides and tutorials by professional players, organized into courses. Master different heroes and roles and gain MMR as a result.`,
            appendSuffix: true
        },
        videoGuides: {
            title: 'Video Guides',
            description:
                'Watch a selection of Video Guides and tutorials for your favorite heroes and maps. Learn the tips and tricks the best players know.',
            appendSuffix: true
        },
        news: {
            title: 'Latest News',
            description: 'Get the latest news and updates.',
            appendSuffix: true
        }
    },
    [Game.LEAGUE]: {
        landing: {
            title: 'GameLeap for League of Legends | Champion Guides and Tips',
            description: `Learn League of Legends from the world's top pro gamers. Access updated hero courses, tricks and thousands of video guides. Join GameLeap and increase your skill today.`,
            appendSuffix: false
        },
        courses: {
            title: 'Courses',
            description: `Watch hundreds of hours of guides and tutorials by professional players, organized into courses. Master different heroes and roles and gain MMR as a result.`,
            appendSuffix: true
        },
        videoGuides: {
            title: 'Video Guides',
            description:
                'Watch a selection of Video Guides and tutorials for your favorite heroes and maps. Learn the tips and tricks the best players know.',
            appendSuffix: true
        },
        news: {
            title: 'Latest News',
            description: 'Get the latest news and updates.',
            appendSuffix: true
        }
    },
    [Game.OVERWATCH2]: {
        landing: {
            title: 'GameLeap for Overwatch 2 | Hero Guides and Tips for more SR',
            description: `Learn Overwatch 2 from the world's top pro gamers. Access updated hero courses, tricks and thousands of video guides. Join GameLeap and increase your rank today.`,
            appendSuffix: false
        },
        courses: {
            title: 'Courses',
            description: `Watch hundreds of hours of guides and tutorials by professional players, organized into courses. Master different heroes and roles and gain MMR as a result.`,
            appendSuffix: true
        },
        videoGuides: {
            title: 'Video Guides',
            description:
                'Watch a selection of Video Guides and tutorials for your favorite heroes and maps. Learn the tips and tricks the best players know.',
            appendSuffix: true
        },
        news: {
            title: 'Latest News',
            description: 'Get the latest news and updates.',
            appendSuffix: true
        }
    },
    [Game.VALORANT]: {
        landing: {
            title: 'GameLeap for Valorant | Agent and Map Guides and Tips',
            description: `Learn Valorant from the world's top pro gamers. Access updated hero courses, tricks and thousands of video guides. Join GameLeap to increase your skill today.`,
            appendSuffix: false
        },
        courses: {
            title: 'Courses',
            description: `Watch hundreds of hours of guides and tutorials by professional players, organized into courses. Master different heroes and roles and gain MMR as a result.`,
            appendSuffix: true
        },
        videoGuides: {
            title: 'Video Guides',
            description:
                'Watch a selection of Video Guides and tutorials for your favorite heroes and maps. Learn the tips and tricks the best players know.',
            appendSuffix: true
        },
        news: {
            title: 'Latest News',
            description: 'Get the latest news and updates.',
            appendSuffix: true
        }
    }
};

