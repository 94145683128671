import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
    catchError,
    concatMap,
    filter,
    finalize,
    map,
    mergeMap,
    tap,
    withLatestFrom
} from 'rxjs/operators';
import { DashboardEndpoints } from '../../core/constants/ApiEndpoints';
import { concatLatestFrom } from '../../core/operators/concat-latest-from';
import { GameAwareHttp } from '../../core/services/game-aware-http.service';
import { Account } from '../../models/account.model';
import { Comment } from '../../models/comment.model';
import { EnrolledCourse } from '../../models/enrolled-course.model';
import { RatedComment } from '../../models/rated-comment.model';
import { SeenVideo } from '../../models/seen-guide.model';
import { UserMetadata } from '../../models/user.model';
import { PayloadAction } from '../interfaces/payload-action.interface';
import { selectSeenVideoByVideoId } from '../selectors/meta.selectors';
import { selectVideoGuideDuration } from '../selectors/video-guides.selectors';
import { GlobalState } from '../store';

import { loadMetadataEtag, loadMetadataEtagSuccess } from '../actions/cache.actions';
import * as metaActions from '../actions/meta.actions';
import { selectMetadataEtag } from '../selectors/cache.selectors';
import { selectAccount, selectIsLoggedIn } from '../selectors/users.selectors';

@Injectable()
export class MetaEffects {
    constructor(
        private actions$: Actions,
        private http: GameAwareHttp,
        private store: Store<GlobalState>
    ) {}

    public loadMetaData$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(
                metaActions.loadMetaDataEffects,
                metaActions.loadMetaDataDashboard
            ),

            concatLatestFrom(action => this.store.select(selectMetadataEtag)),
            tap(() => this.store.dispatch(loadMetadataEtag())),
            concatMap(([action, curEtag]) =>
                curEtag
                    ? this.actions$.pipe(
                          ofType(loadMetadataEtagSuccess),
                          map(cacheAction => [action, cacheAction.payload === curEtag])
                      )
                    : of([action, false])
            ),
            filter(([action, etagLatest]) => etagLatest === false),
            withLatestFrom(this.store.select(selectAccount)),
            filter(params => params != null),
            mergeMap(([[action, etagMatch], { id }]: [[PayloadAction, boolean], Account]) =>
                this.http.get(DashboardEndpoints.getMetaData(id), action.payload).pipe(
                    map((data: UserMetadata) =>
                        metaActions.loadMetaDataDashboardSuccess({
                            payload: data
                        })
                    ),
                    catchError(error =>
                        of(metaActions.loadMetaDataDashboardFailure({ payload: error }))
                    )
                )
            )
        )
    );

    public setVideoSeen$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(metaActions.setVideoSeen),
            concatLatestFrom(({ payload }) => [
                this.store.select(selectAccount),
                this.store.select(selectVideoGuideDuration, ['courseId', payload.videoId]),
                this.store.select(selectIsLoggedIn)
            ]),
            filter(([action, account, seenVideo, isLoggedIn]) => isLoggedIn),
            mergeMap(([{ payload }, { id }, duration, isLoggedIn]) =>
                this.http
                    .post(DashboardEndpoints.newSeenVideo(id), { videoId: payload.videoId })
                    .pipe(
                        map((seenVideo: SeenVideo) =>
                            metaActions.setVideoSeenSuccess({
                                payload: seenVideo
                            })
                        ),
                        tap(() => this.store.dispatch(metaActions.loadMetaDataEffects())),
                        catchError(error =>
                            of(metaActions.setVideoSeenFailure({ payload: error }))
                        ),
                        finalize(() =>
                            this.store.dispatch(
                                metaActions.setVideoProgress({
                                    payload: { videoId: payload.videoId, progress: duration }
                                })
                            )
                        )
                    )
            )
        )
    );

    public setVideoUnseen$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(metaActions.setVideoUnseen),
            concatLatestFrom(({ payload }) => [
                this.store.select(selectAccount),
                this.store.select(selectSeenVideoByVideoId, payload.videoId),
                this.store.select(selectIsLoggedIn)
            ]),
            filter(([action, account, seenVideo, isLoggedIn]) => isLoggedIn),
            mergeMap(
                ([{ payload }, { id }, seenVideo, isLoggedIn]: [
                    PayloadAction,
                    Account,
                    SeenVideo,
                    boolean
                ]) =>
                    this.http.delete(DashboardEndpoints.deleteSeenVideo(id, seenVideo._id)).pipe(
                        map((sv: SeenVideo) =>
                            metaActions.setVideoUnseenSuccess({
                                payload: sv
                            })
                        ),
                        catchError(error =>
                            of(metaActions.setVideoUnseenFailure({ payload: error }))
                        ),
                        finalize(() => {
                            this.store.dispatch(metaActions.loadMetaDataEffects());
                            this.store.dispatch(
                                metaActions.setVideoProgress({
                                    payload: { videoId: payload.videoId, progress: 0 }
                                })
                            );
                        })
                    )
            )
        )
    );

    public setVideoProgress$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(metaActions.setVideoProgress),
            withLatestFrom(this.store.select(selectAccount), this.store.select(selectIsLoggedIn)),
            filter(([action, account, isLoggedIn]) => isLoggedIn),
            mergeMap(([{ payload }, { id }]) =>
                this.http
                    .post(DashboardEndpoints.setVideoProgress(id), {
                        videoId: payload.videoId,
                        progress: payload.progress
                    })
                    .pipe(
                        map((response: { videoId: string; progress: number }) =>
                            metaActions.setVideoProgressSuccess({
                                payload: { [response.videoId]: response.progress }
                            })
                        ),
                        catchError(error =>
                            of(metaActions.setVideoProgressFailure({ payload: error }))
                        )
                    )
            )
        )
    );

    public updateCourseEnrollment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(metaActions.updateCourseEnrollment),
            withLatestFrom(this.store.select(selectAccount), this.store.select(selectIsLoggedIn)),
            filter(([action, account, isLoggedIn]) => isLoggedIn),
            mergeMap(([{ payload }, { id }]) =>
                this.http.put(DashboardEndpoints.updateCourseEnrollment(id), payload).pipe(
                    map((courseEnrollment: EnrolledCourse) =>
                        metaActions.updateCourseEnrollmentSuccess({ payload: courseEnrollment })
                    ),
                    tap(() => this.store.dispatch(metaActions.loadMetaDataEffects())),
                    catchError(error =>
                        of(
                            metaActions.updateCourseEnrollmentFailure({
                                payload: error
                            })
                        )
                    )
                    // finalize(() => this.store.dispatch(loadingActions.courseLoadingFinished()))
                )
            )
        )
    );

    public likeComment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(metaActions.likeComment),
            withLatestFrom(this.store.select(selectAccount)),
            filter(([action, account]) => account != null),
            mergeMap(([{ payload }, { id }]) =>
                this.http.post(DashboardEndpoints.likeComment(id), payload).pipe(
                    map((data: { ratedComment: RatedComment; comment: Comment }) =>
                        metaActions.likeCommentSuccess({ payload: data })
                    ),
                    // tap(() => this.store.dispatch(metaActions.loadMetaDataEffects())),
                    catchError(error =>
                        of(
                            metaActions.likeCommentFailure({
                                payload: error
                            })
                        )
                    )
                    // finalize(() => this.store.dispatch(loadingActions.courseLoadingFinished()))
                )
            )
        )
    );

    public dislikeComment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(metaActions.dislikeComment),
            withLatestFrom(this.store.select(selectAccount)),
            filter(([action, account]) => account != null),
            mergeMap(([{ payload }, { id }]) =>
                this.http.post(DashboardEndpoints.dislikeComment(id), payload).pipe(
                    map((data: { ratedComment: RatedComment; comment: Comment }) =>
                        metaActions.dislikeCommentSuccess({ payload: data })
                    ),
                    // tap(() => this.store.dispatch(metaActions.loadMetaDataEffects())),
                    catchError(error =>
                        of(
                            metaActions.dislikeCommentFailure({
                                payload: {
                                    ...error,
                                    commentId: payload.commentId
                                }
                            })
                        )
                    )
                    // finalize(() => this.store.dispatch(loadingActions.courseLoadingFinished()))
                )
            )
        )
    );

    public deleteRatedComment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(metaActions.deleteRatedComment),
            withLatestFrom(this.store.select(selectAccount)),
            filter(([action, account]) => account != null),
            mergeMap(([{ payload }, { id }]) =>
                this.http.delete(DashboardEndpoints.deleteRatedComment(id, payload._id)).pipe(
                    map((data: { ratedComment: RatedComment; comment: Comment }) =>
                        metaActions.deleteRatedCommentSuccess({ payload: data })
                    ),
                    // tap(() => this.store.dispatch(metaActions.loadMetaDataEffects())),
                    catchError(error =>
                        of(
                            metaActions.deleteRatedCommentFailure({
                                payload: error
                            })
                        )
                    )
                    // finalize(() => this.store.dispatch(loadingActions.courseLoadingFinished()))
                )
            )
        )
    );
}

