import { Game } from '../game.enum';

/**
 * Apex Legends
 */
export enum ApexRank {
    BRONZE = 'bronze',
    SILVER = 'silver',
    GOLD = 'gold',
    PLATINUM = 'platinum',
    DIAMOND = 'diamond',
    MASTER = 'master',
    APEX_PREDATOR = 'apex-predator'
}
export const ApexRanks = [
    { name: 'Bronze', value: ApexRank.BRONZE },
    { name: 'Silver', value: ApexRank.SILVER },
    { name: 'Gold', value: ApexRank.GOLD },
    { name: 'Platinum', value: ApexRank.PLATINUM },
    { name: 'Diamond', value: ApexRank.DIAMOND },
    { name: 'Master', value: ApexRank.MASTER },
    { name: 'Apex Predator', value: ApexRank.APEX_PREDATOR }
];
export const ApexRanksMap = ApexRanks.reduce((result, rankObj) => {
    result[rankObj.value] = rankObj.name;
    return result;
}, {});

/**
 * Dota 2
 */
export enum DotaRank {
    HERALD = 'herald',
    GUARDIAN = 'guardian',
    CRUSADER = 'crusader',
    ARCHON = 'archon',
    LEGEND = 'legend',
    DIVINE = 'divine',
    IMMORTAL = 'immortal',
    PLACED = 'placed', // Immortal-1
    TOP_1000 = 'top-1000', // Immortal-2
    TOP_100 = 'top-100', // Immortal-3
    TOP_10 = 'top-10', // Immortal-4
    TOP_1 = 'top-1' // Immortal-5
}
export const DotaRanks = [
    { name: 'Herald', value: DotaRank.HERALD },
    { name: 'Guardian', value: DotaRank.GUARDIAN },
    { name: 'Crusader', value: DotaRank.CRUSADER },
    { name: 'Archon', value: DotaRank.ARCHON },
    { name: 'Legend', value: DotaRank.LEGEND },
    { name: 'Divine', value: DotaRank.DIVINE },
    { name: 'Immortal', value: DotaRank.IMMORTAL },
    { name: 'Placed', value: DotaRank.PLACED },
    { name: 'Top 1000', value: DotaRank.TOP_1000 },
    { name: 'Top 100', value: DotaRank.TOP_100 },
    { name: 'Top 10', value: DotaRank.TOP_10 },
    { name: 'Top 1', value: DotaRank.TOP_1 }
];
export const DotaRanksMap = DotaRanks.reduce((result, rankObj) => {
    result[rankObj.value] = rankObj.name;
    return result;
}, {});

/**
 * League of Legends
 */
export enum LeagueRank {
    IRON = 'iron',
    BRONZE = 'bronze',
    SILVER = 'silver',
    GOLD = 'gold',
    PLATINUM = 'platinum',
    DIAMOND = 'diamond',
    MASTER = 'master',
    GRANDMASTER = 'grandmaster',
    CHALLENGER = 'challenger'
}
export const LeagueRanks = [
    { name: 'Iron', value: LeagueRank.IRON },
    { name: 'Bronze', value: LeagueRank.BRONZE },
    { name: 'Silver', value: LeagueRank.SILVER },
    { name: 'Gold', value: LeagueRank.GOLD },
    { name: 'Platinum', value: LeagueRank.PLATINUM },
    { name: 'Diamond', value: LeagueRank.DIAMOND },
    { name: 'Master', value: LeagueRank.MASTER },
    { name: 'Grandmaster', value: LeagueRank.GRANDMASTER },
    { name: 'Challenger', value: LeagueRank.CHALLENGER }
];
export const LeagueRanksMap = LeagueRanks.reduce((result, rankObj) => {
    result[rankObj.value] = rankObj.name;
    return result;
}, {});

/**
 * Overwatch
 */
export enum OWRank {
    BRONZE = 'bronze',
    SILVER = 'silver',
    GOLD = 'gold',
    PLATINUM = 'platinum',
    DIAMOND = 'diamond',
    MASTER = 'master',
    GRANDMASTER = 'grandmaster',
    TOP_500 = 'top-500',
    OVERALL = 'overall'
}
export const OWRanks = [
    { name: 'Bronze', value: OWRank.BRONZE },
    { name: 'Silver', value: OWRank.SILVER },
    { name: 'Gold', value: OWRank.GOLD },
    { name: 'Platinum', value: OWRank.PLATINUM },
    { name: 'Diamond', value: OWRank.DIAMOND },
    { name: 'Master', value: OWRank.MASTER },
    { name: 'Grandmaster', value: OWRank.GRANDMASTER },
    { name: 'Top 500', value: OWRank.TOP_500 }
];
export const OWRanksMap = OWRanks.reduce((result, rankObj) => {
    result[rankObj.value] = rankObj.name;
    return result;
}, {});

/**
 * Overwatch 2
 */
export enum OW2Rank {
    BRONZE = 'bronze',
    SILVER = 'silver',
    GOLD = 'gold',
    PLATINUM = 'platinum',
    DIAMOND = 'diamond',
    MASTER = 'master',
    GRANDMASTER = 'grandmaster',
    TOP_500 = 'top-500',
    OVERALL = 'overall'
}
export const OW2Ranks = [
    { name: 'Bronze', value: OW2Rank.BRONZE },
    { name: 'Silver', value: OW2Rank.SILVER },
    { name: 'Gold', value: OW2Rank.GOLD },
    { name: 'Platinum', value: OW2Rank.PLATINUM },
    { name: 'Diamond', value: OW2Rank.DIAMOND },
    { name: 'Master', value: OW2Rank.MASTER },
    { name: 'Grandmaster', value: OW2Rank.GRANDMASTER },
    { name: 'Top 500', value: OW2Rank.TOP_500 }
];
export const OW2RanksMap = OW2Ranks.reduce((result, rankObj) => {
    result[rankObj.value] = rankObj.name;
    return result;
}, {});

/**
 * Fortnite
 */
export enum FortniteRank {
    OPEN = 'open',
    CONTENDER = 'contender',
    CHAMPION = 'champion'
}
export const FortniteRanks = [
    { name: 'Open League', value: FortniteRank.OPEN },
    { name: 'Contender League', value: FortniteRank.CONTENDER },
    { name: 'Champion League', value: FortniteRank.CHAMPION }
];
export const FortniteRanksMap = FortniteRanks.reduce((result, rankObj) => {
    result[rankObj.value] = rankObj.name;
    return result;
}, {});

/**
 * Valorant
 */
export enum ValorantRank {
    IRON = 'iron',
    BRONZE = 'bronze',
    SILVER = 'silver',
    GOLD = 'gold',
    PLATINUM = 'platinum',
    DIAMOND = 'diamond',
    IMMORTAL = 'immortal',
    RADIANT = 'radiant'
}
export const ValorantRanks = [
    { name: 'Iron', value: ValorantRank.IRON },
    { name: 'Bronze', value: ValorantRank.BRONZE },
    { name: 'Silver', value: ValorantRank.SILVER },
    { name: 'Gold', value: ValorantRank.GOLD },
    { name: 'Platinum', value: ValorantRank.PLATINUM },
    { name: 'Diamond', value: ValorantRank.DIAMOND },
    { name: 'Immortal', value: ValorantRank.IMMORTAL },
    { name: 'Radiant', value: ValorantRank.RADIANT }
];
export const ValorantRanksMap = ValorantRanks.reduce((result, rankObj) => {
    result[rankObj.value] = rankObj.name;
    return result;
}, {});

/**
 * Halo Infinite
 */
export enum HaloRank {
    BRONZE = 'bronze',
    SILVER = 'silver',
    GOLD = 'gold',
    PLATINUM = 'platinum',
    DIAMOND = 'diamond',
    ONYX = 'onyx'
}
export const HaloRanks = [
    { name: 'Bronze', value: HaloRank.BRONZE },
    { name: 'Silver', value: HaloRank.SILVER },
    { name: 'Gold', value: HaloRank.GOLD },
    { name: 'Platinum', value: HaloRank.PLATINUM },
    { name: 'Diamond', value: HaloRank.DIAMOND },
    { name: 'Onyx', value: HaloRank.ONYX }
];
export const HaloRanksMap = HaloRanks.reduce((result, rankObj) => {
    result[rankObj.value] = rankObj.name;
    return result;
}, {});

export const GameRank = {
    [Game.APEX]: ApexRank,
    [Game.DOTA]: DotaRank,
    [Game.LEAGUE]: LeagueRank,
    [Game.OVERWATCH2]: OW2Rank,
    [Game.FORTNITE]: FortniteRank,
    [Game.VALORANT]: ValorantRank,
    [Game.HALO]: HaloRank
};
export const GameRanks = {
    [Game.APEX]: ApexRanks,
    [Game.DOTA]: DotaRanks,
    [Game.LEAGUE]: LeagueRanks,
    [Game.OVERWATCH2]: OW2Ranks,
    [Game.FORTNITE]: FortniteRanks,
    [Game.VALORANT]: ValorantRanks,
    [Game.HALO]: HaloRanks
};
export const GameRanksMap = {
    [Game.APEX]: ApexRanksMap,
    [Game.DOTA]: DotaRanksMap,
    [Game.LEAGUE]: LeagueRanksMap,
    [Game.OVERWATCH2]: OW2RanksMap,
    [Game.FORTNITE]: FortniteRanksMap,
    [Game.VALORANT]: ValorantRanksMap,
    [Game.HALO]: HaloRanksMap
};
