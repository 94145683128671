import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, combineLatest, of, race } from 'rxjs';
import { map, take } from 'rxjs/operators';
import {
    resetVisitorPromoCodeData,
    setCampaignId,
    setFirstVisit,
    setPreviousAffiliateId,
    trackVisitEvent,
    validateCampaignSuccess,
    validatePromoCodeFailure,
    validatePromoCodeSuccess,
    validateVisitorCampaign,
    validateVisitorPromoCode
} from '../../../store/actions/referral.actions';
import { PayloadAction } from '../../../store/interfaces/payload-action.interface';
import {
    selectFirstVisitTracked,
    selectPreviousAffiliateId,
    selectPromoCodeData
} from '../../../store/selectors/referral.selectors';
import { GlobalState } from '../../../store/store';

@Injectable()
export class ReferralResolver {
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private store: Store<GlobalState>,
        private actions$: Actions
    ) {}

    private trackVisit() {
        const firstVisitTracked = this.store.selectSignal(selectFirstVisitTracked)();
        const previousAffiliateId = this.store.selectSignal(selectPreviousAffiliateId)();
        const promoCodeData = this.store.selectSignal(selectPromoCodeData)();

        const curAffiliateId = promoCodeData.affiliate && promoCodeData.affiliate._id;

        if (
            (!firstVisitTracked || previousAffiliateId != curAffiliateId) &&
            promoCodeData?.isValid
        ) {
            const campaignId = this.route.snapshot.queryParams.c;

            if (campaignId) {
                this.store.dispatch(setCampaignId({ payload: { campaignId } }));
            }

            this.store.dispatch(
                trackVisitEvent({ payload: { affiliateId: curAffiliateId, campaignId } })
            );
        }

        if (promoCodeData.isValid) {
            this.store.dispatch(
                setPreviousAffiliateId({
                    payload: {
                        previousAffiliateId:
                            promoCodeData?.affiliate && promoCodeData?.affiliate._id
                    }
                })
            );
            this.store.dispatch(setFirstVisit({ payload: { firstVisitTracked: true } }));
        }
    }

    private loadDataComplete(route: ActivatedRouteSnapshot) {
        /**
         * For some reason whenever you navigate to '/' from the account page, the /:promoCode route is activated
         * and not the main '/' route. This is why this code will redirect to '/' again if a promoCode is not provided in the url.
         */
        if (!route.paramMap.get('promoCode')) {
            this.router.navigate(['/']);

            return of(null);
        }
        const pc = route.paramMap.get('promoCode');
        const observables = [
            this.actions$.pipe(
                ofType<PayloadAction>(validatePromoCodeSuccess),
                map(({ payload }) => payload),
                map(() => this.trackVisit())
            )
        ];

        if (route.queryParams.c) {
            this.store.dispatch(
                validateVisitorCampaign({ payload: { campaignId: route.queryParams.c } })
            );
            observables.push(
                this.actions$.pipe(
                    ofType<PayloadAction>(validateCampaignSuccess),
                    map(({ payload }) => payload),
                    map(() => this.trackVisit())
                )
            );
        }
        if (pc != null) {
            this.store.dispatch(
                validateVisitorPromoCode({
                    payload: { promoCode: pc }
                })
            );
        }

        return race(
            this.actions$.pipe(ofType<PayloadAction>(validatePromoCodeFailure)),
            this.actions$.pipe(ofType<PayloadAction>(resetVisitorPromoCodeData)),
            combineLatest(observables).pipe(take(1))
        ).pipe(take(1));
    }

    resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> {
        return this.loadDataComplete(route);
    }
}

