import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

const FA_ROOT_PATH = 'assets/icons/fontawesome/svgs/';

@Injectable()
export class IconsService {
    private iconsList = [
        {
            name: 'house',
            path: 'regular/house'
        },
        {
            name: 'album-collection',
            path: 'regular/album-collection'
        },
        {
            name: 'clapperboard-play',
            path: 'regular/clapperboard-play'
        },
        {
            name: 'newspaper',
            path: 'regular/newspaper'
        },
        {
            name: 'arrow-left',
            path: 'regular/arrow-left'
        },
        {
            name: 'arrow-right',
            path: 'regular/arrow-right'
        },
        {
            name: 'play',
            path: 'regular/play'
        },
        {
            name: 'circle-play',
            path: 'regular/circle-play'
        },
        {
            name: 'pause',
            path: 'regular/pause'
        },
        {
            name: 'magnifying-glass',
            path: 'regular/magnifying-glass'
        },
        {
            name: 'clock-rotate-left',
            path: 'regular/clock-rotate-left'
        },
        {
            name: 'circle-xmark',
            path: 'regular/circle-xmark'
        },
        {
            name: 'xmark',
            path: 'regular/xmark'
        },
        {
            name: 'forward-step',
            path: 'regular/forward-step'
        },
        {
            name: 'face-sad-tear',
            path: 'regular/face-sad-tear'
        },
        {
            name: 'twitter',
            path: 'brands/twitter'
        },
        {
            name: 'youtube',
            path: 'brands/youtube'
        },
        {
            name: 'facebook',
            path: 'brands/facebook'
        },
        {
            name: 'discord',
            path: 'brands/discord'
        },
        {
            name: 'clock',
            path: 'regular/clock'
        },
        {
            name: 'check',
            path: 'regular/check'
        },
        {
            name: 'circle-check',
            path: 'regular/circle-check'
        },
        {
            name: 'badge-check',
            path: 'regular/badge-check'
        },
        {
            name: 'ballot-check',
            path: 'regular/ballot-check'
        },
        {
            name: 'chevron-down',
            path: 'regular/chevron-down'
        },
        {
            name: 'chevron-up',
            path: 'regular/chevron-up'
        },
        {
            name: 'chevron-left',
            path: 'regular/chevron-left'
        },
        {
            name: 'chevron-right',
            path: 'regular/chevron-right'
        },
        {
            name: 'gamepad',
            path: 'regular/gamepad'
        },
        {
            name: 'gamepad-modern',
            path: 'regular/gamepad-modern'
        },
        {
            name: 'user',
            path: 'regular/user'
        },
        {
            name: 'users',
            path: 'regular/users'
        },
        {
            name: 'circle-user',
            path: 'regular/circle-user'
        },
        {
            name: 'circle-user',
            path: 'regular/circle-user'
        },
        // login
        {
            name: 'right-to-bracket',
            path: 'regular/right-to-bracket'
        },
        // logout
        {
            name: 'right-from-bracket',
            path: 'regular/right-from-bracket'
        },
        // signup
        {
            name: 'user-plus',
            path: 'regular/user-plus'
        },
        {
            name: 'lock',
            path: 'regular/lock'
        },
        {
            name: 'unlock',
            path: 'regular/unlock'
        },
        {
            name: 'trash',
            path: 'regular/trash'
        },
        {
            name: 'plus',
            path: 'regular/plus'
        },
        {
            name: 'square-plus',
            path: 'regular/square-plus'
        },
        {
            name: 'bell',
            path: 'regular/bell'
        },
        {
            name: 'circle-info',
            path: 'regular/circle-info'
        },
        {
            name: 'info',
            path: 'regular/info'
        },
        {
            name: 'warning',
            path: 'regular/warning'
        },
        {
            name: 'diamond-exclamation',
            path: 'regular/diamond-exclamation'
        },
        // mark read
        {
            name: 'envelope-circle-check',
            path: 'regular/envelope-circle-check'
        },
        {
            name: 'chart-tree-map',
            path: 'regular/chart-tree-map'
        },
        {
            name: 'chart-tree-map',
            path: 'regular/chart-tree-map'
        },
        {
            name: 'cloud',
            path: 'regular/cloud'
        },
        {
            name: 'clipboard',
            path: 'regular/clipboard'
        },
        {
            name: 'clipboard-user',
            path: 'regular/clipboard-user'
        },
        {
            name: 'bars',
            path: 'regular/bars'
        },
        {
            name: 'calendar',
            path: 'regular/calendar'
        },
        {
            name: 'eye',
            path: 'regular/eye'
        },
        {
            name: 'arrows-rotate',
            path: 'regular/arrows-rotate'
        },
        {
            name: 'pen-to-square',
            path: 'regular/pen-to-square'
        },
        {
            name: 'ellipsis-stroke-vertical',
            path: 'regular/ellipsis-stroke-vertical'
        },
        {
            name: 'ellipsis-stroke-vertical',
            path: 'regular/ellipsis-stroke-vertical'
        },
        {
            name: 'repeat',
            path: 'regular/repeat'
        },
        {
            name: 'thumbs-up',
            path: 'regular/thumbs-up'
        },
        {
            name: 'thumbs-down',
            path: 'regular/thumbs-down'
        },
        {
            name: 'octagon',
            path: 'regular/octagon'
        },
        {
            name: 'rectangle-pro',
            path: 'regular/rectangle-pro'
        },
        {
            name: 'credit-card',
            path: 'regular/credit-card'
        },
        {
            name: 'receipt',
            path: 'regular/receipt'
        },
        {
            name: 'map',
            path: 'regular/map'
        },
        {
            name: 'globe',
            path: 'regular/globe'
        },
        {
            name: 'gift-card',
            path: 'regular/gift-card'
        },
        {
            name: 'rectangle-vertical-history',
            path: 'regular/rectangle-vertical-history'
        },
        {
            name: 'expand',
            path: 'regular/expand'
        },
        {
            name: 'expand',
            path: 'regular/expand'
        },
        {
            name: 'compress',
            path: 'regular/compress'
        },
        {
            name: 'volume',
            path: 'regular/volume'
        },
        {
            name: 'volume-slash',
            path: 'regular/volume-slash'
        },
    ];
    constructor(private iconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
        this.registerIcons();
    }

    private registerIcons() {
        this.iconsList.forEach(icon => {
            const path = FA_ROOT_PATH + icon.path + '.svg';

            this.iconRegistry.addSvgIcon(
                icon.name,
                this.domSanitizer.bypassSecurityTrustResourceUrl(path)
            );
        });
    }
}

