import {
    ApexRank,
    DotaRank,
    FortniteRank,
    HaloRank,
    LeagueRank,
    OW2Rank,
    OWRank,
    ValorantRank
} from './ranks';

/**
 * Apex Legends
 */
const rootPathApex = '/assets/images/apex';

export const apexRankIconUrls = {
    [ApexRank.BRONZE]: rootPathApex + '/bronze.png',
    [ApexRank.SILVER]: rootPathApex + '/silver.png',
    [ApexRank.GOLD]: rootPathApex + '/gold.png',
    [ApexRank.PLATINUM]: rootPathApex + '/platinum.png',
    [ApexRank.DIAMOND]: rootPathApex + '/diamond.png',
    [ApexRank.MASTER]: rootPathApex + '/master.png',
    [ApexRank.APEX_PREDATOR]: rootPathApex + '/apex-predator.png'
};

/**
 * Dota 2
 */

const rootPathDota = '/assets/images/dota';

export const dotaRankIconUrls = {
    [DotaRank.TOP_1000]: rootPathDota + '/top_1000.png',
    [DotaRank.TOP_100]: rootPathDota + '/top_100.png',
    [DotaRank.TOP_10]: rootPathDota + '/top_10.png',
    [DotaRank.TOP_1]: rootPathDota + '/top_10.png', // same icon as top 10
    [DotaRank.IMMORTAL]: rootPathDota + '/placed.png',
    [DotaRank.PLACED]: rootPathDota + '/placed.png'
};
/**
 * League of Legends
 */

const rootPathLeague = '/assets/images/league';

export const leagueRankIconUrls = {
    [LeagueRank.IRON]: rootPathLeague + '/iron.png',
    [LeagueRank.BRONZE]: rootPathLeague + '/bronze.png',
    [LeagueRank.SILVER]: rootPathLeague + '/silver.png',
    [LeagueRank.GOLD]: rootPathLeague + '/gold.png',
    [LeagueRank.PLATINUM]: rootPathLeague + '/platinum.png',
    [LeagueRank.DIAMOND]: rootPathLeague + '/diamond.png',
    [LeagueRank.MASTER]: rootPathLeague + '/master.png',
    [LeagueRank.GRANDMASTER]: rootPathLeague + '/grandmaster.png',
    [LeagueRank.CHALLENGER]: rootPathLeague + '/challenger.png'
};

/**
 * Overwatch
 */

const rootPathOW = '/assets/images/overwatch';

export const owRankIconUrls = {
    [OWRank.BRONZE]: rootPathOW + '/bronze_rank.png',
    [OWRank.SILVER]: rootPathOW + '/silver_rank.png',
    [OWRank.GOLD]: rootPathOW + '/gold_rank.png',
    [OWRank.PLATINUM]: rootPathOW + '/platinum_rank.png',
    [OWRank.DIAMOND]: rootPathOW + '/diamond_rank.png',
    [OWRank.MASTER]: rootPathOW + '/master_rank.png',
    [OWRank.GRANDMASTER]: rootPathOW + '/grandmaster_rank.png',
    [OWRank.TOP_500]: rootPathOW + '/top500_rank.png'
};
/**
 * Overwatch 2
 */

const rootPathOW2 = '/assets/images/overwatch2';

export const ow2RankIconUrls = {
    [OW2Rank.BRONZE]: rootPathOW2 + '/bronze_rank.png',
    [OW2Rank.SILVER]: rootPathOW2 + '/silver_rank.png',
    [OW2Rank.GOLD]: rootPathOW2 + '/gold_rank.png',
    [OW2Rank.PLATINUM]: rootPathOW2 + '/platinum_rank.png',
    [OW2Rank.DIAMOND]: rootPathOW2 + '/diamond_rank.png',
    [OW2Rank.MASTER]: rootPathOW2 + '/master_rank.png',
    [OW2Rank.GRANDMASTER]: rootPathOW2 + '/grandmaster_rank.png',
    [OW2Rank.TOP_500]: rootPathOW2 + '/top500_rank.png'
};
/**
 * Fortnite
 */

const rootPathFortnite = '/assets/images/fortnite';

export const fortniteRankIconUrls = {
    [FortniteRank.OPEN]: rootPathFortnite + '/open.png',
    [FortniteRank.CONTENDER]: rootPathFortnite + '/contender.png',
    [FortniteRank.CHAMPION]: rootPathFortnite + '/champion.png'
};

/**
 * Valorant
 */

const rootPathValorant = '/assets/images/valorant';

export const valorantRankIconUrls = {
    [ValorantRank.IRON]: rootPathValorant + '/iron_3.png',
    [ValorantRank.BRONZE]: rootPathValorant + '/bronze_3.png',
    [ValorantRank.SILVER]: rootPathValorant + '/silver_3.png',
    [ValorantRank.GOLD]: rootPathValorant + '/gold_3.png',
    [ValorantRank.PLATINUM]: rootPathValorant + '/platinum_3.png',
    [ValorantRank.DIAMOND]: rootPathValorant + '/diamond_3.png',
    [ValorantRank.IMMORTAL]: rootPathValorant + '/immortal_3.png',
    [ValorantRank.RADIANT]: rootPathValorant + '/radiant.png'
};

/**
 * Halo Infinite
 */

const rootPathHalo = '/assets/images/halo';

export const haloRankIconUrls = {
    [HaloRank.BRONZE]: rootPathHalo + '/bronze.png',
    [HaloRank.SILVER]: rootPathHalo + '/silver.png',
    [HaloRank.GOLD]: rootPathHalo + '/gold.png',
    [HaloRank.PLATINUM]: rootPathHalo + '/platinum.png',
    [HaloRank.DIAMOND]: rootPathHalo + '/diamond.png',
    [HaloRank.ONYX]: rootPathHalo + '/onyx.png'
};

