import { createAction, props } from '@ngrx/store';
import { APIError } from '../../models/error.model';

export enum CoursesEtagActionsTypes {
    LoadFeaturedCoursesEtag = '[Courses Effects] Load Featured Courses Etag',
    LoadFeaturedCoursesEtagSuccess = '[Content API] Load Featured Courses Etag Success',
    LoadFeaturedCoursesEtagFailure = '[Content API] Load Featured Courses Etag Failure',

    LoadCoursesEtag = '[Courses Effects] Load Courses Etag',
    LoadCoursesEtagSuccess = '[Content API] Load Courses Etag Success',
    LoadCoursesEtagFailure = '[Content API] Load Courses Etag Failure',

    LoadEnrolledCoursesEtag = '[Dashboard Home] Load Enrolled Courses Etag',
    LoadEnrolledCoursesEtagSuccess = '[Content API] Load Enrolled Courses Etag Success',
    LoadEnrolledCoursesEtagFailure = '[Content API] Load Enrolled Courses Etag Failure',

    LoadCourseEtag = '[Courses Effects] Load Course Etag',
    LoadCourseEtagSuccess = '[Content API] Load Course Etag Success',
    LoadCourseEtagFailure = '[Content API] Load Course Etag Failure'
}

export enum TagsEtagActionsTypes {
    LoadTagsEtag = '[Tags Effects] Load Tags Etag',
    LoadTagsEtagSuccess = '[Content API] Load Tags Etag Success',
    LoadTagsEtagFailure = '[Content API] Load Tags Etag Failure',
}

export enum VideoGuidesEtagActionsTypes {
    LoadRecentVideoGuidesEtag = '[Dashboard Home] Load Recent Video Guides Etag',
    LoadRecentVideoGuidesEtagSuccess = '[Content API] Load Recent Video Guides Etag Success',
    LoadRecentVideoGuidesEtagFailure = '[Content API] Load Recent Video Guides Etag Failure',

    LoadFeaturedVideoGuidesEtag = '[Base Home] Load Featured Video Guides Etag',
    LoadFeaturedVideoGuidesEtagSuccess = '[Content API] Load Featured Video Guides Etag Success',
    LoadFeaturedVideoGuidesEtagFailure = '[Content API] Load Featured Video Guides Etag Failure',

    LoadSearchVideoGuidesEtag = '[Dashboard Guides] Load Search Video Guides Etag',
    LoadSearchVideoGuidesEtagSuccess = '[Content API] Load Search Video Guides Etag Success',
    LoadSearchVideoGuidesEtagFailure = '[Content API] Load Search Video Guides Etag Failure'
}

export enum PlayersEtagActionsTypes {
    LoadFeaturedPlayersEtag = '[Main Home] Load Featured Players Etag',
    LoadFeaturedPlayersEtagSuccess = '[Content API] Load Featured Players Etag Success',
    LoadFeaturedPlayersEtagFailure = '[Content API] Load Featured Players Etag Failure'
}

export enum MetadataEtagActionsTypes {
    LoadMetadataEtag = '[Main Home] Load Metadata Etag',
    LoadMetadataEtagSuccess = '[Content API] Load Metadata Etag Success',
    LoadMetadataEtagFailure = '[Content API] Load Metadata Etag Failure'
}

export enum CacheEtagActionsTypes {
    ClearCacheEtagValues = '[App Global] Clear Cache Etag Values',
}

/**
 * Courses Cache Actions
 */

export const loadFeaturedCoursesEtag = createAction(
    CoursesEtagActionsTypes.LoadFeaturedCoursesEtag
);
export const loadFeaturedCoursesEtagSuccess = createAction(
    CoursesEtagActionsTypes.LoadFeaturedCoursesEtagSuccess,
    props<{ payload: string }>()
);
export const loadFeaturedCoursesEtagFailure = createAction(
    CoursesEtagActionsTypes.LoadFeaturedCoursesEtagFailure,
    props<{ payload: APIError }>()
);

export const loadCoursesEtag = createAction(CoursesEtagActionsTypes.LoadCoursesEtag);
export const loadCoursesEtagSuccess = createAction(
    CoursesEtagActionsTypes.LoadCoursesEtagSuccess,
    props<{ payload: string }>()
);
export const loadCoursesEtagFailure = createAction(
    CoursesEtagActionsTypes.LoadCoursesEtagFailure,
    props<{ payload: APIError }>()
);

export const loadEnrolledCoursesEtag = createAction(
    CoursesEtagActionsTypes.LoadEnrolledCoursesEtag
);
export const loadEnrolledCoursesEtagSuccess = createAction(
    CoursesEtagActionsTypes.LoadEnrolledCoursesEtagSuccess,
    props<{ payload: string }>()
);
export const loadEnrolledCoursesEtagFailure = createAction(
    CoursesEtagActionsTypes.LoadEnrolledCoursesEtagFailure,
    props<{ payload: APIError }>()
);

export const loadCourseEtag = createAction(
    CoursesEtagActionsTypes.LoadCourseEtag,
    props<{
        payload: {
            courseId: string;
        };
    }>()
);
export const loadCourseEtagSuccess = createAction(
    CoursesEtagActionsTypes.LoadCourseEtagSuccess,
    props<{ payload: { courseId: string; etag: string } }>()
);
export const loadCourseEtagFailure = createAction(
    CoursesEtagActionsTypes.LoadCourseEtagFailure,
    props<{ payload: APIError }>()
);

/**
 * Tags Cache Actions
 */

export const loadTagsEtag = createAction(
    TagsEtagActionsTypes.LoadTagsEtag
);
export const loadTagsEtagSuccess = createAction(
    TagsEtagActionsTypes.LoadTagsEtagSuccess,
    props<{ payload: string }>()
);
export const loadTagsEtagFailure = createAction(
    TagsEtagActionsTypes.LoadTagsEtagFailure,
    props<{ payload: APIError }>()
);

/**
 * Video Guides Cache Actions
 */

export const loadRecentVideoGuidesEtag = createAction(
    VideoGuidesEtagActionsTypes.LoadRecentVideoGuidesEtag
);
export const loadRecentVideoGuidesEtagSuccess = createAction(
    VideoGuidesEtagActionsTypes.LoadRecentVideoGuidesEtagSuccess,
    props<{ payload: string }>()
);
export const loadRecentVideoGuidesEtagFailure = createAction(
    VideoGuidesEtagActionsTypes.LoadRecentVideoGuidesEtagFailure,
    props<{ payload: APIError }>()
);

export const loadFeaturedVideoGuidesEtag = createAction(
    VideoGuidesEtagActionsTypes.LoadFeaturedVideoGuidesEtag
);
export const loadFeaturedVideoGuidesEtagSuccess = createAction(
    VideoGuidesEtagActionsTypes.LoadFeaturedVideoGuidesEtagSuccess,
    props<{ payload: string }>()
);
export const loadFeaturedVideoGuidesEtagFailure = createAction(
    VideoGuidesEtagActionsTypes.LoadFeaturedVideoGuidesEtagFailure,
    props<{ payload: APIError }>()
);

export const loadSearchVideoGuidesEtag = createAction(
    VideoGuidesEtagActionsTypes.LoadSearchVideoGuidesEtag,
    props<{
        payload: {
            term: string;
            searchType: string;
            sortingType: string;
            pageNumber: number;
            skipFirstPage: boolean;
            playerId?: string;
            role?: string;
            courseId?: string;
        };
    }>()
);
export const loadSearchVideoGuidesEtagSuccess = createAction(
    VideoGuidesEtagActionsTypes.LoadSearchVideoGuidesEtagSuccess,
    props<{ payload: string }>()
);
export const loadSearchVideoGuidesEtagFailure = createAction(
    VideoGuidesEtagActionsTypes.LoadSearchVideoGuidesEtagFailure,
    props<{ payload: APIError }>()
);

/**
 * Players Cache Actions
 */

export const loadFeaturedPlayersEtag = createAction(
    PlayersEtagActionsTypes.LoadFeaturedPlayersEtag
);
export const loadFeaturedPlayersEtagSuccess = createAction(
    PlayersEtagActionsTypes.LoadFeaturedPlayersEtagSuccess,
    props<{ payload: string }>()
);
export const loadFeaturedPlayersEtagFailure = createAction(
    PlayersEtagActionsTypes.LoadFeaturedPlayersEtagFailure,
    props<{ payload: APIError }>()
);

/**
 * Metadata Cache Actions
 */
export const loadMetadataEtag = createAction(MetadataEtagActionsTypes.LoadMetadataEtag);
export const loadMetadataEtagSuccess = createAction(
    MetadataEtagActionsTypes.LoadMetadataEtagSuccess,
    props<{ payload: string }>()
);
export const loadMetadataEtagFailure = createAction(
    MetadataEtagActionsTypes.LoadMetadataEtagFailure,
    props<{ payload: APIError }>()
);

/**
 * Cache Etag Actions
 */
export const clearCacheEtagValues = createAction(CacheEtagActionsTypes.ClearCacheEtagValues);
