import { createReducer, on } from '@ngrx/store';
import { APIError } from '../../models/error.model';
import * as authActions from '../actions/auth.actions';
import * as errorActions from '../actions/errors.actions';
import * as referralActions from '../actions/referral.actions';
import * as paymentActions from '../actions/payment.actions';

export interface VideoGuidesErrorsState {
    recentVideoGuides: null | APIError;
}
export interface ReferralErrorsState {
    validatePromoCode: null | APIError;
}

export interface AuthErrorsState {
    loginUser: null | APIError;
    signupUser: null | APIError;
    passwordReset: null | APIError;
}
export interface PaymentErrorsState {
    newSubscription: null | APIError;
}

export interface ErrorsState {
    videoGuidesErrors: VideoGuidesErrorsState;
    referralErrors: ReferralErrorsState;
    authErrors: AuthErrorsState;
    paymentErrors: PaymentErrorsState;
}

export const errorsInitialState: ErrorsState = {
    videoGuidesErrors: {
        recentVideoGuides: null
    },
    referralErrors: {
        validatePromoCode: null
    },
    authErrors: {
        loginUser: null,
        signupUser: null,
        passwordReset: null
    },
    paymentErrors: {
        newSubscription: null
    }
};

export const errorsReducer = createReducer(
    errorsInitialState,
    on(referralActions.validatePromoCodeFailure, (state, { payload }) => ({
        ...state,
        referralErrors: {
            ...state.referralErrors,
            validatePromoCode: payload
        }
    })),
    on(errorActions.validatePromoCodeErrorReset, state => ({
        ...state,
        referralErrors: {
            ...state.referralErrors,
            validatePromoCode: null
        }
    })),
    on(authActions.loginUserFailure, (state, { payload }) => ({
        ...state,
        authErrors: {
            ...state.authErrors,
            loginUser: payload
        }
    })),
    on(errorActions.loginErrorReset, state => ({
        ...state,
        authErrors: {
            ...state.authErrors,
            loginUser: null
        }
    })),
    on(authActions.signupUserFailure, (state, { payload }) => ({
        ...state,
        authErrors: {
            ...state.authErrors,
            signupUser: payload
        }
    })),
    on(errorActions.signupErrorReset, state => ({
        ...state,
        authErrors: {
            ...state.authErrors,
            signupUser: null
        }
    })),
    on(authActions.passwordResetFailure, (state, { payload }) => ({
        ...state,
        authErrors: {
            ...state.authErrors,
            passwordReset: payload
        }
    })),
    on(errorActions.passwordResetErrorReset, state => ({
        ...state,
        authErrors: {
            ...state.authErrors,
            passwordReset: null
        }
    })),
    on(paymentActions.newSubscriptionFailure, (state, { payload }) => ({
        ...state,
        paymentErrors: {
            ...state.paymentErrors,
            newSubscription: payload
        }
    })),
    on(errorActions.newSubscriptionErrorReset, state => ({
        ...state,
        paymentErrors: {
            ...state.paymentErrors,
            newSubscription: null
        }
    }))
);
