import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, NgZone, PLATFORM_ID } from '@angular/core';
import { SwPush, SwUpdate } from '@angular/service-worker';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, combineLatest, delay, take } from 'rxjs';
import { environment } from '../../../environments/environment';
import { updatePushSubscription } from '../../store/actions/notifications.actions';
import { selectNotificationsReceiver } from '../../store/selectors/notifications.selectors';
import { selectIsLoggedIn } from '../../store/selectors/users.selectors';
import { GlobalState } from '../../store/store';
import { AppSettings } from '../constants/AppSettings';

@Injectable()
export class ServiceWorkerService {
    private notificationsPromptConditionsMetSubject: BehaviorSubject<boolean> = new BehaviorSubject<
        boolean
    >(false);
    public notificationsPromptConditionsMet$: Observable<
        boolean
    > = this.notificationsPromptConditionsMetSubject.asObservable();

    constructor(
        private swUpdate: SwUpdate,
        private swPush: SwPush,
        private store: Store<GlobalState>,
        @Inject(PLATFORM_ID) private platformId: Object,
        private ngZone: NgZone
    ) {
        this.init();
    }

    private isEnabled() {
        return this.swUpdate.isEnabled;
    }

    /**
     * Currently, the ServiceWorkerModule fails to register the service worker, so we have to register it manually.
     * This is what the init() method below does.
     *
     * If you want to test the worker locally, follow these steps:
     *      1. Remove the '&& environment.production' check in the code below.
     *      2. In angular.json, set "serviceWorker": "true" in the dev configuration
     *      3. Add these lines to the assets array in angular.json:
     *           "src/ngsw.json",
     *           "src/ngsw-worker.js",
     *           "src/safety-worker.js",
     *           "src/worker-basic.min.js"
     *      4. Create a production build, then go to the prod folder and copy the files above and paste them inside
     *      the 'src' folder.
     */
    public init() {
        if (
            isPlatformBrowser(this.platformId) &&
            this.isEnabled() &&
            'serviceWorker' in navigator &&
            (environment.production || environment.test)
        ) {
            this.ngZone.runOutsideAngular(() => {
                this.store.select(selectIsLoggedIn).subscribe(isLoggedIn => {
                    combineLatest([
                        this.store.select(selectNotificationsReceiver),
                        this.swPush.subscription
                    ])
                        // delay 3000ms in the event the just just logged in and the notifications receiver
                        // has not yet had time to be returned by the API -- we avoid a race condition
                        .pipe(delay(3000), take(1))
                        .subscribe(([receiver, subscription]) => {
                            if (receiver?.pushSubscription != subscription) {
                                this.store.dispatch(
                                    updatePushSubscription({
                                        payload: subscription
                                    })
                                );
                            }
                            if (Notification.permission != 'denied') {
                                if (isLoggedIn && !receiver?.pushSubscription) {
                                    this.notificationsPromptConditionsMetSubject.next(true);
                                }
                            }
                        });
                });
            });
        }
    }

    public requestPushPermissions(): PushSubscription | null {
        this.swPush
            .requestSubscription({
                serverPublicKey: AppSettings.PUBLIC_VAPID_KEY
            })
            .then(newSub => {
                this.store.dispatch(
                    updatePushSubscription({
                        payload: newSub
                    })
                );
            })
            .catch(error => {
                console.error(error);
            });

        return null;
    }

    public removePushPermissions(): void {
        this.swPush.unsubscribe().catch(error => {
            console.error(error);
        });
    }
}

