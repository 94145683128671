import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { CacheEtagTypes, CacheState } from '../reducers/cache.reducer';
import { GlobalState } from '../store';

const selectCache = createFeatureSelector<MemoizedSelector<GlobalState, any>>('app').projector(
    (state: GlobalState) => state.cache
);

export const selectFeaturedCoursesEtag = createSelector(
    selectCache,
    (state: CacheState) => state.etagValues[CacheEtagTypes.COURSES_FEATURED]
);
export const selectCoursesEtag = createSelector(
    selectCache,
    (state: CacheState) => state.etagValues[CacheEtagTypes.COURSES]
);
export const selectEnrolledCoursesEtag = createSelector(
    selectCache,
    (state: CacheState) => state.etagValues[CacheEtagTypes.COURSES_ENROLLED]
);
export const selectTagsEtag = createSelector(
    selectCache,
    (state: CacheState) => state.etagValues[CacheEtagTypes.TAGS]
);
export const selectRecentVideoGuidesEtag = createSelector(
    selectCache,
    (state: CacheState) => state.etagValues[CacheEtagTypes.VIDEOS_RECENT]
);
export const selectFeaturedVideoGuidesEtag = createSelector(
    selectCache,
    (state: CacheState) => state.etagValues[CacheEtagTypes.VIDEOS_FEATURED]
);
export const selectSearchVideoGuidesEtag = createSelector(
    selectCache,
    (state: CacheState) => state.etagValues[CacheEtagTypes.VIDEOS_SEARCH]
);
export const selectCourseEtag = createSelector(
    selectCache,
    (state: CacheState, props: { courseId: string }) =>
        state.etagValues[CacheEtagTypes.COURSES_EXTENDED][props.courseId]
);
export const selectFeaturedPlayersEtag = createSelector(
    selectCache,
    (state: CacheState) => state.etagValues[CacheEtagTypes.PLAYERS_FEATURED]
);
export const selectMetadataEtag = createSelector(
    selectCache,
    (state: CacheState) => state.etagValues[CacheEtagTypes.METADATA]
);
