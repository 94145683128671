import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, combineLatest, map, take } from 'rxjs';
import { selectCurrentGame } from '../../store/selectors/persist.selectors';
import { selectIsLoggedIn } from '../../store/selectors/users.selectors';
import { GlobalState } from '../../store/store';

@Injectable()
export class CanActivateGameLanding {
    constructor(private store: Store<GlobalState>, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return combineLatest([
            this.store.select(selectCurrentGame),
            this.store.select(selectIsLoggedIn)
        ]).pipe(
            take(1),
            map(([game, isLoggedIn]) => {
                if (isLoggedIn) {
                    const navigateTo = ['dashboard'];

                    if (game) {
                        navigateTo.push(game);
                    }
                    this.router.navigate(navigateTo, {
                        replaceUrl: true,
                        ...(route.queryParams && { queryParams: route.queryParams })
                    });
                    return false;
                }

                return true;
            })
        );
    }
}

