import { Routes } from '@angular/router';
import { NotFoundComponent } from './core/components/not-found/not-found.component';
import { CanActivateAccount } from './core/guards/account.guard';
import { DashboardGameSetGuardV2 } from './core/guards/dashboard-game-set-v2.guard';
import { DashboardGameSetGuard } from './core/guards/dashboard-game-set.guard';
import { CanActivatePartner } from './core/guards/partner.guard';
import { RouteScrollBehaviour } from './core/services/router-scroll.service.intf';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardOutletSEOResolver } from './dashboard/resolvers/dashboard-outlet-seo.resolver';

export const routes: Routes = [
    {
        path: '',
        component: DashboardComponent,
        data: {
            breadcrumb: {
                label: 'Home'
            }
        },
        children: [
            // {
            //     path: '',
            //     loadChildren: () =>
            //         import('./dashboard/landing/dashboard-landing.module').then(
            //             m => m.DashboardLandingModule
            //         ),
            //     data: {
            //         seo: {
            //             title: 'GameLeap - Gaming News, Guides and Tips',
            //             description: `The fastest way to rank up in competitive games like Dota 2, LoL, Overwatch 2 and Valorant.
            // Learn from Pro-made video guides to master heroes, maps, make plays and improve your skills.`,
            //             appendSuffix: false
            //         },
            //         breadcrumb: {
            //             label: 'Home'
            //         }
            //     }
            // },
            {
                path: 'courses',
                loadChildren: () =>
                    import('./dashboard/courses/dashboard-courses.module').then(
                        m => m.DashboardCoursesModule
                    ),
                data: {
                    scrollBehavior: RouteScrollBehaviour.KEEP_POSITION,
                    seo: {
                        title: 'Courses',
                        description:
                            'Learn from professional video courses for esports and other games. Get up to date tips and tricks, builds and other guides.',
                        appendSuffix: true
                    }
                },
                resolve: {
                    seo: DashboardOutletSEOResolver
                }
            },
            {
                path: 'video-guides',
                loadChildren: () =>
                    import('./dashboard/guides/dashboard-guides.module').then(
                        m => m.DashboardGuidesModule
                    ),
                canActivate: [DashboardGameSetGuard],
                data: {
                    scrollBehavior: RouteScrollBehaviour.KEEP_POSITION,
                    seo: {
                        title: 'Video Guides',
                        description:
                            'Watch thousands of professional video guides and tutorials for your favorite heroes, maps and builds. Learn tips and tricks to boost your game.',
                        appendSuffix: true
                    }
                }
            },
            {
                path: 'articles',
                loadChildren: () =>
                    import('./dashboard/articles/dashboard-articles.module').then(
                        m => m.DashboardArticlesModule
                    ),
                data: {
                    scrollBehavior: RouteScrollBehaviour.KEEP_POSITION,
                    seo: {
                        title: 'Articles',
                        description:
                            'GameLeap brings you the latest news, guides, tier lists, patch notes and deals from the world of gaming, anime and tech.',
                        appendSuffix: true
                    }
                }
            },

            {
                path: 'search',
                loadChildren: () =>
                    import('./dashboard/search/dashboard-search.module').then(
                        m => m.DashboardSearchModule
                    ),
                canActivate: [DashboardGameSetGuard],
                data: {
                    seo: {
                        title: 'Search',
                        appendSuffix: true
                    },
                    // searchPage: true,
                    scrollBehavior: RouteScrollBehaviour.KEEP_POSITION
                }
            },
            {
                path: 'video-guides',
                loadChildren: () =>
                    import('./dashboard/guides/dashboard-guides.module').then(
                        m => m.DashboardGuidesModule
                    ),
                canActivate: [DashboardGameSetGuard],
                data: {
                    scrollBehavior: RouteScrollBehaviour.KEEP_POSITION
                },
                resolve: {
                    seo: DashboardOutletSEOResolver
                }
            },
            {
                path: 'course',
                loadChildren: () =>
                    import('./dashboard/course/dashboard-course.module').then(
                        m => m.DashboardCourseModule
                    ),
                canActivate: [DashboardGameSetGuard],
                data: {
                    seo: {
                        title: 'Course',
                        appendSuffix: true,
                        name: 'Course overview'
                    },
                    breadcrumb: {
                        label: 'Courses',
                        routeInterceptor: () => '/courses'
                    },
                    scrollBehavior: RouteScrollBehaviour.GO_TO_TOP
                }
            },
            {
                path: 'course',
                loadChildren: () =>
                    import('./dashboard/guide/dashboard-guide.module').then(
                        m => m.DashboardGuideModule
                    ),
                canActivate: [DashboardGameSetGuard],
                data: {
                    seo: {
                        title: 'Guide',
                        appendSuffix: true
                    },
                    breadcrumb: {
                        label: 'Courses',
                        routeInterceptor: () => '/courses'
                    },
                    useRawTitle: true,
                    scrollBehavior: RouteScrollBehaviour.GO_TO_TOP
                }
            },

            {
                path: 'login',
                loadChildren: () =>
                    import('./main/modules/login/login.module').then(m => m.LoginModule),
                data: {
                    seo: {
                        title: 'Login',
                        description: 'Log in with your GameLeap account',
                        appendSuffix: false
                    }
                }
            },
            {
                path: 'signup',
                loadChildren: () =>
                    import('./main/modules/signup/signup.module').then(m => m.SignupModule),
                data: {
                    seo: {
                        title: 'Sign up',
                        description: `Create your account to join GameLeap and receive full access to our services`,
                        appendSuffix: false
                    }
                }
            },
            {
                path: 'account',
                loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
                canActivate: [CanActivateAccount],
                data: {
                    seo: {
                        title: 'Account',
                        appendSuffix: false
                    }
                }
            },
            {
                path: 'partners',
                loadChildren: () =>
                    import('./main/modules/partner/partner.module').then(m => m.PartnerModule),
                canActivate: [CanActivatePartner]
            },

            {
                path: 'support',
                loadChildren: () =>
                    import('./main/modules/support/support.module').then(m => m.SupportModule),
                data: {
                    seo: {
                        title: 'Support',
                        description:
                            'Learn about our pricing options and how to make your account and payments.',
                        appendSuffix: false
                    }
                }
            },
            {
                path: 'privacy',
                redirectTo: 'support/privacy',
                pathMatch: 'full'
            },
            {
                path: 'faq',
                redirectTo: 'support/faq',
                pathMatch: 'full'
            },
            {
                path: 'terms',
                redirectTo: 'support/terms',
                pathMatch: 'full'
            },
            {
                path: 'overwatch',
                redirectTo: 'overwatch2',
                pathMatch: 'prefix'
            },
            {
                path: 'news',
                redirectTo: 'articles'
            },
            {
                path: 'guides',
                redirectTo: 'articles'
            },
            {
                path: 'dashboard',
                redirectTo: ''
            },
            {
                path: 'dashboard/:slug',
                redirectTo: ':slug'
            },
            {
                path: ':slug',
                loadChildren: () =>
                    import('./dashboard/home/dashboard-home.module').then(
                        m => m.DashboardHomeModule
                    ),
                resolve: {
                    seo: DashboardOutletSEOResolver
                },
                canActivate: [DashboardGameSetGuardV2]
            },

            // {
            //     path: ':promoCode',
            //     component: ReferralComponent,
            //     resolve: {
            //         promoData: ReferralResolver
            //     }
            // },
            {
                path: '404',
                redirectTo: 'not-found',
                pathMatch: 'full'
            },
            {
                path: 'not-found',
                component: NotFoundComponent,
                data: {
                    seo: {
                        title: 'Page Not Found'
                    }
                }
            },
            {
                path: '**',
                redirectTo: 'not-found'
            }
        ]
    }
];

