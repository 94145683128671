import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { take, takeWhile } from 'rxjs/operators';
import { Game, GameDisplayName } from '../../../../core/constants/game.enum';
import { SubscriptionStatus } from '../../../../models/user-subscription.model';
import { openSidenavMenu } from '../../../../store/actions/app.actions';
import { selectCurrentSubscription } from '../../../../store/selectors/payment.selectors';
import {
    selectCurrentGame
} from '../../../../store/selectors/persist.selectors';
import { selectEnabledBannerCampaign } from '../../../../store/selectors/referral.selectors';
import { selectIsLoggedIn, selectUserRoles } from '../../../../store/selectors/users.selectors';
import { GlobalState } from '../../../../store/store';
import { ClickOutsideDirective } from '../../../directives/click-outside.directive';
import { EscapePressedDirective } from '../../../directives/esc-key.directive';
import { WindowRef } from '../../../services/window.service';
import { DashboardNavigationSaleBannerComponent } from './dashboard-navigation-sale-banner/dashboard-navigation-sale-banner.component';

@Component({
    selector: 'app-dashboard-navigation',
    templateUrl: './dashboard-navigation.component.html',
    styleUrls: ['./dashboard-navigation.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgClass, EscapePressedDirective, ClickOutsideDirective, RouterLink, NgIf, RouterLinkActive, MatIconModule, DashboardNavigationSaleBannerComponent]
})
export class DashboardNavigationComponent implements OnInit {
    private componentAlive: boolean = true;

    public Game = Game;
    public isLarge: boolean = true;
    public isLoggedIn: boolean = false;
    public isOpen: boolean = false;
    public saleBannerEnabled: boolean = false;
    public saleBannerVisible: boolean = true;
    public currentGame: Game | null | undefined;
    public Games = Object.values(Game);

    public navRoutes = [
        {
            game: Game.DOTA,
            displayName: GameDisplayName[Game.DOTA],
            logoPath: '/assets/images/dota/dota2logo_small.webp'
        },
        {
            game: Game.LEAGUE,
            displayName: GameDisplayName[Game.LEAGUE],
            logoPath: '/assets/images/league/leaguelogo_small.webp'
        },
        {
            game: Game.OVERWATCH2,
            displayName: GameDisplayName[Game.OVERWATCH2],
            logoPath: '/assets/images/overwatch/overwatch_logo_small.webp'
        },
        {
            game: Game.VALORANT,
            displayName: GameDisplayName[Game.VALORANT],
            logoPath: '/assets/images/valorant/valorantlogo_small.webp'
        },
        {
            game: Game.APEX,
            displayName: GameDisplayName[Game.APEX],
            logoPath: '/assets/images/apex/apexlogo_small.webp'
        },
        {
            game: Game.HALO,
            displayName: GameDisplayName[Game.HALO],
            logoPath: '/assets/images/halo/haloinfinitelogo_small.webp'
        },
        {
            game: Game.FORTNITE,
            displayName: GameDisplayName[Game.FORTNITE],
            logoPath: '/assets/images/fortnite/fortnite_logo_small.webp'
        }
    ];

    constructor(
        private store: Store<GlobalState>,
        private actions$: Actions,
        private cdr: ChangeDetectorRef,
        public window: WindowRef
    ) {}

    public openNav() {
        this.isOpen = true;
        this.cdr.detectChanges();
    }

    public toggleNavOpen() {
        this.isOpen = !this.isOpen;
        this.cdr.detectChanges();
    }

    public closeNav() {
        this.isOpen = false;
        this.cdr.detectChanges();
    }

    public trackBy(index, item) {
        return item._id;
    }
    ngAfterViewInit() {
        this.store
            .select(selectEnabledBannerCampaign)
            .pipe(take(2))
            .subscribe(campaign => {
                this.saleBannerEnabled = campaign != null;
            });
    }

    ngOnInit(): void {
        this.store
            .select(selectIsLoggedIn)
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(isLoggedIn => (this.isLoggedIn = isLoggedIn));
        this.actions$
            .pipe(
                ofType(openSidenavMenu),
                takeWhile(() => this.componentAlive)
            )
            .subscribe(() => this.openNav());
        this.store
            .select(selectCurrentGame)
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(currentGame => {
                this.currentGame = currentGame;
                this.cdr.detectChanges();
            });
        combineLatest([
            this.store.select(selectCurrentSubscription),
            this.store.select(selectUserRoles),
            this.store.select(selectIsLoggedIn)
        ]).subscribe(([currentSubscription, roles, isLoggedIn]) => {
            const visibleOnStatuses = [
                SubscriptionStatus.CANCELED,
                SubscriptionStatus.EXPIRED,
                SubscriptionStatus.FREE
            ];
            const bannerVisibleCriteria =
                (currentSubscription && visibleOnStatuses.includes(currentSubscription.status)) ||
                !isLoggedIn ||
                roles.isFree;
            this.saleBannerVisible = bannerVisibleCriteria;
        });
    }

    ngOnDestroy() {
        this.componentAlive = false;
    }
}

