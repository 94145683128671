import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { Account } from '../../models/account.model';
import { UserRoles } from '../../models/user-role-map.model';
import { UserSelectors } from '../../store/selectors/user.selectors';

@Injectable()
export class CanActivateAffiliate {
    private account: Account;
    private userRoles: UserRoles;

    constructor(private userSelectors: UserSelectors, private router: Router) {
        this.userSelectors.account$.subscribe(account => {
            this.account = account;
        });
        this.userSelectors.userRoles$.subscribe(userRoles => {
            this.userRoles = userRoles;
        });
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (
            this.account &&
            (this.userRoles.isAdmin || route.params.affiliateId === this.account.affiliateId)
        ) {
            return true;
        }
        this.router.navigate(['/not-found'], { skipLocationChange: true });

        return false;
    }
}
