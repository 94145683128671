import { createReducer, on } from '@ngrx/store';
import * as loadingActions from '../actions/loading.actions';

export interface VideoGuidesLoadingState {
    recentVideoGuides: boolean;
    videoGuides: boolean;
}
export interface CoursesLoadingState {
    courses: boolean;
    enrolledCourses: boolean;
    course: boolean;
}
export interface AuthLoadingState {
    loginUser: boolean;
    signupUser: boolean;
    passwordReset: boolean;
    passwordResetInitiate: boolean;
}
export interface PaymentLoadingState {
    loadSubscriptions: boolean;
    newSubscription: boolean;
    addPaymentMethod: boolean;
    loadPaymentMethods: boolean;
    updatePaymentMethod: boolean;
    deletePaymentMethod: boolean;
    cancelSubscription: boolean;
    loadPayments: boolean;
}
export interface CommentsLoadingState {
    newComment: boolean;
    updateComment: boolean;
}
export interface SearchLoadingState {
    suggestedContent: boolean;
}
export interface UsersLoadingState {
    updateUser: boolean;
}
export interface ReferralLoadingState {
    trackProductSubscribeEvent: boolean;
}
export interface NewsLoadingState {
    loadRecentNews: boolean;
    loadCategoryArticles: boolean;
    loadTrendingArticles: boolean;
}

export interface LoadingState {
    // authLoading: object;
    // appLoading: object;
    // notificationsLoading: object;
    videoGuidesLoading: VideoGuidesLoadingState;
    coursesLoading: CoursesLoadingState;
    authLoading: AuthLoadingState;
    paymentLoading: PaymentLoadingState;
    commentsLoading: CommentsLoadingState;
    searchLoading: SearchLoadingState;
    usersLoading: UsersLoadingState;
    referralLoading: ReferralLoadingState;
    newsLoading: NewsLoadingState;
    // dashboardLoading: object;
}

export const loadingInitialState: LoadingState = {
    // authLoading: {},
    // appLoading: {},
    // notificationsLoading: {},
    videoGuidesLoading: {
        recentVideoGuides: false,
        videoGuides: false
    },
    coursesLoading: {
        courses: false,
        enrolledCourses: false,
        course: false
    },
    authLoading: {
        loginUser: false,
        signupUser: false,
        passwordReset: false,
        passwordResetInitiate: false
    },
    paymentLoading: {
        loadSubscriptions: false,
        newSubscription: false,
        addPaymentMethod: false,
        loadPaymentMethods: false,
        updatePaymentMethod: false,
        deletePaymentMethod: false,
        cancelSubscription: false,
        loadPayments: false
    },
    commentsLoading: {
        newComment: false,
        updateComment: false
    },
    searchLoading: {
        suggestedContent: false
    },
    usersLoading: {
        updateUser: false
    },
    referralLoading: {
        trackProductSubscribeEvent: false
    },
    newsLoading: {
        loadRecentNews: false,
        loadCategoryArticles: false,
        loadTrendingArticles: false
    }
    // dashboardLoading: {}
};

export const loadingReducer = createReducer(
    loadingInitialState,
    /**
     * Video guides loading
     */
    on(loadingActions.recentVideoGuidesLoading, state => ({
        ...state,
        videoGuidesLoading: {
            ...state.videoGuidesLoading,
            recentVideoGuides: true
        }
    })),
    on(loadingActions.recentVideoGuidesLoadingFinished, state => ({
        ...state,
        videoGuidesLoading: {
            ...state.videoGuidesLoading,
            recentVideoGuides: false
        }
    })),
    on(loadingActions.searchVideoGuidesLoading, state => ({
        ...state,
        videoGuidesLoading: {
            ...state.videoGuidesLoading,
            videoGuides: true
        }
    })),
    on(loadingActions.searchVideoGuidesLoadingFinished, state => ({
        ...state,
        videoGuidesLoading: {
            ...state.videoGuidesLoading,
            videoGuides: false
        }
    })),

    /**
     * Courses loading
     */
    on(loadingActions.coursesLoading, state => ({
        ...state,
        coursesLoading: {
            ...state.coursesLoading,
            courses: true
        }
    })),
    on(loadingActions.coursesLoadingFinished, state => ({
        ...state,
        coursesLoading: {
            ...state.coursesLoading,
            courses: false
        }
    })),
    on(loadingActions.enrolledCoursesLoading, state => ({
        ...state,
        coursesLoading: {
            ...state.coursesLoading,
            enrolledCourses: true
        }
    })),
    on(loadingActions.enrolledCoursesLoadingFinished, state => ({
        ...state,
        coursesLoading: {
            ...state.coursesLoading,
            enrolledCourses: false
        }
    })),
    on(loadingActions.courseLoading, state => ({
        ...state,
        coursesLoading: {
            ...state.coursesLoading,
            course: true
        }
    })),
    on(loadingActions.courseLoadingFinished, state => ({
        ...state,
        coursesLoading: {
            ...state.coursesLoading,
            course: false
        }
    })),

    /**
     * Auth loading
     */
    on(loadingActions.loginUserLoading, state => ({
        ...state,
        authLoading: {
            ...state.authLoading,
            loginUser: true
        }
    })),
    on(loadingActions.loginUserLoadingFinished, state => ({
        ...state,
        authLoading: {
            ...state.authLoading,
            loginUser: false
        }
    })),
    on(loadingActions.signupUserLoading, state => ({
        ...state,
        authLoading: {
            ...state.authLoading,
            signupUser: true
        }
    })),
    on(loadingActions.signupUserLoadingFinished, state => ({
        ...state,
        authLoading: {
            ...state.authLoading,
            signupUser: false
        }
    })),
    on(loadingActions.passwordResetLoading, state => ({
        ...state,
        authLoading: {
            ...state.authLoading,
            passwordReset: true
        }
    })),
    on(loadingActions.passwordResetLoadingFinished, state => ({
        ...state,
        authLoading: {
            ...state.authLoading,
            passwordReset: false
        }
    })),
    on(loadingActions.passwordResetInitiateLoading, state => ({
        ...state,
        authLoading: {
            ...state.authLoading,
            passwordResetInitiate: true
        }
    })),
    on(loadingActions.passwordResetInitiateLoadingFinished, state => ({
        ...state,
        authLoading: {
            ...state.authLoading,
            passwordResetInitiate: false
        }
    })),
    /**
     * Payment loading
     */
    on(loadingActions.loadSubscriptionsLoading, state => ({
        ...state,
        paymentLoading: {
            ...state.paymentLoading,
            loadSubscriptions: true
        }
    })),
    on(loadingActions.loadSubscriptionsLoadingFinished, state => ({
        ...state,
        paymentLoading: {
            ...state.paymentLoading,
            loadSubscriptions: false
        }
    })),
    on(loadingActions.newSubscriptionLoading, state => ({
        ...state,
        paymentLoading: {
            ...state.paymentLoading,
            newSubscription: true
        }
    })),
    on(loadingActions.newSubscriptionLoadingFinished, state => ({
        ...state,
        paymentLoading: {
            ...state.paymentLoading,
            newSubscription: false
        }
    })),
    on(loadingActions.addPaymentMethodLoading, state => ({
        ...state,
        paymentLoading: {
            ...state.paymentLoading,
            addPaymentMethod: true
        }
    })),
    on(loadingActions.addPaymentMethodLoadingFinished, state => ({
        ...state,
        paymentLoading: {
            ...state.paymentLoading,
            addPaymentMethod: false
        }
    })),
    on(loadingActions.loadPaymentMethodsLoading, state => ({
        ...state,
        paymentLoading: {
            ...state.paymentLoading,
            loadPaymentMethods: true
        }
    })),
    on(loadingActions.loadPaymentMethodsLoadingFinished, state => ({
        ...state,
        paymentLoading: {
            ...state.paymentLoading,
            loadPaymentMethods: false
        }
    })),
    on(loadingActions.updatePaymentMethodLoading, state => ({
        ...state,
        paymentLoading: {
            ...state.paymentLoading,
            updatePaymentMethod: true
        }
    })),
    on(loadingActions.updatePaymentMethodLoadingFinished, state => ({
        ...state,
        paymentLoading: {
            ...state.paymentLoading,
            updatePaymentMethod: false
        }
    })),
    on(loadingActions.deletePaymentMethodLoading, state => ({
        ...state,
        paymentLoading: {
            ...state.paymentLoading,
            deletePaymentMethod: true
        }
    })),
    on(loadingActions.deletePaymentMethodLoadingFinished, state => ({
        ...state,
        paymentLoading: {
            ...state.paymentLoading,
            deletePaymentMethod: false
        }
    })),
    on(loadingActions.cancelSubscriptionLoading, state => ({
        ...state,
        paymentLoading: {
            ...state.paymentLoading,
            cancelSubscription: true
        }
    })),
    on(loadingActions.cancelSubscriptionLoadingFinished, state => ({
        ...state,
        paymentLoading: {
            ...state.paymentLoading,
            cancelSubscription: false
        }
    })),
    on(loadingActions.loadPaymentsLoading, state => ({
        ...state,
        paymentLoading: {
            ...state.paymentLoading,
            cancelSubscription: true
        }
    })),
    on(loadingActions.loadPaymentsLoadingFinished, state => ({
        ...state,
        paymentLoading: {
            ...state.paymentLoading,
            cancelSubscription: false
        }
    })),
    /**
     * Comments loading
     */
    on(loadingActions.newCommentLoading, state => ({
        ...state,
        commentsLoading: {
            ...state.commentsLoading,
            newComment: true
        }
    })),
    on(loadingActions.newCommentLoadingFinished, state => ({
        ...state,
        commentsLoading: {
            ...state.commentsLoading,
            newComment: false
        }
    })),
    on(loadingActions.updateCommentLoading, state => ({
        ...state,
        commentsLoading: {
            ...state.commentsLoading,
            updateComment: true
        }
    })),
    on(loadingActions.updateCommentLoadingFinished, state => ({
        ...state,
        commentsLoading: {
            ...state.commentsLoading,
            updateComment: false
        }
    })),
    /**
     * Search loading
     */
    on(loadingActions.suggestedContentLoading, state => ({
        ...state,
        searchLoading: {
            ...state.searchLoading,
            suggestedContent: true
        }
    })),
    on(loadingActions.suggestedContentLoadingFinished, state => ({
        ...state,
        searchLoading: {
            ...state.searchLoading,
            suggestedContent: false
        }
    })),
    /**
     * Users loading
     */
    on(loadingActions.updateUserLoading, state => ({
        ...state,
        usersLoading: {
            ...state.usersLoading,
            updateUser: true
        }
    })),
    on(loadingActions.updateUserLoadingFinished, state => ({
        ...state,
        usersLoading: {
            ...state.usersLoading,
            updateUser: false
        }
    })),
    /**
     * Referral loading
     */
    on(loadingActions.trackProductSubscribeEventLoading, state => ({
        ...state,
        referralLoading: {
            ...state.referralLoading,
            trackProductSubscribeEvent: true
        }
    })),
    on(loadingActions.trackProductSubscribeEventLoadingFinished, state => ({
        ...state,
        referralLoading: {
            ...state.referralLoading,
            trackProductSubscribeEvent: false
        }
    })),
    /**
     * News loading
     */
    on(loadingActions.loadRecentNewsLoading, state => ({
        ...state,
        newsLoading: {
            ...state.newsLoading,
            loadRecentNews: true
        }
    })),
    on(loadingActions.loadRecentNewsLoadingFinished, state => ({
        ...state,
        newsLoading: {
            ...state.newsLoading,
            loadRecentNews: false
        }
    })),
    on(loadingActions.loadCategoryArticlesLoading, state => ({
        ...state,
        newsLoading: {
            ...state.newsLoading,
            loadCategoryArticles: true
        }
    })),
    on(loadingActions.loadCategoryArticlesLoadingFinished, state => ({
        ...state,
        newsLoading: {
            ...state.newsLoading,
            loadCategoryArticles: false
        }
    })),
    on(loadingActions.loadTrendingArticlesLoading, state => ({
        ...state,
        newsLoading: {
            ...state.newsLoading,
            loadTrendingArticles: true
        }
    })),
    on(loadingActions.loadTrendingArticlesLoadingFinished, state => ({
        ...state,
        newsLoading: {
            ...state.newsLoading,
            loadTrendingArticles: false
        }
    }))
);

