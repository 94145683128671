import { AuthEffects } from './auth.effects';
import { CacheEffects } from './cache.effects';
import { CloudEffects } from './cloud.effects';
import { CommentsEffects } from './comments.effects';
import { ContentEffects } from './content.effects';
import { CoursesEffects } from './courses.effects';
import { MetaEffects } from './meta.effects';
import { NewsEffects } from './news.effects';
import { NotificationsEffects } from './notifications.effects';
import { PageViewEffects } from './page-views.effects';
import { PaymentEffects } from './payment.effects';
import { PersistEffects } from './persist.effects';
import { PlayersEffects } from './players.effects';
import { ReferralEffects } from './referral.effects';
import { SearchEffects } from './search.effects';
import { TagsEffects } from './tags.effects';
import { UserEffects } from './user.effects';
import { VideoGuidesEffects } from './video-guides.effects';

export const EffectsProviders = [
    VideoGuidesEffects,
    ReferralEffects,
    AuthEffects,
    CoursesEffects,
    MetaEffects,
    CacheEffects,
    SearchEffects,
    NotificationsEffects,
    PaymentEffects,
    CommentsEffects,
    PlayersEffects,
    UserEffects,
    PersistEffects,
    NewsEffects,
    ContentEffects,
    CloudEffects,
    UserEffects,
    PageViewEffects,
    TagsEffects
];

