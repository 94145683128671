import { Location, NgClass, NgIf } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, takeWhile } from 'rxjs/operators';
import { Game } from '../../../../core/constants/game.enum';
import { SEOService } from '../../../../core/services/seo.service';
import { openSidenavMenu } from '../../../../store/actions/app.actions';
import { selectCurrentGame } from '../../../../store/selectors/persist.selectors';
import { selectIsLoggedIn } from '../../../../store/selectors/users.selectors';
import { GlobalState } from '../../../../store/store';
import { DashboardSearchAutocompleteComponent } from '../dashboard-search-autocomplete/dashboard-search-autocomplete.component';
import { DashboardHeaderDropdownComponent } from '../dashboard-header-dropdown/dashboard-header-dropdown.component';
import { DashboardNotificationsComponent } from '../dashboard-notifications/dashboard-notifications.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { DashboardNavigationComponent } from '../dashboard-navigation/dashboard-navigation.component';

@Component({
    selector: 'app-dashboard-header',
    templateUrl: './dashboard-header.component.html',
    styleUrls: ['./dashboard-header.component.scss'],
    standalone: true,
    imports: [NgClass, DashboardNavigationComponent, MatButtonModule, MatIconModule, DashboardSearchAutocompleteComponent, NgIf, DashboardNotificationsComponent, DashboardHeaderDropdownComponent]
})
export class DashboardHeaderComponent implements OnInit {
    private componentAlive: boolean = true;

    public isLoggedIn: boolean = false;
    public searchVisible: boolean = false;
    public searchPage: boolean = false;
    public currentGame: Game | null | undefined;

    @ViewChild('search', { static: true }) search: DashboardSearchAutocompleteComponent;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private seoService: SEOService,
        private location: Location,
        private store: Store<GlobalState>
    ) {}

    private setIsSearchPage() {
        const routeData: any = this.route.snapshot.firstChild?.data;

        this.searchPage = routeData?.searchPage;
    }

    public goBack() {
        this.location.back();
    }

    public toggleSearchVisible(visible?: boolean) {
        if (visible != undefined) {
            this.searchVisible = visible;
        } else {
            this.searchVisible = !this.searchVisible;
        }

        if (this.searchVisible) {
            this.search.focus();
        }
    }

    public openSidenavMenu() {
        this.store.dispatch(openSidenavMenu());
    }

    ngOnInit() {
        this.seoService.rawTitle$.pipe(takeWhile(() => this.componentAlive)).subscribe(title => {
            this.setIsSearchPage();
        });
        this.router.events
            .pipe(
                takeWhile(() => this.componentAlive),
                filter(evt => evt instanceof NavigationEnd)
            )
            .subscribe(evt => {
                this.setIsSearchPage();
            });
        this.store
            .select(selectIsLoggedIn)
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(isLoggedIn => {
                this.isLoggedIn = isLoggedIn;
            });
        this.store
            .select(selectCurrentGame)
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(currentGame => {
                this.currentGame = currentGame;
            });
    }

    ngOnDestroy() {
        this.componentAlive = false;
    }
}

