import { createFeatureSelector, createSelector } from '@ngrx/store';
import { notificationsAdapter, NotificationsState } from '../reducers/notifications.reducer';

const selectNotificationsState = createFeatureSelector<NotificationsState>('notifications');
const notificationsSelectors = notificationsAdapter.getSelectors();

export const selectNotificationsSubstate = createSelector(
    selectNotificationsState,
    (state: NotificationsState) => state.notifications
);
export const selectReceiverSubstate = createSelector(
    selectNotificationsState,
    (state: NotificationsState) => state.receiver
);
export const selectNotifications = createSelector(
    selectNotificationsSubstate,
    notificationsSelectors.selectAll
);
export const selectUnseenNotificationsPresent = createSelector(selectNotifications, notifications =>
    notifications.some(n => !n.seen)
);
export const selectUnreadNotificationsPresent = createSelector(selectNotifications, notifications =>
    notifications.some(n => !n.read)
);
export const selectNotificationsReceiver = createSelector(selectReceiverSubstate, receiver => receiver);

