import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { takeWhile } from 'rxjs/operators';
import { selectPromoBannerState } from '../../../../app/store/selectors/persist.selectors';
import { collapsePromoBanner, expandPromoBanner } from '../../../store/actions/persist.actions';
import { GlobalState } from '../../../store/store';
import { NgClass } from '@angular/common';

@Component({
    selector: 'header-banner',
    templateUrl: './header-banner.component.html',
    styleUrls: ['./header-banner.component.scss'],
    standalone: true,
    imports: [NgClass]
})
export class HeaderBannerComponent implements OnInit, OnDestroy {
    private componentAlive: boolean = true;
    public bannerExpanded: boolean = true;

    constructor(private store: Store<GlobalState>) {}

    public close() {
        this.store.dispatch(collapsePromoBanner());
    }

    public open() {
        this.store.dispatch(expandPromoBanner());
    }

    ngOnInit() {
        this.store
            .select(selectPromoBannerState)
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(expanded => {
                this.bannerExpanded = expanded;
            });
    }

    ngOnDestroy() {
        this.componentAlive = false;
    }
}
