import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Tag } from '../../models/tag.model';
import { CommonService } from '../../shared/services/common.service';
import { OWRank } from '../constants/game-constants/ranks';
import {
    dotaRankIconUrls,
    leagueRankIconUrls, owRankIconUrls
} from '../constants/game-constants/ranks-icons';
import { Game } from '../constants/game.enum';
import { TagType } from '../constants/tag-type.enum';

@Injectable()
export class GameCommonService {
    private searchContentByParamsSubject: Subject<any> = new Subject();
    public searchContentWithParams$: Observable<
        any
    > = this.searchContentByParamsSubject.asObservable();

    constructor(private commonService: CommonService) {}

    public getSmallBackgroundThumbnail(tag: Tag, game: Game): string {
        let path = '';

        if (tag) {
            let name: string = '';

            if (tag.type === TagType.GENERAL) {
                name = game === Game.VALORANT ? tag.slug : 'general';
            } else {
                name = game === Game.OVERWATCH2 ? tag.slug : tag.role;
            }

            path = `/assets/images/dashboard/${game}_${name}_wallpaper_small.jpg`;
        }

        return path;
    }

    public getDefaultSmallBackgroundThumbnail(game: Game) {
        return `/assets/images/dashboard/${game}_general_wallpaper_small.jpg`;
    }

    public searchContentWithParams() {
        this.searchContentByParamsSubject.next(true);
    }

    public getRoleIcon(role: string, game: Game, isActive: boolean = false) {
        let roleClass: string | null = null;
        switch (game) {
            case Game.OVERWATCH2:
                roleClass = role + (isActive ? ' active' : '');
                break;
            case Game.DOTA:
                roleClass = role + '-icon' + (isActive ? '-active' : '');
                break;

            case Game.LEAGUE:
                roleClass = game + '-' + role + '-icon' + (isActive ? '-active' : '');
                break;
            case Game.FORTNITE:
                roleClass = role + '-icon' + (isActive ? '-active' : '');
                break;
        }

        return roleClass;
    }

    public getRankIcon(
        { rating, rank }: { rating: number; rank: string },
        game: Game
    ): string | null {
        switch (game) {
            case Game.OVERWATCH2:
                if (rating < 1500) {
                    return owRankIconUrls[OWRank.BRONZE];
                } else if (rating >= 1500 && rating < 2000) {
                    return owRankIconUrls[OWRank.SILVER];
                } else if (rating >= 2000 && rating < 2500) {
                    return owRankIconUrls[OWRank.GOLD];
                } else if (rating >= 2500 && rating < 3000) {
                    return owRankIconUrls[OWRank.MASTER];
                } else if (rating >= 3000 && rating < 3500) {
                    return owRankIconUrls[OWRank.DIAMOND];
                } else if (rating >= 3500 && rating < 4000) {
                    return owRankIconUrls[OWRank.GRANDMASTER];
                } else if (rating >= 4000) {
                    return owRankIconUrls[OWRank.TOP_500];
                }

                break;
            case Game.DOTA:
                return dotaRankIconUrls[rank];

            case Game.LEAGUE:
                return leagueRankIconUrls[rank];
        }

        return null;
    }
}

