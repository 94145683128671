import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, finalize, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { ApiEndpoints, DashboardEndpoints } from '../../core/constants/ApiEndpoints';
import { MainHttp } from '../../core/services/main-http.service';
import { validatePromoCodeErrorReset } from '../actions/errors.actions';
import * as loadingActions from '../actions/loading.actions';
import * as referralActions from '../actions/referral.actions';
import { PayloadAction } from '../interfaces/payload-action.interface';
import { selectAccount } from '../selectors/users.selectors';
import { GlobalState } from '../store';

@Injectable()
export class ReferralEffects {
    constructor(
        private actions$: Actions,
        private http: MainHttp,
        private store: Store<GlobalState>
    ) {}

    /**
     * Validates a promo code in the backend.
     * @param action$
     */
    public validatePromoCode$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(
                referralActions.validateCheckoutPromoCode,
                referralActions.validateBannerPromoCode,
                referralActions.validateVisitorPromoCode
            ),
            tap(action => this.store.dispatch(validatePromoCodeErrorReset())),
            mergeMap(({ type, payload }) =>
                this.http.get(ApiEndpoints.validatePromoCode + payload.promoCode).pipe(
                    map(data => {
                        if (
                            !data.isValid &&
                            type === referralActions.ReferralActionsTypes.ValidateVisitorPromoCode
                        ) {
                            return referralActions.resetVisitorPromoCodeData();
                        }
                        return referralActions.validatePromoCodeSuccess({ payload: data });
                    }),
                    catchError(error =>
                        of(referralActions.validatePromoCodeFailure({ payload: error }))
                    )
                )
            )
        )
    );

    /**
     * Validates a promo campaign.
     * @param action$
     */
    public validateCampaign$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(referralActions.validateVisitorCampaign),
            mergeMap(({ payload }) =>
                this.http.get(ApiEndpoints.validateCampaign + payload.campaignId).pipe(
                    map(data =>
                        referralActions.validateCampaignSuccess({ payload: data.campaign })
                    ),
                    catchError(error =>
                        of(referralActions.validateCampaignFailure({ payload: error }))
                    )
                )
            )
        )
    );

    /**
     * Creates a new affiliate visit event.
     * @param action$
     */
    public trackVisit$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(referralActions.trackVisitEvent),
            mergeMap(({ payload }) =>
                this.http.post(ApiEndpoints.trackVisit, payload).pipe(
                    map(data => referralActions.trackVisitEventSuccess(data)),
                    catchError(error =>
                        of(referralActions.trackVisitEventFailure({ payload: error }))
                    )
                )
            )
        )
    );

    /**
     * Creates a new affiliate product subscribe event.
     * @param action$
     */
    public trackProductSubscribe$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(referralActions.trackProductSubscribeEvent),
            tap(() => this.store.dispatch(loadingActions.trackProductSubscribeEventLoading())),
            mergeMap(({ payload }) =>
                this.http.post(ApiEndpoints.trackProductSubscribe, payload).pipe(
                    map(data => referralActions.trackProductSubscribeEventSuccess(data)),
                    catchError(error =>
                        of(referralActions.trackProductSubscribeEventFailure({ payload: error }))
                    ),
                    finalize(() =>
                        this.store.dispatch(
                            loadingActions.trackProductSubscribeEventLoadingFinished()
                        )
                    )
                )
            )
        )
    );

    /**
     * Fetches earnings data for a particular affiliate.
     * @param action$
     */
    public loadAffiliateEarningsData$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(referralActions.loadAffiliateEarningsData),
            mergeMap(({ payload }) =>
                this.http
                    .get(ApiEndpoints.getAffiliateEarningsData + payload.affiliateId, payload.data)
                    .pipe(
                        map(data =>
                            referralActions.loadAffiliateEarningsDataSuccess({
                                payload: {
                                    [payload.data.currency]: data
                                }
                            })
                        ),
                        catchError(error =>
                            of(referralActions.loadAffiliateEarningsDataFailure({ payload: error }))
                        )
                    )
            )
        )
    );

    /**
     * Fetches affiliate events charts for an affiliate.
     * @param action$
     */
    public loadAffiliateEvents$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(referralActions.loadAffiliateEvents),
            mergeMap(({ payload }) =>
                this.http.get(ApiEndpoints.getAffiliateEvents, payload).pipe(
                    map(data =>
                        referralActions.loadAffiliateEventsSuccess({
                            payload: {
                                ...data,
                                eventKind: payload.eventKind
                            }
                        })
                    ),
                    catchError(error =>
                        of(referralActions.loadAffiliateEventsFailure({ payload: error }))
                    )
                )
            )
        )
    );

    /**
     * Fetches promo codes data for an affiliate.
     * @param action$
     */
    public loadAffiliatePromoCodes$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(referralActions.loadAffiliatePromoCodes),
            mergeMap(({ payload }) =>
                this.http.get(ApiEndpoints.getAffiliatePromoCodes, payload).pipe(
                    map(data => referralActions.loadAffiliatePromoCodesSuccess({ payload: data })),
                    catchError(error =>
                        of(referralActions.loadAffiliatePromoCodesFailure({ payload: error }))
                    )
                )
            )
        )
    );

    /**
     * Fetches affiliate payments charts for an affiliate.
     * @param action$
     */
    public loadAffiliatePayments$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(referralActions.loadAffiliatePayments),
            mergeMap(({ payload }) =>
                this.http.get(ApiEndpoints.getAffiliatePayments(payload.affiliateId), payload).pipe(
                    map(data =>
                        referralActions.loadAffiliatePaymentsSuccess({
                            payload: {
                                ...data,
                                paymentType: payload.paymentType
                            }
                        })
                    ),
                    catchError(error =>
                        of(referralActions.loadAffiliatePaymentsFailure({ payload: error }))
                    )
                )
            )
        )
    );

    /**
     * Fetches affiliate campaigns for a given promo code.
     * @param action$
     */
    public loadAffiliateCampaigns$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(referralActions.loadAffiliateCampaigns),
            mergeMap(({ payload }) =>
                this.http.get(ApiEndpoints.getAffiliateCampaigns, payload).pipe(
                    map(data => referralActions.loadAffiliateCampaignsSuccess({ payload: data })),
                    catchError(error =>
                        of(referralActions.loadAffiliateCampaignsFailure({ payload: error }))
                    )
                )
            )
        )
    );

    /**
     * Fetches all campaigns with a currently enabled banner or null if one does not exist.
     * @param action$
     */
    public loadEnabledBannerCampaigns$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(referralActions.loadEnabledBannerCampaigns),
            mergeMap(() =>
                this.http.get(ApiEndpoints.getEnabledBannerCampaigns).pipe(
                    map(data =>
                        referralActions.loadEnabledBannerCampaignsSuccess({ payload: data })
                    ),
                    catchError(error =>
                        of(referralActions.loadEnabledBannerCampaignsFailure({ payload: error }))
                    )
                )
            )
        )
    );
    /**
     *
     * Fetches all campaigns with a currently enabled modal or null if one does not exist.
     * @param action$
     */
    public loadEnabledModalCampaigns$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(referralActions.loadEnabledModalCampaigns),
            mergeMap(() =>
                this.http.get(ApiEndpoints.getEnabledModalCampaigns).pipe(
                    map(data =>
                        referralActions.loadEnabledModalCampaignsSuccess({ payload: data })
                    ),
                    catchError(error =>
                        of(referralActions.loadEnabledModalCampaignsFailure({ payload: error }))
                    )
                )
            )
        )
    );
    /**
     *
     * Fetches all active giveaways.
     * @param action$
     */
    public loadEnabledGiveaways$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(referralActions.loadEnabledGiveaways),
            mergeMap(() =>
                this.http.get(ApiEndpoints.getEnabledGiveaways).pipe(
                    map(data => referralActions.loadEnabledGiveawaysSuccess({ payload: data })),
                    catchError(error =>
                        of(referralActions.loadEnabledGiveawaysFailure({ payload: error }))
                    )
                )
            )
        )
    );
    /**
     *
     * Fetches all redeemed giveaways for the current user.
     * @param action$
     */
    public loadGiveawayRedemptions$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(referralActions.loadGiveawayRedemptions),
            withLatestFrom(this.store.select(selectAccount)),
            filter(([action, account]) => account != null),
            mergeMap(([{ payload }, { id }]) =>
                this.http.get(ApiEndpoints.getGiveawayRedemptions(id)).pipe(
                    map(data => referralActions.loadGiveawayRedemptionsSuccess({ payload: data })),
                    catchError(error =>
                        of(referralActions.loadGiveawayRedemptionsFailure({ payload: error }))
                    )
                )
            )
        )
    );
    /**
     *
     * Redeems a giveaway for the currently logged in user.
     * @param action$
     */
    public redeemGiveaway$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(
                referralActions.redeemGiveaway,
                referralActions.redeemGiveawayItem
            ),
            withLatestFrom(this.store.select(selectAccount)),
            filter(([action, account]) => account != null),
            mergeMap(([{ payload }, { id }]) =>
                this.http.post(ApiEndpoints.redeemGiveaway, payload).pipe(
                    map(data => referralActions.redeemGiveawaySuccess({ payload: data })),
                    catchError(error =>
                        of(referralActions.redeemGiveawayFailure({ payload: error }))
                    )
                )
            )
        )
    );

    /**
     * Fetches all active ad campaigns.
     * @param action$
     */
    public loadActiveAdCampaignsAdvertisements$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(referralActions.loadActiveAdCampaignsAdvertisements),
            tap(() =>
                this.store.dispatch(
                    referralActions.setLoadActiveAdCampaignsAdvertisementsComplete({
                        payload: false
                    })
                )
            ),
            mergeMap(() =>
                this.http.get(DashboardEndpoints.getActiveAdCampaignsAdvertisements).pipe(
                    map(data =>
                        referralActions.loadActiveAdCampaignsAdvertisementsSuccess({
                            payload: data
                        })
                    ),
                    catchError(error =>
                        of(
                            referralActions.loadActiveAdCampaignsAdvertisementsFailure({
                                payload: error
                            })
                        )
                    ),
                    finalize(() =>
                        this.store.dispatch(
                            referralActions.setLoadActiveAdCampaignsAdvertisementsComplete({
                                payload: true
                            })
                        )
                    )
                )
            )
        )
    );

    /**
     * Creates a new ad impression event.
     * @param action$
     */
    public trackAdImpressionEvent$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(referralActions.trackAdImpressionEvent),
            mergeMap(({ payload }) =>
                this.http.post(ApiEndpoints.newAdImpression, payload).pipe(
                    map(data => referralActions.trackAdImpressionEventSuccess(data)),
                    catchError(error =>
                        of(referralActions.trackAdImpressionEventFailure({ payload: error }))
                    )
                )
            )
        )
    );
    
    /**
     * Creates a new ad click event.
     * @param action$
     */
    public trackAdClickEvent$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(referralActions.trackAdClickEvent),
            mergeMap(({ payload }) =>
                this.http.post(ApiEndpoints.newAdClick, payload).pipe(
                    map(data => referralActions.trackAdClickEventSuccess(data)),
                    catchError(error =>
                        of(referralActions.trackAdClickEventFailure({ payload: error }))
                    )
                )
            )
        )
    );
}

