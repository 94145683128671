import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { Notification } from '../../models/notification.model';
import { Receiver } from '../../models/receiver.model';
import * as notificationsActions from '../actions/notifications.actions';

const selectId = (entity: any) => entity._id;

export const notificationsAdapter: EntityAdapter<Notification> = createEntityAdapter<Notification>({
    selectId,
    sortComparer: (a: Notification, b: Notification) => (a.createdAt < b.createdAt ? 1 : -1)
});
export interface NotificationsState {
    notifications: EntityState<Notification>;
    receiver: Receiver | null;
}

export const notificationsInitialState: NotificationsState = {
    notifications: notificationsAdapter.getInitialState(),
    receiver: null
};

export const notificationsReducer = createReducer(
    notificationsInitialState,
    on(notificationsActions.loadNotificationsSuccess, (state, { payload }) => ({
        ...state,
        notifications: notificationsAdapter.setAll(payload, state.notifications)
    })),
    on(notificationsActions.markNotificationsReadSuccess, (state, { payload }) => ({
        ...state,
        notifications:
            payload && payload.itemId
                ? notificationsAdapter.updateOne(
                      {
                          id: payload.itemId,
                          changes: { read: true }
                      },
                      state.notifications
                  )
                : notificationsAdapter.updateMany(
                      (state.notifications.ids as string[]).map(id => ({
                          id,
                          changes: { read: true }
                      })),
                      state.notifications
                  )
    })),
    on(notificationsActions.markNotificationsSeenSuccess, (state, { payload }) => ({
        ...state,
        notifications:
            payload && payload.itemId
                ? notificationsAdapter.updateOne(
                      {
                          id: payload.itemId,
                          changes: { seen: true }
                      },
                      state.notifications
                  )
                : notificationsAdapter.updateMany(
                      (state.notifications.ids as string[]).map(id => ({
                          id,
                          changes: { seen: true }
                      })),
                      state.notifications
                  )
    })),
    on(notificationsActions.loadNotificationPreferencesSuccess, (state, { payload }) => ({
        ...state,
        receiver: payload
    }))
);

