import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, finalize, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { ApiEndpoints } from '../../core/constants/ApiEndpoints';
import { MainHttp } from '../../core/services/main-http.service';
import { PayloadAction } from '../interfaces/payload-action.interface';
import { UserSubscription, UserSubscriptionProration } from '../../models/user-subscription.model';
import { UserSelectors } from '../selectors/user.selectors';
import { Customer } from '../../models/customer.model';
import { UserPayment } from '../../models/payment.model';
import { PaymentMethod } from '../../models/payment-method.model';
import { CurrencySettings } from '../../models/payment-settings.model';
import { GlobalState } from '../store';
import { newSubscriptionErrorReset } from '../actions/errors.actions';
import { resetPromoCodeData } from '../actions/referral.actions';
import { confirmLogin } from '../actions/auth.actions';
import { TrackService } from '../../core/services/track.service';
import { selectPromoCodeData } from '../selectors/referral.selectors';
import { AppSettings } from '../../core/constants/AppSettings';
import { selectCurrentGame } from '../selectors/persist.selectors';
import { newSubscriptionLoading, newSubscriptionLoadingFinished } from '../actions/loading.actions';

import * as paymentActions from '../actions/payment.actions';
import * as loadingActions from '../actions/loading.actions';

@Injectable()
export class PaymentEffects {
    constructor(
        private actions$: Actions,
        private http: MainHttp,
        private userSelectors: UserSelectors,
        private store: Store<GlobalState>,
        private router: Router,
        private trackService: TrackService
    ) {}

    /**
     * Fetches the stripe publishable key, which is used to initiate the stripe client.
     * @param action$
     */
    public loadStripePublishableKey$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(paymentActions.loadStripePublishableKey),
            mergeMap(({ payload }) =>
                this.http.get(ApiEndpoints.getStripePublishableKey).pipe(
                    map((data: { publishableKey: string }) =>
                        paymentActions.loadStripePublishableKeySuccess({ payload: data })
                    ),
                    catchError(error =>
                        of(
                            paymentActions.loadStripePublishableKeyFailure({
                                payload: error
                            })
                        )
                    )
                )
            )
        )
    );
    /**
     * Retrieves localized payment settings.
     * @param action$
     */
    public loadCurrencySettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(paymentActions.loadCurrencySettings),
            mergeMap(({ payload }) =>
                this.http.get(ApiEndpoints.loadCurrencySettings).pipe(
                    map((currencySettings: CurrencySettings) =>
                        paymentActions.loadCurrencySettingsSuccess({ payload: currencySettings })
                    ),
                    catchError(error =>
                        of(
                            paymentActions.loadCurrencySettingsFailure({
                                payload: error
                            })
                        )
                    )
                )
            )
        )
    );

    public newSubscriptionLoading$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(newSubscriptionLoading),
                tap(() =>
                    this.router.navigate(['/signup/checkout/processing'], {
                        replaceUrl: true,
                        skipLocationChange: true
                    })
                )
            ),
        {
            dispatch: false
        }
    );

    /**
     * Creates a new Stripe subscription.
     * @param action$
     */
    public newStripeSubscription$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(paymentActions.newStripeSubscription),
            tap(() => this.store.dispatch(newSubscriptionErrorReset())),
            tap(() => this.store.dispatch(newSubscriptionLoading())),
            mergeMap(({ payload }) =>
                this.http.post(ApiEndpoints.createNewStripeSubscription, payload).pipe(
                    map((subData: { sub: UserSubscription; sourceSub: any }) =>
                        paymentActions.newStripeSubscriptionSuccess({ payload: subData })
                    ),
                    catchError(error =>
                        of(
                            paymentActions.newSubscriptionFailure({
                                payload: error
                            })
                        )
                    ),
                    finalize(() => this.store.dispatch(newSubscriptionLoadingFinished()))
                )
            )
        )
    );

    public newSubscriptionSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType<PayloadAction>(paymentActions.newSubscriptionSuccess),
                tap(() => this.store.dispatch(resetPromoCodeData())),
                tap(() => this.store.dispatch(confirmLogin({ payload: { reIssueToken: true } }))),
                tap(() =>
                    // we do this again with a delay because lifetime subs are updated in a webhook from
                    setTimeout(
                        () =>
                            this.store.dispatch(confirmLogin({ payload: { reIssueToken: true } })),
                        5000
                    )
                ),
                tap(({ payload }) =>
                    this.router.navigate(['/signup/checkout/success'], {
                        queryParams: {
                            game: payload.game
                        }
                    })
                ),
                withLatestFrom(
                    this.store.select(selectPromoCodeData),
                    this.store.select(selectCurrentGame)
                ),
                tap(([{ payload }, promoCodeData, currentGame]) => {
                    const selectedPlan = AppSettings.SUBSCRIPTION_PLANS.find(
                        plan => plan.type === payload.plan
                    );
                    if (selectedPlan) {
                        this.trackService.trackSubscribeEvent({
                            price: selectedPlan.totalPrice,
                            planName: selectedPlan.name,
                            planDuration: selectedPlan.duration,
                            promoCode: promoCodeData?.code,
                            promoCodeDuration: promoCodeData?.discount?.duration,
                            currentGame
                        });
                    }
                })
            ),
        {
            dispatch: false
        }
    );

    public newSubscriptionError$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType<PayloadAction>(paymentActions.newSubscriptionFailure),
                tap(() => this.router.navigate(['/signup/checkout/error']))
            ),
        {
            dispatch: false
        }
    );

    /**
     * Creates a new Braintree subscription.
     * @param action$
     */
    public newBraintreeSubscription$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(paymentActions.newBraintreeSubscription),
            tap(() => this.store.dispatch(newSubscriptionErrorReset())),
            tap(() => this.store.dispatch(newSubscriptionLoading())),
            mergeMap(({ payload }) =>
                this.http.post(ApiEndpoints.createNewBraintreeSubscription, payload).pipe(
                    map((subscription: UserSubscription) =>
                        paymentActions.newSubscriptionSuccess({ payload: subscription })
                    ),
                    catchError(error =>
                        of(
                            paymentActions.newSubscriptionFailure({
                                payload: error
                            })
                        )
                    ),
                    finalize(() => this.store.dispatch(newSubscriptionLoadingFinished()))
                )
            )
        )
    );

    /**
     * Retries to complete the payment for a Stripe subscription in the 'incomplete' status.
     * Will only occur for 23:59 hours after the first attempted payment has failed to obtain authentication.
     * @param action$
     */
    public retryStripeSubscriptionPayment$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(paymentActions.retryStripeSubscriptionPayment),
            tap(() => this.store.dispatch(newSubscriptionErrorReset())),
            tap(() => this.store.dispatch(newSubscriptionLoading())),
            // tap(() => this.paymentActions.newSubscriptionSetProcessing(true)),
            mergeMap(({ payload }) =>
                this.http
                    .post(
                        ApiEndpoints.retryStripeSubscriptionPayment(payload.subscriptionId),
                        payload.data
                    )
                    .pipe(
                        map(
                            (subData: {
                                sub: UserSubscription;
                                sourceSub: any;
                                paymentMethodId: string;
                            }) =>
                                paymentActions.retryStripeSubscriptionPaymentSuccess({
                                    payload: subData
                                })
                        ),
                        catchError(error =>
                            of(
                                paymentActions.newSubscriptionFailure({
                                    payload: error
                                })
                            )
                        ),
                        finalize(() => this.store.dispatch(newSubscriptionLoadingFinished()))
                    )
            )
        )
    );

    /**
     * Gets the subscription for the current user.
     * @param action$
     */
    public loadCustomers$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(paymentActions.loadCustomers),
            // tap(() => this.paymentActions.userCustomersGetLoadingChanged(true)),
            withLatestFrom(this.userSelectors.account$),
            mergeMap(([{ payload }, { id }]) => {
                return this.http.get(ApiEndpoints.getUserCustomers(id)).pipe(
                    map((customers: Customer[]) =>
                        paymentActions.loadCustomersSuccess({
                            payload: customers
                        })
                    ),
                    catchError(error =>
                        of(
                            paymentActions.loadCustomersFailure({
                                payload: error
                            })
                        )
                    )
                    // finalize(() => this.paymentActions.userPaymentMethodsGetLoadingChanged(false))
                );
            })
        )
    );

    /**
     * Gets the subscription for the current user.
     * @param action$
     */
    public loadSubscriptions$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(paymentActions.loadSubscriptions),
            tap(() => this.store.dispatch(loadingActions.loadSubscriptionsLoading())),
            withLatestFrom(this.userSelectors.account$),
            filter(([{ payload }, account]) => account != null),
            mergeMap(([{ payload }, { id }]) => {
                return this.http.get(ApiEndpoints.getUserSubscriptions(id)).pipe(
                    map((subscriptions: UserSubscription[]) =>
                        paymentActions.loadSubscriptionsSuccess({ payload: subscriptions })
                    ),
                    catchError(error =>
                        of(
                            paymentActions.loadSubscriptionsFailure({
                                payload: error
                            })
                        )
                    ),
                    finalize(() =>
                        this.store.dispatch(loadingActions.loadSubscriptionsLoadingFinished())
                    )
                );
            })
        )
    );

    /**
     * Gets all payments for the current user.
     * @param action$
     */
    public loadPayments$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(paymentActions.loadPayments),
            tap(() => this.store.dispatch(loadingActions.loadPaymentsLoading())),

            withLatestFrom(this.userSelectors.account$),
            mergeMap(([{ payload }, { id }]) => {
                return this.http.get(ApiEndpoints.getUserPayments(id)).pipe(
                    map((payments: UserPayment[]) =>
                        paymentActions.loadPaymentsSuccess({ payload: payments })
                    ),
                    catchError(error =>
                        of(
                            paymentActions.loadPaymentsFailure({
                                payload: error
                            })
                        )
                    ),
                    finalize(() =>
                        this.store.dispatch(loadingActions.loadPaymentsLoadingFinished())
                    )
                );
            })
        )
    );

    /**
     * Gets all payment methods for the current user.
     * @param action$
     */
    public loadPaymentMethods = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(paymentActions.loadPaymentMethods),
            tap(() => this.store.dispatch(loadingActions.loadPaymentMethodsLoading())),
            withLatestFrom(this.userSelectors.account$),
            mergeMap(([{ payload }, { id }]) => {
                return this.http.get(ApiEndpoints.getUserPaymentMethods(id)).pipe(
                    map((paymentMethods: PaymentMethod[]) =>
                        paymentActions.loadPaymentMethodsSuccess({ payload: paymentMethods })
                    ),
                    catchError(error =>
                        of(
                            paymentActions.loadPaymentMethodsFailure({
                                payload: error
                            })
                        )
                    ),
                    finalize(() =>
                        this.store.dispatch(loadingActions.loadPaymentMethodsLoadingFinished())
                    )
                );
            })
        )
    );

    /**
     * Creates a new payment method.
     * @param action$
     */
    public addPaymentMethod$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(paymentActions.addPaymentMethod),
            tap(() => this.store.dispatch(loadingActions.addPaymentMethodLoading())),
            withLatestFrom(this.userSelectors.account$),
            mergeMap(([{ payload }, { id }]) => {
                return this.http.post(ApiEndpoints.addUserPaymentMethod(id), payload).pipe(
                    map((paymentMethod: PaymentMethod) =>
                        paymentActions.addPaymentMethodSuccess({ payload: paymentMethod })
                    ),
                    catchError(error =>
                        of(
                            paymentActions.addPaymentMethodFailure({
                                payload: error
                            })
                        )
                    ),
                    finalize(() =>
                        this.store.dispatch(loadingActions.addPaymentMethodLoadingFinished())
                    )
                );
            })
        )
    );

    /**
     * Updates an existing payment method.
     * @param action$
     */
    public updatePaymentMethod$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(paymentActions.updatePaymentMethod),
            tap(() => this.store.dispatch(loadingActions.updatePaymentMethodLoading())),
            withLatestFrom(this.userSelectors.account$),
            mergeMap(([{ payload }, { id }]) => {
                const path = ApiEndpoints.updateUserPaymentMethod(payload.paymentMethodId, id);
                return this.http.put(path, payload.data).pipe(
                    map((paymentMethod: PaymentMethod) =>
                        paymentActions.updatePaymentMethodSuccess({ payload: paymentMethod })
                    ),
                    catchError(error =>
                        of(
                            paymentActions.updatePaymentMethodFailure({
                                payload: error
                            })
                        )
                    ),
                    finalize(() =>
                        this.store.dispatch(loadingActions.updatePaymentMethodLoadingFinished())
                    )
                );
            })
        )
    );

    /**
     * Removes a payment method.
     * @param action$
     */
    public removePaymentMethod$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(paymentActions.removePaymentMethod),
            tap(() => this.store.dispatch(loadingActions.deletePaymentMethodLoading())),
            withLatestFrom(this.userSelectors.account$),
            mergeMap(([{ payload }, { id }]) => {
                const path = ApiEndpoints.removeUserPaymentMethod(payload.paymentMethodId, id);
                return this.http.delete(path).pipe(
                    map((paymentMethod: PaymentMethod) =>
                        paymentActions.removePaymentMethodSuccess({ payload: paymentMethod })
                    ),
                    catchError(error =>
                        of(
                            paymentActions.removePaymentMethodFailure({
                                payload: error
                            })
                        )
                    ),
                    finalize(() =>
                        this.store.dispatch(loadingActions.deletePaymentMethodLoadingFinished())
                    )
                );
            })
        )
    );

    /**
     * Cancels a user subscription.
     * @param action$
     */
    public cancelSubscription$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(paymentActions.cancelSubscription),
            tap(() => this.store.dispatch(loadingActions.cancelSubscriptionLoading())),
            withLatestFrom(this.userSelectors.account$),
            mergeMap(([{ payload }, { id }]) => {
                return this.http
                    .post(ApiEndpoints.cancelUserSubscription(payload.subscriptionId, id), {
                        feedback: payload.feedback || null
                    })
                    .pipe(
                        map((subscription: UserSubscription) =>
                            paymentActions.cancelSubscriptionSuccess({ payload: subscription })
                        ),
                        catchError(error =>
                            of(
                                paymentActions.cancelSubscriptionFailure({
                                    payload: error
                                })
                            )
                        ),
                        finalize(() =>
                            this.store.dispatch(loadingActions.cancelSubscriptionLoadingFinished())
                        )
                    );
            })
        )
    );

    /**
     * Retrieves proration data for a subscription.
     * Used when a customer requests a plan change (currently Stripe only).
     * @param action$
     */
    public loadSubscriptionProration$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(paymentActions.loadSubscriptionProration),
            // tap(() => this.paymentActions.userSubscriptionProrationGetLoadingChanged(true)),
            withLatestFrom(this.userSelectors.account$),
            mergeMap(([{ payload }, { id }]) => {
                const path = ApiEndpoints.getUserSubscriptionProration(payload.subscriptionId, id);
                return this.http.get(path, payload.data).pipe(
                    map((proration: UserSubscriptionProration) =>
                        paymentActions.loadSubscriptionProrationSuccess({ payload: proration })
                    ),
                    catchError(error =>
                        of(
                            paymentActions.loadSubscriptionProrationFailure({
                                payload: error
                            })
                        )
                    )
                    // finalize(() =>
                    //     this.paymentActions.userSubscriptionProrationGetLoadingChanged(false)
                    // )
                );
            })
        )
    );

    /**
     * Changes the plan, price and billing cycle of a subscription.
     * Currently Stripe only.
     * @param action$
     */
    public changeSubscriptionPlan$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(paymentActions.changeSubscriptionPlan),
            // tap(() => this.paymentActions.userSubscriptionPlanChangeLoadingChanged(true)),
            withLatestFrom(this.userSelectors.account$),
            mergeMap(([{ payload }, { id }]) => {
                const path = ApiEndpoints.changeUserSubscriptionPlan(payload.subscriptionId, id);
                return this.http.post(path, payload.data).pipe(
                    map((subscription: UserSubscription) =>
                        paymentActions.changeSubscriptionPlanSuccess({ payload: subscription })
                    ),
                    catchError(error =>
                        of(
                            paymentActions.changeSubscriptionPlanFailure({
                                payload: error
                            })
                        )
                    )
                    // finalize(() => this.paymentActions.userSubscriptionPlanChangeLoadingChanged(false))
                );
            })
        )
    );
}

