import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, filter, map, tap, mergeMap } from 'rxjs/operators';
import { GameAwareHttp } from '../../core/services/game-aware-http.service';
import { DashboardEndpoints } from '../../core/constants/ApiEndpoints';
import { GlobalState } from '../store';
import { Player } from '../../models/player.model';
import { concatLatestFrom } from '../../core/operators/concat-latest-from';

import * as cacheSelectors from '../selectors/cache.selectors';
import * as cacheActions from '../actions/cache.actions';
import * as playersActions from '../actions/players.actions';

@Injectable()
export class PlayersEffects {
    constructor(
        private actions$: Actions,
        private http: GameAwareHttp,
        private store: Store<GlobalState>
    ) {}

    loadFeaturedPlayers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(playersActions.loadFeaturedPlayers),
            concatLatestFrom(action => this.store.select(cacheSelectors.selectFeaturedPlayersEtag)),
            tap(() => this.store.dispatch(cacheActions.loadFeaturedPlayersEtag())),
            concatMap(([action, curEtag]) =>
                curEtag
                    ? this.actions$.pipe(
                          ofType(cacheActions.loadFeaturedPlayersEtagSuccess),
                          map(cacheAction => [action, cacheAction.payload === curEtag])
                      )
                    : of([action, false])
            ),
            filter(([action, etagLatest]) => etagLatest === false),
            mergeMap(() =>
                this.http.get(DashboardEndpoints.getFeaturedPlayers).pipe(
                    map((featuredPlayers: Player[]) =>
                        playersActions.loadFeaturedPlayersSuccess({ payload: featuredPlayers })
                    ),
                    catchError(error =>
                        of(
                            playersActions.loadFeaturedPlayersFailure({
                                payload: error
                            })
                        )
                    )
                )
            )
        )
    );
}
