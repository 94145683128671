import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, map, take } from 'rxjs';
import { selectCurrentGame } from '../../store/selectors/persist.selectors';
import { GlobalState } from '../../store/store';

@Injectable()
export class EmptyDashboardGameSetGuard {
    constructor(private store: Store<GlobalState>, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.store.select(selectCurrentGame).pipe(
            take(1),
            map(game => {
                if (game != null) {
                    this.router.navigate([route.url, game]);
                    return false;
                }

                return true;
            })
        );
    }
}

