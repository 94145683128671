import { isPlatformServer } from '@angular/common';
import { Component, Inject, OnInit, Optional, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { L10nTranslateDirective } from 'angular-l10n';
import { Response } from 'express';
import { RESPONSE } from '../../../models/server.tokens';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss'],
    standalone: true,
    imports: [L10nTranslateDirective]
})
export class NotFoundComponent implements OnInit {
    constructor(
        @Optional() @Inject(RESPONSE) private response: Response,
        private router: Router,
        @Inject(PLATFORM_ID) private platformId: Object
    ) {}

    goHome() {
        this.router.navigate(['/']);
    }
    ngOnInit() {
        if (isPlatformServer(this.platformId)) {
            this.response.status(404);
        }
    }
}

