import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
    catchError,
    concatMap,
    filter,
    finalize,
    map,
    mergeMap,
    take,
    tap,
    withLatestFrom
} from 'rxjs/operators';
import { DashboardEndpoints } from '../../core/constants/ApiEndpoints';
import { concatLatestFrom } from '../../core/operators/concat-latest-from';
import { VideoGuide } from '../../models/video-guide.model';
import { PayloadAction } from '../interfaces/payload-action.interface';
import { GlobalState } from '../store';

import { GameAwareHttp } from '../../core/services/game-aware-http.service';
import { MainHttp } from '../../core/services/main-http.service';
import * as cacheActions from '../actions/cache.actions';
import * as loadingActions from '../actions/loading.actions';
import * as videoGuidesActions from '../actions/video-guides.actions';
import * as cacheSelectors from '../selectors/cache.selectors';
import { selectAccount } from '../selectors/users.selectors';

@Injectable()
export class VideoGuidesEffects {
    constructor(
        private actions$: Actions,
        private http: GameAwareHttp,
        private mainHttp: MainHttp,
        private store: Store<GlobalState>
    ) {}

    public loadRecentVideoGuides$ = createEffect(() =>
        this.actions$.pipe(
            ofType(videoGuidesActions.loadRecentVideoGuides),
            concatLatestFrom(action =>
                this.store.select(cacheSelectors.selectRecentVideoGuidesEtag)
            ),
            tap(() => this.store.dispatch(cacheActions.loadRecentVideoGuidesEtag())),
            concatMap(([action, curEtag]) =>
                curEtag
                    ? this.actions$.pipe(
                          ofType(cacheActions.loadRecentVideoGuidesEtagSuccess),
                          take(1),
                          map(cacheAction => [action, cacheAction.payload === curEtag])
                      )
                    : of([action, false])
            ),
            filter(([action, etagLatest]) => etagLatest === false),
            map(([action]) => action),
            tap(() => this.store.dispatch(loadingActions.recentVideoGuidesLoading())),
            mergeMap(action =>
                this.mainHttp.get(DashboardEndpoints.getRecentVideoGuides).pipe(
                    map((recentVideoGuides: VideoGuide[]) =>
                        videoGuidesActions.loadRecentVideoGuidesSuccess({
                            payload: recentVideoGuides
                        })
                    ),
                    catchError(error =>
                        of(
                            videoGuidesActions.loadRecentVideoGuidesFailure({
                                payload: error
                            })
                        )
                    ),
                    finalize(() =>
                        this.store.dispatch(loadingActions.recentVideoGuidesLoadingFinished())
                    )
                )
            )
        )
    );

    public loadFeaturedVideoGuides$ = createEffect(() =>
        this.actions$.pipe(
            ofType(videoGuidesActions.loadFeaturedVideoGuides),
            concatLatestFrom(action =>
                this.store.select(cacheSelectors.selectFeaturedVideoGuidesEtag)
            ),
            tap(() => this.store.dispatch(cacheActions.loadFeaturedVideoGuidesEtag())),
            concatMap(([action, curEtag]) =>
                curEtag
                    ? this.actions$.pipe(
                          ofType(cacheActions.loadFeaturedVideoGuidesEtagSuccess),
                          take(1),
                          map(cacheAction => [action, cacheAction.payload === curEtag])
                      )
                    : of([action, false])
            ),
            filter(([action, etagLatest]) => etagLatest === false),
            map(([action]) => action),
            // tap(() => this.store.dispatch(loadingActions.recentVideoGuidesLoading())),
            mergeMap(action =>
                this.http.get(DashboardEndpoints.getFeaturedVideoGuides).pipe(
                    map((recentVideoGuides: VideoGuide[]) =>
                        videoGuidesActions.loadFeaturedVideoGuidesSuccess({
                            payload: recentVideoGuides
                        })
                    ),
                    catchError(error =>
                        of(
                            videoGuidesActions.loadFeaturedVideoGuidesFailure({
                                payload: error
                            })
                        )
                    )
                    // finalize(() =>
                    //     this.store.dispatch(loadingActions.recentVideoGuidesLoadingFinished())
                    // )
                )
            )
        )
    );

    public searchVideoGuides$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(videoGuidesActions.searchVideoGuides),
            concatLatestFrom(action =>
                this.store.select(cacheSelectors.selectSearchVideoGuidesEtag)
            ),
            tap(([action]) =>
                this.store.dispatch(
                    cacheActions.loadSearchVideoGuidesEtag({ payload: action.payload })
                )
            ),
            concatMap(([action, curEtag]) =>
                curEtag
                    ? this.actions$.pipe(
                          ofType(cacheActions.loadSearchVideoGuidesEtagSuccess),
                          take(1),
                          map(cacheAction => [action, cacheAction.payload === curEtag])
                      )
                    : of([action, false])
            ),
            filter(([action, etagLatest]) => etagLatest === false),
            map(([action]): PayloadAction => action as PayloadAction),
            tap(() => this.store.dispatch(loadingActions.searchVideoGuidesLoading())),
            mergeMap(({ payload }) =>
                this.http.get(DashboardEndpoints.searchVideoGuides, payload).pipe(
                    map((response: { videoGuides: VideoGuide[]; hasReachedBottom: boolean }) =>
                        videoGuidesActions.searchVideoGuidesSuccess({
                            payload: {
                                videoGuides: response.videoGuides,
                                hasReachedBottom: response.hasReachedBottom
                            }
                        })
                    ),
                    catchError(error =>
                        of(
                            videoGuidesActions.searchVideoGuidesFailure({
                                payload: error
                            })
                        )
                    ),
                    finalize(() =>
                        this.store.dispatch(loadingActions.searchVideoGuidesLoadingFinished())
                    )
                )
            )
        )
    );

    /**
     * Creates a new playback for a video guide.
     * @param action$
     * @returns {Observable<any>}
     */
    public newPlayback$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(videoGuidesActions.newPlayback),
            withLatestFrom(this.store.select(selectAccount)),
            mergeMap(([{ payload }, account]) => {
                return this.http
                    .post(DashboardEndpoints.newPlayback, {
                        ...payload,
                        userId: account != null ? account.id : null
                    })
                    .pipe(
                        map((playback: any) =>
                            videoGuidesActions.newPlaybackSuccess({ payload: playback })
                        ),
                        catchError(error =>
                            of(
                                videoGuidesActions.newPlaybackFailure({
                                    payload: error
                                })
                            )
                        )
                    );
            })
        )
    );

    /**
     * Updates playback for a video guide.
     * @param action$
     * @returns {Observable<any>}
     */
    public updatePlayback$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(videoGuidesActions.updatePlayback),
            mergeMap(({ payload }) => {
                return this.http
                    .put(DashboardEndpoints.updatePlayback(payload.playbackId), payload.data)
                    .pipe(
                        map((playback: any) =>
                            videoGuidesActions.updatePlaybackSuccess({ payload: playback })
                        ),
                        catchError(error =>
                            of(
                                videoGuidesActions.newPlaybackFailure({
                                    payload: error
                                })
                            )
                        )
                    );
            })
        )
    );
}

