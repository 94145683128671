import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectCurrentGame } from '../../store/selectors/persist.selectors';
import { GlobalState } from '../../store/store';
import { Game } from '../constants/game.enum';
import { IHttp } from '../interfaces/http.interface';
import { BaseHttp } from './base-http';

@Injectable()
export class GameAwareHttp extends BaseHttp implements IHttp {
    GAME: Game | null | undefined;

    constructor(http: HttpClient, private store: Store<GlobalState>) {
        super(http);
        store.select(selectCurrentGame).subscribe(game => (this.GAME = game));
    }
}
