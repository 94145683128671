import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { LocalStorageService } from '../../core/services/local-storage.service';
import {
    setAuthToken,
    setRefreshToken
} from '../actions/persist.actions';
import { PayloadAction } from '../interfaces/payload-action.interface';
import { GlobalState } from '../store';

@Injectable()
export class PersistEffects {
    constructor(
        private actions$: Actions,
        private localStorage: LocalStorageService,
        private store: Store<GlobalState>
    ) {}

    /**
     * Persists auth token in localStorage.
     * @param action$
     */
    setAuthToken$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType<PayloadAction>(setAuthToken),
                tap(({ payload }) => this.localStorage.setItem('authToken', payload))
            ),
        {
            dispatch: false
        }
    );

    /**
     * Persists refresh token in localStorage.
     * @param action$
     */
    setRefreshToken$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType<PayloadAction>(setRefreshToken),
                tap(({ payload }) => this.localStorage.setItem('refreshToken', payload))
            ),
        {
            dispatch: false
        }
    );

    // /**
    //  * If the current game has changed, dispatch content delete action.
    //  * @param action$
    //  */
    // setCurrentGame$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType<PayloadAction>(setCurrentGame),
    //         withLatestFrom(
    //             this.store.select(selectCurrentGame).pipe(
    //                 distinctUntilChanged(),
    //                 startWith(null),
    //                 pairwise(),
    //                 filter(([prevGame]) => prevGame !== null),
    //                 distinctUntilChanged(([prevGame, curGame]) => prevGame !== curGame)
    //             )
    //         ),
    //         map(data => {
    //             console.log('Games have changed and are different!');
    //             console.log('data', data[1]);
    //             return resetDashboardContent();
    //         })
    //     )
    // );
}

