import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { DashboardEndpoints } from '../../core/constants/ApiEndpoints';
import { GameAwareHttp } from '../../core/services/game-aware-http.service';
import { MainHttp } from '../../core/services/main-http.service';
import * as cacheActions from '../actions/cache.actions';
import { PayloadAction } from '../interfaces/payload-action.interface';
import { UserSelectors } from '../selectors/user.selectors';
import { selectAccount } from '../selectors/users.selectors';
import { GlobalState } from '../store';

@Injectable()
export class CacheEffects {
    constructor(
        private actions$: Actions,
        private http: MainHttp,
        private gameAwareHttp: GameAwareHttp,
        private userSelectors: UserSelectors,
        private store: Store<GlobalState>
    ) {}

    private getETagValue(response: Response) {
        let etag = response.headers.get('etag') || '';
        etag = etag.replace('"', '');
        etag = etag.replace('"', '');

        return etag;
    }

    loadFeaturedCoursesEtag$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(cacheActions.loadFeaturedCoursesEtag),
            mergeMap(({ payload }) =>
                this.http
                    .head(DashboardEndpoints.getFeaturedCourses, payload, { observe: 'response' })
                    .pipe(
                        map((response: Response) =>
                            cacheActions.loadFeaturedCoursesEtagSuccess({
                                payload: this.getETagValue(response)
                            })
                        ),
                        catchError(error =>
                            of(cacheActions.loadFeaturedCoursesEtagFailure({ payload: error }))
                        )
                    )
            )
        )
    );

    loadCoursesEtag$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(cacheActions.loadCoursesEtag),
            mergeMap(({ payload }) =>
                this.http
                    .head(DashboardEndpoints.getCourses, payload, { observe: 'response' })
                    .pipe(
                        map((response: Response) =>
                            cacheActions.loadCoursesEtagSuccess({
                                payload: this.getETagValue(response)
                            })
                        ),
                        catchError(error =>
                            of(cacheActions.loadCoursesEtagFailure({ payload: error }))
                        )
                    )
            )
        )
    );

    loadEnrolledCoursesEtag$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(cacheActions.loadEnrolledCoursesEtag),
            withLatestFrom(this.userSelectors.account$),
            mergeMap(([{ payload }, { id }]) =>
                this.http
                    .head(DashboardEndpoints.getEnrolledCourses(id), payload, {
                        observe: 'response'
                    })
                    .pipe(
                        map((response: Response) =>
                            cacheActions.loadEnrolledCoursesEtagSuccess({
                                payload: this.getETagValue(response)
                            })
                        ),
                        catchError(error =>
                            of(cacheActions.loadEnrolledCoursesEtagFailure({ payload: error }))
                        )
                    )
            )
        )
    );

    loadCourseEtag$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(cacheActions.loadCourseEtag),
            filter(({ payload }) => payload.courseId != null),
            mergeMap(({ payload }) =>
                this.http
                    .head(
                        DashboardEndpoints.getCourse(payload.courseId),
                        {},
                        {
                            observe: 'response'
                        }
                    )
                    .pipe(
                        map((response: Response) =>
                            cacheActions.loadCourseEtagSuccess({
                                payload: {
                                    courseId: payload.courseId,
                                    etag: this.getETagValue(response)
                                }
                            })
                        ),
                        catchError(error =>
                            of(cacheActions.loadCourseEtagFailure({ payload: error }))
                        )
                    )
            )
        )
    );

    loadTagsEtag$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(cacheActions.loadTagsEtag),
            mergeMap(({ payload }) =>
                this.http.head(DashboardEndpoints.getTags, payload, { observe: 'response' }).pipe(
                    map((response: Response) =>
                        cacheActions.loadTagsEtagSuccess({
                            payload: this.getETagValue(response)
                        })
                    ),
                    catchError(error => of(cacheActions.loadTagsEtagFailure({ payload: error })))
                )
            )
        )
    );

    loadRecentVideoGuidesEtag$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(cacheActions.loadRecentVideoGuidesEtag),
            mergeMap(({ payload }) =>
                this.http
                    .head(DashboardEndpoints.getRecentVideoGuides, payload, {
                        observe: 'response'
                    })
                    .pipe(
                        map((response: Response) =>
                            cacheActions.loadRecentVideoGuidesEtagSuccess({
                                payload: this.getETagValue(response)
                            })
                        ),
                        catchError(error =>
                            of(cacheActions.loadRecentVideoGuidesEtagFailure({ payload: error }))
                        )
                    )
            )
        )
    );

    loadSearchVideoGuidesEtag$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(cacheActions.loadSearchVideoGuidesEtag),
            mergeMap(({ payload }) =>
                this.gameAwareHttp
                    .head(DashboardEndpoints.searchVideoGuides, payload, {
                        observe: 'response'
                    })
                    .pipe(
                        map((response: Response) =>
                            cacheActions.loadSearchVideoGuidesEtagSuccess({
                                payload: this.getETagValue(response)
                            })
                        ),
                        catchError(error =>
                            of(cacheActions.loadSearchVideoGuidesEtagFailure({ payload: error }))
                        )
                    )
            )
        )
    );

    loadMetadataEtag$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(cacheActions.loadMetadataEtag),
            withLatestFrom(this.store.select(selectAccount)),
            filter(([action, account]) => account != null),
            mergeMap(([{ payload }, { id }]) =>
                this.http
                    .head(DashboardEndpoints.getMetaData(id), payload, {
                        observe: 'response'
                    })
                    .pipe(
                        map((response: Response) =>
                            cacheActions.loadMetadataEtagSuccess({
                                payload: this.getETagValue(response)
                            })
                        ),
                        catchError(error =>
                            of(cacheActions.loadMetadataEtagFailure({ payload: error }))
                        )
                    )
            )
        )
    );
}

