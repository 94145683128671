import { createAction } from '@ngrx/store';

export enum AuthErrorActionTypes {
    LoginErrorReset = '[Auth Effects] Login Error Reset',
    SignupErrorReset = '[Auth Effects] Signup Error Reset',
    PasswordResetErrorReset = '[Auth Effects] Password Reset Error Reset',
    ValidatePromoCodeErrorReset = '[Referral Effects] Validate Promo Code Error Reset'
}

export enum PaymentErrorActionTypes {
    NewSubscriptionErrorReset = '[Payment Effects] New Subscription Error Reset'
}

export const loginErrorReset = createAction(AuthErrorActionTypes.LoginErrorReset);
export const signupErrorReset = createAction(AuthErrorActionTypes.SignupErrorReset);
export const passwordResetErrorReset = createAction(AuthErrorActionTypes.PasswordResetErrorReset);
export const validatePromoCodeErrorReset = createAction(AuthErrorActionTypes.ValidatePromoCodeErrorReset);

export const newSubscriptionErrorReset = createAction(PaymentErrorActionTypes.NewSubscriptionErrorReset);
