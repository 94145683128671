import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { DashboardEndpoints } from '../../core/constants/ApiEndpoints';
import { GameAwareHttp } from '../../core/services/game-aware-http.service';
import { Notification } from '../../models/notification.model';
import { GlobalState } from '../store';

import * as notificationsActions from '../actions/notifications.actions';
import { PayloadAction } from '../interfaces/payload-action.interface';
import { selectIsLoggedIn } from '../selectors/users.selectors';

@Injectable()
export class NotificationsEffects {
    constructor(
        private actions$: Actions,
        private http: GameAwareHttp,
        private store: Store<GlobalState>
    ) {}

    public loadNotifications$ = createEffect(() =>
        this.actions$.pipe(
            ofType(notificationsActions.loadNotifications),
            switchMap(({ payload }) =>
                this.http.get(DashboardEndpoints.getNotifications, payload).pipe(
                    map((results: Notification[]) =>
                        notificationsActions.loadNotificationsSuccess({ payload: results })
                    ),
                    catchError(error =>
                        of(
                            notificationsActions.loadNotificationsFailure({
                                payload: error
                            })
                        )
                    )
                )
            )
        )
    );

    // public updateNotification$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType(notificationsActions.loadNotifications),
    //         switchMap(({ payload }) =>
    //             this.http.get(DashboardEndpoints.getNotifications, payload).pipe(
    //                 map((results: Notification[]) =>
    //                     notificationsActions.loadNotificationsSuccess({ payload: results })
    //                 ),
    //                 catchError(error =>
    //                     of(
    //                         notificationsActions.loadNotificationsFailure({
    //                             payload: error
    //                         })
    //                     )
    //                 )
    //             )
    //         )
    //     )
    // );

    public markNotificationSeen$ = createEffect(() =>
        this.actions$.pipe(
            ofType(notificationsActions.markNotificationsSeen),
            switchMap(({ payload }) =>
                this.http.put(DashboardEndpoints.markNotificationsSeen, payload).pipe(
                    map(() => notificationsActions.markNotificationsSeenSuccess({ payload })),
                    catchError(error =>
                        of(
                            notificationsActions.markNotificationsSeenFailure({
                                payload: error
                            })
                        )
                    )
                )
            )
        )
    );
    public markNotificationRead$ = createEffect(() =>
        this.actions$.pipe(
            ofType(notificationsActions.markNotificationsRead),
            switchMap(({ payload }) =>
                this.http.put(DashboardEndpoints.markNotificationsRead, payload).pipe(
                    map(() => notificationsActions.markNotificationsReadSuccess({ payload })),
                    catchError(error =>
                        of(
                            notificationsActions.markNotificationsReadFailure({
                                payload: error
                            })
                        )
                    )
                )
            )
        )
    );

    /**
     * Updates the currently logged in user's push notification subscription.
     * If the subscription doesn't exist, one will be created for the user and saved in the database.
     * @param action$
     * @returns {Observable<any>}
     */
    public updatePushSubscription = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(notificationsActions.updatePushSubscription),
            switchMap(({ payload }) => {
                return this.http
                    .put(
                        DashboardEndpoints.updatePushSubscription,
                        JSON.parse(JSON.stringify(payload))
                    )
                    .pipe(
                        map(data =>
                            notificationsActions.updatePushSubscriptionSuccess({ payload: data })
                        ),
                        catchError(error =>
                            of(
                                notificationsActions.updatePushSubscriptionFailure({
                                    payload: error
                                })
                            )
                        )
                    );
            })
        )
    );

    /**
     * Loads the currently logged in user's notification preferences.
     * @param action$
     * @returns {Observable<any>}
     */
    public loadNotificationPreferences = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(notificationsActions.loadNotificationPreferences),
            withLatestFrom( this.store.select(selectIsLoggedIn)),
            filter(([action, isLoggedIn]) => isLoggedIn),
            switchMap(() =>
                this.http.get(DashboardEndpoints.loadNotificationPreferences).pipe(
                    map(data =>
                        notificationsActions.loadNotificationPreferencesSuccess({
                            payload: data
                        })
                    ),
                    catchError(error =>
                        of(
                            notificationsActions.loadNotificationPreferencesFailure({
                                payload: error
                            })
                        )
                    )
                )
            )
        )
    );
    /**
     *
     * Updates the currently logged in user's notification preferences.
     * @param action$
     * @returns {Observable<any>}
     */
    public updateNotificationPreferences = createEffect(() =>
        this.actions$.pipe(
            ofType<PayloadAction>(notificationsActions.updateNotificationPreferences),
            switchMap(({ payload }) =>
                this.http.put(DashboardEndpoints.updateNotificationPreferences, payload).pipe(
                    map(data =>
                        notificationsActions.updateNotificationPreferencesSuccess({
                            payload: data
                        })
                    ),
                    catchError(error =>
                        of(
                            notificationsActions.updateNotificationPreferencesFailure({
                                payload: error
                            })
                        )
                    )
                )
            )
        )
    );
}

