import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DashboardOutletScrollService {
    private scrollYSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    private scrolledSubject: Subject<any> = new Subject<any>();

    public scrolLY$: Observable<number> = this.scrollYSubject.asObservable();
    public scrolled$: Observable<number> = this.scrolledSubject.asObservable();

    constructor() {}

    public updateScrollY(value: number) {
        this.scrollYSubject.next(value);
    }
    public updateScrolled(event: any) {
        this.scrolledSubject.next(event);
    }
}

