import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { GlobalState } from '../../store/store';
import { Games } from '../constants/game.enum';

@Injectable()
export class CanActivateParameterizedGame {
    constructor(private store: Store<GlobalState>, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const SUPPORTED_GAMES = [...Games, 'overwatch'];
        const canActivate = route.params.game && SUPPORTED_GAMES.includes(route.params.game);

        if (!canActivate) {
            this.router.navigate(['/not-found'], { skipLocationChange: true });
        }

        return canActivate;
    }
}

