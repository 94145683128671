import { Observable, of as observableOf } from 'rxjs';
import { PreloadingStrategy, Route } from '@angular/router';

import { Injectable } from '@angular/core';
import { UserSelectors } from './store/selectors/user.selectors';
import { UserRoles } from './models/user-role-map.model';

@Injectable()
export class CustomPreloadingStrategy implements PreloadingStrategy {
    private userRoles: UserRoles;

    constructor(private userSelectors: UserSelectors) {
        this.userSelectors.userRoles$.subscribe(userRoles => {
            this.userRoles = userRoles;
        });
    }

    preload(route: Route, load: Function): Observable<any> {
        return route.data
        && (route.data.preloadElevated && (this.userRoles.isAdmin || this.userRoles.isManager)
            || route.data.preload) ? load() : observableOf(null);
    }
}
