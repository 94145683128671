import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { EMPTY, Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { catchError, flatMap, map, mergeMap, take, tap } from 'rxjs/operators';
import { logoutUserInterceptor } from '../../store/actions/auth.actions';
import { setAuthToken, setRefreshToken } from '../../store/actions/persist.actions';
import { selectAuthToken, selectRefreshToken } from '../../store/selectors/persist.selectors';
import { UserSelectors } from '../../store/selectors/user.selectors';
import { GlobalState } from '../../store/store';
import { ApiEndpoints } from '../constants/ApiEndpoints';
import { HelpersService } from '../services/helpers.service';
import { LocalStorageService } from '../services/local-storage.service';
import { MainHttp } from '../services/main-http.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private authToken: string | null | undefined;
    private refreshToken: string;
    private authTokenSubscription: Subscription;
    private refreshTokenSubscription: Subscription;
    private isLoggedInSubscription: Subscription;
    private refreshTokenInProgress: boolean = false;
    private tokenRefreshSubject: Subject<any> = new Subject();
    private tokenRefreshed$: Observable<any> = this.tokenRefreshSubject.asObservable();
    private isLoggedIn: boolean;
    private http: MainHttp;

    constructor(
        private injector: Injector,
        private userSelectors: UserSelectors,
        private store: Store<GlobalState>,
        private localStorage: LocalStorageService
    ) {
        this.http = injector.get(MainHttp);
        this.authTokenSubscription = store.select(selectAuthToken).subscribe(authToken => {
            this.authToken = authToken;
        });
        this.refreshTokenSubscription = store.select(selectRefreshToken).subscribe(refreshToken => {
            this.refreshToken = refreshToken || '';
        });
        this.isLoggedInSubscription = this.userSelectors.isLoggedIn$.subscribe(isLoggedIn => {
            this.isLoggedIn = isLoggedIn;
        });
    }

    private refreshAuthToken(): Observable<any> {
        if (this.refreshTokenInProgress) {
            return new Observable(observer => {
                this.tokenRefreshed$.subscribe(() => {
                    observer.next();
                    observer.complete();
                });
            });
        } else {
            this.refreshTokenInProgress = true;

            return this.store.select(selectRefreshToken).pipe(
                take(1),
                flatMap(refreshToken => {
                    // console.log('going to use refresh token: ', refreshToken);
                    return this.http
                        .post(
                            ApiEndpoints.newAuthToken,
                            {},
                            { observe: 'response' }
                        )
                        .pipe(
                            map((data: any) => {
                                this.store.dispatch(
                                    setAuthToken({ payload: data.body.result.authToken })
                                );
                                this.store.dispatch(
                                    setRefreshToken({ payload: data.body.result.refreshToken })
                                );
                                this.refreshTokenInProgress = false;
                                this.tokenRefreshSubject.next(true);
                            })
                        );
                })
            );
        }
    }

    private setAuthHeaders(req: HttpRequest<any>): Observable<HttpRequest<any>> {
        return this.store.select(selectAuthToken).pipe(
            take(1),
            flatMap((authToken: any) => {
                const clone = req.clone({
                    headers: req.headers.set('Authorization', 'Bearer ' + authToken)
                });

                return of(clone);
            })
        );
    }

    private handleRequest(req, next): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    if (
                        event &&
                        event.headers &&
                        event.headers.get &&
                        event.headers.get('Authorization')
                    ) {
                        this.store.dispatch(
                            setAuthToken({ payload: event.headers.get('Authorization') })
                        );
                    }
                }
            }),
            catchError(error => {
                const handledError = HelpersService.handleResponseError(error);
                const expiredTokenErrorCode = '1008';
                const malformedAuthTokenErrorCode = '1009';
                let errorCode =
                    (error.headers && error.headers.get && error.headers.get('API-Error-Code')) ||
                    handledError.errorCode;

                errorCode = errorCode && errorCode.toString ? errorCode.toString() : errorCode;

                if (errorCode === malformedAuthTokenErrorCode) {
                    this.store.dispatch(logoutUserInterceptor());
                    return next.handle(req);
                }

                if (errorCode === expiredTokenErrorCode) {
                    return this.refreshAuthToken().pipe(
                        mergeMap(() => {
                            return this.setAuthHeaders(req).pipe(
                                flatMap(clonedReq => {
                                    return next.handle(clonedReq);
                                })
                            );
                        }),
                        catchError(refreshTokenError => {
                            const refreshTokenExpiredErrorCode = '2007';
                            const refreshTokenNotFoundErrorCode = '2012';
                            let refreshTokenErrorCode =
                                (refreshTokenError.headers &&
                                    refreshTokenError.headers.get &&
                                    refreshTokenError.headers.get('API-Error-Code')) ||
                                refreshTokenError.errorCode;
                            refreshTokenErrorCode =
                                refreshTokenErrorCode && refreshTokenErrorCode.toString
                                    ? refreshTokenErrorCode.toString()
                                    : refreshTokenErrorCode;

                            if (
                                this.isLoggedIn &&
                                (refreshTokenErrorCode === refreshTokenExpiredErrorCode ||
                                    refreshTokenErrorCode === refreshTokenNotFoundErrorCode)
                            ) {
                                this.refreshTokenInProgress = false;
                                this.store.dispatch(logoutUserInterceptor());
                            }

                            return EMPTY;
                        })
                    );
                }

                return throwError(error);
            })
        );
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        req = req.clone({
            headers: req.headers
                // the value for the ngsw-bypass can be anything
                .set('ngsw-bypass', 'true')
        });

        // has to be replaced with cloudfront domain
        if (req.url.includes('amazonaws.com') || !this.authToken) {
            return this.handleRequest(req, next);
        }

        const newRetVal = this.setAuthHeaders(req).pipe(
            flatMap(clonedReq => {
                next.handle(req);
                return this.handleRequest(clonedReq, next);
            })
        );

        return newRetVal;
    }
}

