import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class IHttp {
    get: (url: string, options?) => Observable<any>;

    post: (url: string, body, options?) => Observable<any>;

    put: (url: string, body, options?) => Observable<any>;

    delete: (url: string, body?, options?) => Observable<any>;
}
